import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { XBRBorder, USDTBorder, TBABorder } from "../assets/index.js";
import { useTheme } from "@mui/material";
import { IoClose } from "react-icons/io5";

const LiquidityConfirmedModal = ({
  onClose,
  text,
  paragraph,
  token0Name,
  token1Name,
  hashdata,
}) => {
  const tokenImageMap = {
    XBR: XBRBorder,
    USDT: USDTBorder,
    TBA: TBABorder,
  };
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";
  const image1 = tokenImageMap[token0Name];
  const image2 = tokenImageMap[token1Name];

  const handleCloseClick = (event) => {
    event.stopPropagation();
    onClose();
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);
  const modalContent = (
    <div className={`fixed top-0 right-0 z-50 mt-20 mr-2`} onClick={onClose}>
      <a
        href={`https://polygonscan.com/tx/${hashdata}`}
        target="_blank"
        rel="noopener noreferrer"
        className={`block min-w-[270px] w-90per max-w-[400px] box-border rounded-[16px] gap-[10px] relative border-2 border-solid`}
        onClick={(event) => {
          event.stopPropagation();
          onClose();
        }}
      >
        <div
          className={`relative p-4 border rounded-[12px] w-[350px] ${
            isLightMode
              ? "bg-white border-[#959DF7]"
              : "bg-[#02042C] border-[#21246A]"
          }`}
        >
          <div className="flex gap-2 items-center">
            <div className="flex items-center">
              <img src={image1} alt="image1" className="w-7 h-7" />
              <img src={image2} alt="image2" className="w-7 h-7 -ml-[10px]" />
            </div>
            <div className="flex flex-col">
              <div
                className={`text-base ${
                  isLightMode ? "text-[#34343C]" : "text-white"
                }`}
              >
                {text}
              </div>
              <div
                className={`text-base ${
                  isLightMode ? "text-[#34343C]" : "text-[#DCDCDE]"
                }`}
              >
                {paragraph}
              </div>
            </div>
          </div>
        </div>
      </a>
      <IoClose
        className="absolute top-2 right-2 w-6 h-6 cursor-pointer flex justify-end"
        onClick={handleCloseClick}
      />
    </div>
  );

  // Create a portal to render the modal outside of the popup
  return ReactDOM.createPortal(modalContent, document.body);
};

export default LiquidityConfirmedModal;
