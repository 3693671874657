import { useTheme } from "@mui/material";
import { FaArrowLeft, FaCheck } from "react-icons/fa";
import Footer from "../components/Footer";
import {
  InRange,
  OutofRange,
  TBABorder,
  USDTBorder,
  XBRBorder,
} from "../assets";
import { ReactComponent as CopyIcon } from "../assets/content_copy.svg";
import { useState, useEffect } from "react";
import { MdArrowOutward } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { getLiquidity } from "../uniswap/liquidity";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { XBRimg, USDTimg, tba } from "../assets";
import IncreaseLiquidity from "../components/IncreaseLiquidity.jsx";
import RemoveLiquidity from "../components/RemoveLiquidity.jsx";
import NftDetailsShimmer from "../components/LoadingShimmer/NftDetailsShimmer.jsx";
import axios from "axios";
import { collectfees, getCollectFees } from "../uniswap/position.js";
import { Log } from "ethers";
import CollectFees from "../components/CollectFees.jsx";
import LiquidityConfirmationModal from "../components/LiquidityConfirmationModal.jsx";
import LiquidityConfirmedModal from "../components/LiquidityConfirmedModal.jsx";
import FailedTransaction from "../components/FailedTransaction.jsx";
import LPFarmSuccessModal from "../components/LPFarmSuccessModal.jsx";
import CollectFeeConfirmationModal from "./CollectFeeConfirmationModal.jsx";
import refreshNFTId from "../redux/fetchData/refreshNFTId.js";
const NFTDeatails = () => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";
  const { nftId } = useParams();
  const { walletProvider } = useWeb3ModalProvider();
  const [xbrPrice, setXbrPrice] = useState(0);
  const [tbaPrice, setTbaPrice] = useState(0);
  const [usdtPrice, setUsdtPrice] = useState(0);
  const tokenImageMap = {
    XBR: XBRBorder,
    USDT: USDTBorder,
    TBA: TBABorder,
  };

  const fetchPrice = async (address) => {
    const URL = `${process.env.REACT_APP_API}/v1/xbr/token-prices?address=${address}`;
    const response = await axios.get(URL);
    return response?.data?.data?.attributes?.price_usd;
  };

  const [tokenSelectTab, setTokenSelectTab] = useState(1);
  const [liquidityData, setLiquidityData] = useState({});
  const [increaseLiquidity, setIncreaseLiquidity] = useState(false);
  const [removeLiquidity, setRemoveLiquidity] = useState(false);
  const [token0LiquidityAmount, settoken0LiquidityAmount] = useState();
  const [token1LiquidityAmount, settoken1LiquidityAmount] = useState();
  const [token0name, settoken0name] = useState();
  const [token1name, settoken1name] = useState();
  const [finalprice, setfinalprice] = useState();
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [Collectfee0, setCollectfee0] = useState();
  const [Collectfee1, setCollectfee1] = useState();
  const [collectFeesOpen, setCollectFeesOpen] = useState(false);
  const [claimFeesModal, setClaimFeesModal] = useState(false);
  const [claimFeesSuccess, setClaimFeesSuccess] = useState(false);
  const [claimFessFail, setClaimFeesFail] = useState(false);
  const [hashdata, setHashdata] = useState("");
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  const [isLiquidityChanged, setIsLiquidityChanged] = useState(false);

  const getLiquidityData = async () => {
    const {
      token0,
      token1,
      fee,
      tickLower,
      tickUpper,
      currentTick,
      poolAddress,
    } = await getLiquidity(Number(nftId), walletProvider);

    const token0LiquidityAmount = token0.amount;
    const token1LiquidityAmount = token1.amount;
    settoken0LiquidityAmount(token0LiquidityAmount);
    settoken1LiquidityAmount(token1LiquidityAmount);
    const token0Name = token0.symbol;
    const token1Name = token1.symbol;
    settoken0name(token0Name);
    settoken1name(token1Name);
    const decimalAdjustmentFactor = Math.pow(
      10,
      token1.decimals - token0.decimals
    );
    const currentfee = fee / 10000;
    const tickToPrice = (tick) => {
      return Math.pow(1.0001, tick);
    };
    const minPrice = tickToPrice(tickLower) / decimalAdjustmentFactor;
    const maxPrice = tickToPrice(tickUpper) / decimalAdjustmentFactor;
    const currentPrice = tickToPrice(currentTick) / decimalAdjustmentFactor;

    const minPrice1 = 1 / maxPrice;
    const maxPrice1 = 1 / minPrice;
    const currentPrice1 = 1 / currentPrice;

    setLiquidityData((prevData) => ({
      ...prevData,
      [nftId]: {
        token0LiquidityAmount,
        token1LiquidityAmount,
        token0Name,
        token1Name,
        currentfee,
        minPrice,
        maxPrice,
        minPrice1,
        maxPrice1,
        currentPrice,
        currentPrice1,
        poolAddress,
      },
    }));
    setFetchingDetails(false);
  };

  useEffect(() => {
    if (!nftId.length) return;
    getLiquidityData();
  }, [nftId, walletProvider]);

  useEffect(() => {
    if (!nftId.length) return;
    if (isLiquidityChanged) {
      getLiquidityData();
      handleCollectFees();
      setIsLiquidityChanged(false);
    }
  }, [isLiquidityChanged]);

  const formatBalanceForPrice = (balance, isMinPrice) => {
    const epsilon = 1e-6;
    const infinityThreshold = 1e20;

    if (balance < epsilon || balance > infinityThreshold) {
      return isMinPrice ? "0" : "∞";
    }

    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return num.toFixed(4).replace(/\.?0+$/, "");
    }
  };

  const formatBalanceForFinalPrice = (balance) => {
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return num.toFixed(4).replace(/\.?0+$/, "");
    }
  };
  useEffect(() => {
    if (increaseLiquidity || removeLiquidity || collectFeesOpen) {
      // Disable background scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable background scrolling
      document.body.style.overflow = "auto";
    }

    // Clean up when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [increaseLiquidity, removeLiquidity, collectFeesOpen]);

  const formatBalanceForPricecp = (balance) => {
    const smallPriceThreshold = 1e-5;

    const num = parseFloat(balance);

    if (num > 0 && num < smallPriceThreshold) {
      return "<0.00001";
    }

    // Format large numbers with suffixes
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return num.toFixed(5).replace(/\.?0+$/, "");
    }
  };

  function formatCollectPrice(price) {
    // Ensure the price is a number
    if (typeof price !== "number" || isNaN(price)) return "NaN";

    if (price === 0) return "0";

    // Convert the price to a string in fixed-point notation to ensure precision
    let priceStr = price.toFixed(25); // Use enough precision to capture all significant digits

    // Find the index of the decimal point
    let decimalIndex = priceStr.indexOf(".") + 1;

    // Find the index of the first non-zero digit after the decimal point
    let firstNonZeroIndex = decimalIndex;
    while (
      priceStr[firstNonZeroIndex] === "0" &&
      firstNonZeroIndex < priceStr.length
    ) {
      firstNonZeroIndex++;
    }

    // Count the number of leading zeroes after the decimal point
    let zeroCount = firstNonZeroIndex - decimalIndex;

    // Get the significant digits part (adjust the length as needed)
    let significantPart = priceStr.substring(
      firstNonZeroIndex,
      firstNonZeroIndex + 3
    );

    // Check if the price is greater than or equal to 1
    if (price >= 1) {
      // Get the integer part of the number
      let integerPart = Math.floor(price).toString();

      // If zeroCount is 0, don't include the brackets
      return zeroCount > 0
        ? `${integerPart}.0(${zeroCount})${significantPart}`
        : `${integerPart}.${significantPart}`;
    }

    // For numbers less than 1, format with zeroCount (if any)
    return zeroCount > 0
      ? `0.0(${zeroCount})${significantPart}`
      : `0.${significantPart}`;
  }
  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 4)}...${address.slice(-3)}`;
  };

  const collectfee = async () => {
    try {
      setClaimFeesModal(true);
      const collect = await collectfees(
        nftId,
        liquidityData[nftId].token0Name,
        liquidityData[nftId].token1Name,
        walletProvider
      );
      setHashdata(collect);
      if (collect) {
        setCollectFeesOpen(false);
        setClaimFeesModal(false);
        setClaimFeesSuccess(true);
      } else {
        setCollectFeesOpen(false);
        setClaimFeesModal(false);
        setClaimFeesFail(true);
      }
    } catch (error) {
      console.error("Error at claiming Fees", error);
    }
  };

  useEffect(() => {
    const prices = async () => {
      const XBR = await fetchPrice(
        "0xD44CaEBbF5C5a3848357eC05e24220BEBEF41d40"
      );
      const TBA = await fetchPrice(
        "0x7e1AE068Ac4e0ffD7a84750c2FB093A0EAE9aEa2"
      );
      const USDT = await fetchPrice(
        "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
      );
      setXbrPrice(XBR);
      setTbaPrice(Number(TBA));
      setUsdtPrice(USDT);
    };
    prices();
  }, []);

  const pricemap = {
    XBR: xbrPrice,
    TBA: tbaPrice,
    USDT: usdtPrice,
  };

  function safeParseNumber(value) {
    const number = Number(value);
    return isNaN(number) ? 0 : number;
  }

  useEffect(() => {
    if (
      token0LiquidityAmount &&
      token1LiquidityAmount &&
      token0name &&
      token1name &&
      pricemap[token0name] &&
      pricemap[token1name]
    ) {
      const token0price =
        safeParseNumber(token0LiquidityAmount) *
        safeParseNumber(pricemap[token0name]);
      const token1price =
        safeParseNumber(token1LiquidityAmount) *
        safeParseNumber(pricemap[token1name]);

      setfinalprice(token0price + token1price);
    }
  }, [
    token0LiquidityAmount,
    token1LiquidityAmount,
    token0name,
    token1name,
    pricemap,
  ]);

  const handleCollectFees = async () => {
    try {
      const result = await getCollectFees(
        nftId,
        token0name,
        token1name,
        walletProvider
      );
      setCollectfee0(result[0]);
      setCollectfee1(result[1]);
      console.log("Collectfee0", Collectfee0);
    } catch (err) {
      console.error("Error collecting fees:", err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleCollectFees();
  }, [token0name, token1name]);

  useEffect(() => {
    if (claimFeesSuccess) {
      handleCollectFees();
    }
  }, [claimFeesSuccess]);

  const handleCopy = () => {
    const address = liquidityData[nftId].poolAddress;
    navigator.clipboard.writeText(address).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };

  const tokenRedirecturls = {
    XBR: "https://polygonscan.com/address/0xD44CaEBbF5C5a3848357eC05e24220BEBEF41d40",
    USDT: "https://polygonscan.com/address/0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    TBA: "https://polygonscan.com/address/0x7e1AE068Ac4e0ffD7a84750c2FB093A0EAE9aEa2",
  };

  return (
    <div
      className={`mx-auto p-2 font-inter ${
        theme.palette.mode === "light" ? "bg-[#F7F7FE]" : "bg-[#02042C]"
      }`}
    >
      {theme.palette.mode === "light" ? (
        <>
          <div
            className="absolute hidden sm:block top-10 right-0 4xl:right-[20%] h-[507.098px] w-[410.121px] opacity-20 flex-shrink-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
              filter: "blur(190.72238159179688px)",
            }}
          ></div>
          <div
            className="absolute hidden sm:block mt-[25vh] left-0 4xl:left-[20%] h-[507.098px] w-[410.121px] opacity-20 flex-shrink-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
              filter: "blur(190.72238159179688px)",
            }}
          ></div>
        </>
      ) : (
        <>
          <div
            className="absolute hidden sm:block top-10 right-0 h-[507.098px] w-[310.121px] opacity-30 flex-shrink-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
              filter: "blur(230.72238159179688px)",
            }}
          ></div>
          <div
            className="absolute hidden sm:block top-[20vh] -left-10 h-[507.098px] w-[310.121px] opacity-30 flex-shrink-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
              filter: "blur(230.72238159179688px)",
            }}
          ></div>
        </>
      )}
      {fetchingDetails ? (
        <NftDetailsShimmer />
      ) : (
        liquidityData[nftId] && (
          <div className="max-w-[1280px] mx-auto pt-6 sm:pt-10 pb-16">
            <div
              className={`flex gap-[10px] text-base leading-6 items-center cursor-pointer ${
                isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
              }`}
              onClick={() => navigate("/swap")}
            >
              <FaArrowLeft />
              <span>Back to NFTs</span>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between gap-3 lg:items-center mt-4 sm:mt-8 p-2 lg:p-0">
              <div className="flex gap-3 md:gap-4 md:items-center flex-col-reverse md:flex-row">
                <div className="flex gap-3 md:gap-4 md:items-center flex-col md:flex-row">
                  {" "}
                  <div className="flex gap-3 md:gap-4 items-center">
                    <div className={`flex items-center`}>
                      <img
                        src={tokenImageMap[liquidityData[nftId].token0Name]}
                        alt="USDT"
                        className="w-6 h-6"
                      />
                      <img
                        src={tokenImageMap[liquidityData[nftId].token1Name]}
                        alt="XBR"
                        className="-ml-2 w-6 h-6"
                      />
                    </div>
                    <span
                      className={`text-[31px] font-semibold leading-[120%] ${
                        isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                      }`}
                    >
                      {liquidityData[nftId].token0Name} /{" "}
                      {liquidityData[nftId].token1Name}{" "}
                    </span>
                  </div>
                  <div className="flex gap-4 items-center">
                    <div
                      className={`flex gap-2 px-4 py-2 text-base leading-6 rounded-[12px] items-center ${
                        isLightMode
                          ? "text-[#181821] bg-[#D6DAFC]"
                          : "text-[#DCDCDE] bg-[#0B0E4A]"
                      }`}
                    >
                      <span>
                        {shortenAddress(liquidityData[nftId].poolAddress)}
                      </span>
                      {copied ? (
                        <FaCheck />
                      ) : (
                        <CopyIcon
                          className="cursor-pointer"
                          onClick={handleCopy}
                        />
                      )}
                    </div>
                    <div
                      className={`flex px-4 py-2 text-base leading-6 rounded-[12px] ${
                        isLightMode
                          ? "text-[#181821] bg-[#D6DAFC]"
                          : "text-[#DCDCDE] bg-[#0B0E4A]"
                      }`}
                    >
                      {liquidityData[nftId].currentfee}%
                    </div>
                  </div>
                </div>

                <div className="flex gap-[6px] items-center">
                  <img
                    src={
                      liquidityData[nftId].token0LiquidityAmount > 0 &&
                      liquidityData[nftId].token1LiquidityAmount > 0
                        ? InRange
                        : OutofRange
                    }
                    alt={
                      liquidityData[nftId].token0LiquidityAmount > 0 &&
                      liquidityData[nftId].token1LiquidityAmount > 0
                        ? "In Range"
                        : "Out of Range"
                    }
                    className="w-4 h-4"
                  />
                  <span
                    className={`text-base leading-6 ${
                      liquidityData[nftId].token0LiquidityAmount > 0 &&
                      liquidityData[nftId].token1LiquidityAmount > 0
                        ? "text-[#57D24C]"
                        : "text-red-500"
                    }`}
                  >
                    {liquidityData[nftId].token0LiquidityAmount > 0 &&
                    liquidityData[nftId].token1LiquidityAmount > 0
                      ? "In Range"
                      : "Out of Range"}
                  </span>
                </div>
              </div>
              <div className="flex gap-2 items-center z-10">
                <button
                  className={`px-4 py-2 sm:px-6 sm:py-3 text-base leading-6 font-semibold rounded-[12px] border-2 border-[#5663F2] hover:bg-[#5663F2] hover:transition-colors duration-300 ${
                    isLightMode
                      ? "text-[#5663F2] hover:text-white"
                      : "text-[#FFFFFF]"
                  }`}
                  onClick={() => setIncreaseLiquidity(true)}
                >
                  Increase liquidity
                </button>
                <button
                  className={`px-4 py-2 sm:px-6 sm:py-3 text-base leading-6 font-semibold rounded-[12px] border-2 border-[#5663F2] hover:bg-[#5663F2] hover:transition-colors duration-300 ${
                    isLightMode
                      ? "text-[#5663F2] hover:text-white"
                      : "text-[#FFFFFF]"
                  } ${
                    liquidityData[nftId].token0LiquidityAmount > 0 &&
                    liquidityData[nftId].token1LiquidityAmount > 0
                      ? "block"
                      : "hidden"
                  }`}
                  onClick={() => setRemoveLiquidity(true)}
                >
                  Remove liquidity
                </button>
              </div>
            </div>
            <div className="flex flex-col-reverse lg:flex-row gap-6 mt-6 sm:mt-12 justify-between">
              <div
                className={`p-4 sm:p-6 rounded-[32px] border w-full lg:w-1/2 flex flex-col gap-5 z-10 ${
                  isLightMode ? "border-[#B6BCF9]" : "border-[#10124B]"
                }`}
              >
                <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row w-full justify-between items-end lg:items-center">
                  <div className="flex gap-6 w-full justify-between lg:justify-normal">
                    <span
                      className={`text-[20px] leading-[150%] font-bold ${
                        isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                      }`}
                    >
                      Price range
                    </span>{" "}
                    <div className="flex gap-[6px] items-center">
                      <img
                        src={
                          liquidityData[nftId].token0LiquidityAmount > 0 &&
                          liquidityData[nftId].token1LiquidityAmount > 0
                            ? InRange
                            : OutofRange
                        }
                        alt={
                          liquidityData[nftId].token0LiquidityAmount > 0 &&
                          liquidityData[nftId].token1LiquidityAmount > 0
                            ? "In Range"
                            : "Out of Range"
                        }
                        className="w-4 h-4"
                      />
                      <span
                        className={`text-base leading-6 ${
                          liquidityData[nftId].token0LiquidityAmount > 0 &&
                          liquidityData[nftId].token1LiquidityAmount > 0
                            ? "text-[#57D24C]"
                            : "text-red-500"
                        }`}
                      >
                        {liquidityData[nftId].token0LiquidityAmount > 0 &&
                        liquidityData[nftId].token1LiquidityAmount > 0
                          ? "In Range"
                          : "Out of Range"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`flex gap-4 p-2 rounded-[24px] ${
                      isLightMode ? "bg-white" : "bg-[#050840]"
                    }`}
                  >
                    <button
                      className={`py-1 px-4 rounded-[24px] text-base leading-6 font-semibold ${
                        tokenSelectTab === 1
                          ? isLightMode
                            ? "bg-[#EEEFFE] text-[#5663F2]"
                            : "bg-[#21246A] text-white"
                          : isLightMode
                          ? "bg-transparent text-[#69696F]"
                          : "bg-transparent text-white"
                      }`}
                      onClick={() => setTokenSelectTab(1)}
                    >
                      {liquidityData[nftId].token0Name}
                    </button>
                    <button
                      className={`py-1 px-4 rounded-[24px] text-base leading-6 font-semibold ${
                        tokenSelectTab === 2
                          ? isLightMode
                            ? "bg-[#EEEFFE] text-[#5663F2]" // Light mode selected
                            : "bg-[#21246A] text-white" // Dark mode selected
                          : isLightMode
                          ? "bg-transparent text-[#69696F]" // Light mode unselected
                          : "bg-transparent text-white" // Dark mode unselected
                      }`}
                      onClick={() => setTokenSelectTab(2)}
                    >
                      {liquidityData[nftId].token1Name}
                    </button>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-4">
                  <div
                    className={`w-full p-8 flex flex-col gap-2 justify-center items-center rounded-[16px] ${
                      isLightMode ? "bg-white" : "bg-[#0B0E4A]"
                    }`}
                  >
                    <div
                      className={`text-base leading-6 ${
                        isLightMode ? "text-[#69696F]" : "text-[#97979B]"
                      }`}
                    >
                      Min price
                    </div>
                    <div
                      className={`text-[20px] leading-[150%] font-bold ${
                        isLightMode ? "text-[#34343C]" : "text-[F1F1F2]"
                      }`}
                    >
                      {tokenSelectTab === 1
                        ? formatBalanceForPrice(
                            liquidityData[nftId].minPrice,
                            true
                          )
                        : formatBalanceForPrice(
                            liquidityData[nftId].minPrice1,
                            true
                          )}
                    </div>
                    <div
                      className={`text-base leading-6 ${
                        isLightMode ? "text-[#69696F]" : "text-[#97979B]"
                      }`}
                    >
                      {tokenSelectTab === 1
                        ? `${liquidityData[nftId].token1Name} per ${liquidityData[nftId].token0Name}`
                        : `${liquidityData[nftId].token0Name} per ${liquidityData[nftId].token1Name}`}
                    </div>
                    <div
                      className={`text-[14px] leading-[150%] text-center ${
                        isLightMode ? "text-[#C5C5C8]" : "text-[#69696F]"
                      }`}
                    >
                      Your position will be 100% at this price
                    </div>
                  </div>
                  <div
                    className={`w-full p-8 flex flex-col gap-2 justify-center items-center rounded-[16px] ${
                      isLightMode ? "bg-white" : "bg-[#0B0E4A]"
                    }`}
                  >
                    <div
                      className={`text-base leading-6 ${
                        isLightMode ? "text-[#69696F]" : "text-[#97979B]"
                      }`}
                    >
                      Max price
                    </div>
                    <div
                      className={`text-[20px] leading-[150%] font-bold ${
                        isLightMode ? "text-[#34343C]" : "text-[F1F1F2]"
                      }`}
                    >
                      {tokenSelectTab === 1
                        ? formatBalanceForPrice(
                            liquidityData[nftId].maxPrice,
                            false
                          )
                        : formatBalanceForPrice(
                            liquidityData[nftId].maxPrice1,
                            false
                          )}
                    </div>
                    <div
                      className={`text-base leading-6 ${
                        isLightMode ? "text-[#69696F]" : "text-[#97979B]"
                      }`}
                    >
                      {tokenSelectTab === 1
                        ? `${liquidityData[nftId].token1Name} per ${liquidityData[nftId].token0Name}`
                        : `${liquidityData[nftId].token0Name} per ${liquidityData[nftId].token1Name}`}
                    </div>
                    <div
                      className={`text-[14px] leading-[150%] text-center ${
                        isLightMode ? "text-[#C5C5C8]" : "text-[#69696F]"
                      }`}
                    >
                      Your position will be 100% at this price
                    </div>
                  </div>
                </div>
                <div
                  className={`w-full p-8 flex flex-col justify-center items-center gap-2 rounded-[16px] ${
                    isLightMode ? "bg-white" : "bg-[#0B0E4A]"
                  }`}
                >
                  <div
                    className={`text-base leading-6 ${
                      isLightMode ? "text-[#69696F]" : "text-[#97979B]"
                    }`}
                  >
                    Current price
                  </div>
                  <div
                    className={`text-[20px] leading-[150%] font-bold ${
                      isLightMode ? "text-[#34343C]" : "text-[F1F1F2]"
                    }`}
                  >
                    {tokenSelectTab === 1
                      ? formatBalanceForPricecp(
                          liquidityData[nftId].currentPrice
                        )
                      : formatBalanceForPricecp(
                          liquidityData[nftId].currentPrice1
                        )}
                  </div>
                  <div
                    className={`text-base leading-6 ${
                      isLightMode ? "text-[#69696F]" : "text-[#97979B]"
                    }`}
                  >
                    {tokenSelectTab === 1
                      ? `${liquidityData[nftId].token1Name} per ${liquidityData[nftId].token0Name}`
                      : `${liquidityData[nftId].token0Name} per ${liquidityData[nftId].token1Name}`}
                  </div>
                </div>
              </div>
              <div
                className={`w-full lg:w-1/2
             flex flex-col gap-6`}
              >
                <div
                  className={`p-4 md:p-6 w-full flex flex-col gap-4 rounded-[32px] border z-10 ${
                    isLightMode ? "border-[#B6BCF9]" : "border-[#10124B]"
                  }`}
                >
                  <div className={`flex flex-col gap-2`}>
                    <span
                      className={`text-[20px] leading-[150%] font-bold ${
                        isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                      }`}
                    >
                      Liquidity
                    </span>
                    <div className="flex gap-6 items-center">
                      {/* <span
                    className={`text-[20px] leading-[150%] font-bold ${
                      isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                    }`}
                  >
                    23 LPT
                  </span> */}
                      <span
                        className={`text-base leading-6 ${
                          isLightMode ? "text-[#69696F]" : "text-[#97979B]"
                        }`}
                      >
                        ${formatBalanceForFinalPrice(finalprice)}{" "}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`flex flex-col
                gap-[14px] p-6 rounded-[16px] ${
                  isLightMode ? "bg-white" : "bg-[#0B0E4A]"
                }`}
                  >
                    <div className="w-full flex justify-between">
                      <a
                        href={
                          tokenRedirecturls[liquidityData[nftId].token0Name]
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="flex gap-2 items-center">
                          <img
                            src={tokenImageMap[liquidityData[nftId].token0Name]}
                            alt="XBR"
                          />
                          <div
                            className={`flex gap-1 items-center text-base leading-6 ${
                              isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                            }`}
                          >
                            <span>{liquidityData[nftId].token0Name}</span>
                            <MdArrowOutward />
                          </div>
                        </div>
                      </a>
                      <div
                        className={`text-base leading-6 ${
                          isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                        }`}
                      >
                        {formatBalanceForPricecp(
                          liquidityData[nftId].token0LiquidityAmount
                        )}
                      </div>
                    </div>
                    <div className="w-full flex justify-between">
                      <a
                        href={
                          tokenRedirecturls[liquidityData[nftId].token1Name]
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="flex gap-2 items-center">
                          <img
                            src={tokenImageMap[liquidityData[nftId].token1Name]}
                            alt="USDT"
                          />
                          <div
                            className={`flex gap-1 items-center text-base leading-6 ${
                              isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                            }`}
                          >
                            <span>{liquidityData[nftId].token1Name}</span>
                            <MdArrowOutward />
                          </div>
                        </div>
                      </a>
                      <div
                        className={`text-base leading-6 ${
                          isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                        }`}
                      >
                        {formatBalanceForPricecp(
                          liquidityData[nftId].token1LiquidityAmount
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`p-4 md:p-6 w-full flex flex-col gap-4 rounded-[32px] border ${
                    isLightMode ? "border-[#B6BCF9]" : "border-[#10124B]"
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div className={`flex flex-col gap-2`}>
                      <span
                        className={`text-[20px] leading-[150%] font-bold ${
                          isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                        }`}
                      >
                        Unclaimed fees
                      </span>
                      <div className="flex items-center">
                        <span
                          className={`text-[20px] leading-[150%] font-bold ${
                            isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                          }`}
                        ></span>
                      </div>
                    </div>
                    <button
                      className={`rounded-[12px] text-white text-base leading-[150%] px-6 py-3 flex items-center justify-center font-semibold bg-[#5663F2]`}
                      onClick={() => setCollectFeesOpen(true)}
                      disabled={Collectfee0 == 0.0 && Collectfee1 == 0.0}
                    >
                      Collect fees
                    </button>
                  </div>
                  <div
                    className={`flex flex-col
                gap-[14px] p-6 rounded-[16px] ${
                  isLightMode ? "bg-white" : "bg-[#0B0E4A]"
                }`}
                  >
                    <div className="w-full flex justify-between">
                      <div className="flex gap-2 items-center">
                        <img
                          src={tokenImageMap[liquidityData[nftId].token0Name]}
                          alt="XBR"
                        />
                        <div
                          className={`flex items-center text-base leading-6 ${
                            isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                          }`}
                        >
                          <span>{liquidityData[nftId].token0Name}</span>
                        </div>
                      </div>
                      <div
                        className={`text-base leading-6 ${
                          isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                        }`}
                      >
                        {formatCollectPrice(Number(Collectfee0))}
                      </div>
                    </div>
                    <div className="w-full flex justify-between">
                      <div className="flex gap-2 items-center">
                        <img
                          src={tokenImageMap[liquidityData[nftId].token1Name]}
                          alt="USDT"
                        />
                        <div
                          className={`flex items-center text-base leading-6 ${
                            isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                          }`}
                        >
                          <span>{liquidityData[nftId].token1Name}</span>
                        </div>
                      </div>
                      <div
                        className={`text-base leading-6 ${
                          isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                        }`}
                      >
                        {formatCollectPrice(Number(Collectfee1))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      {increaseLiquidity && (
        <div className="fixed h-screen inset-0 bg-black bg-opacity-50 z-50">
          <IncreaseLiquidity
            nftId={nftId}
            onClose={() => setIncreaseLiquidity(false)}
            setIsLiquidityChanged={setIsLiquidityChanged}
          />
        </div>
      )}
      {removeLiquidity && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <RemoveLiquidity
            nftId={nftId}
            onClose={() => setRemoveLiquidity(false)}
            setRemoveLiquidity={setRemoveLiquidity}
            setIsLiquidityChanged={setIsLiquidityChanged}
          />
        </div>
      )}
      {collectFeesOpen && (
        <CollectFees
          onClose={() => setCollectFeesOpen(false)}
          collectfee={collectfee}
          token0Name={token0name}
          token1Name={token1name}
          collectfee0={formatCollectPrice(Number(Collectfee0))}
          collectfee1={formatCollectPrice(Number(Collectfee1))}
        />
      )}
      {claimFeesModal && (
        <LiquidityConfirmationModal
          onClose={() => setClaimFeesModal(false)}
          text={`Collect Fees`}
        />
      )}
      {claimFeesSuccess && (
        <CollectFeeConfirmationModal
          onClose={() => setClaimFeesSuccess(false)}
          type={"success"}
          message={`Fees Collected`}
          hashdata={hashdata}
        />
      )}
      {claimFessFail && (
        <CollectFeeConfirmationModal
          onClose={() => setClaimFeesFail(false)}
          type={`failed`}
          message={`Fees collection failed`}
        />
      )}
      <Footer />
    </div>
  );
};

export default NFTDeatails;
