import { configureStore } from '@reduxjs/toolkit';
import walletReducer from '../redux/wallet/walletSlice';
import themeReducer from '../redux/theme/themeSlice';
import dataReducer from '../redux/data/dataSlice';
import locationReducer from '../redux/locations/redirectSlice';
import RefreshNFTIdReducer from './fetchData/refreshNFTId';
import loggerMiddleware from "./middleware/logger";

// const store = configureStore({
//   reducer: {
//     theme: themeReducer,
//     wallet: walletReducer,
//     data: dataReducer,
//   },
// });

const store = configureStore({
  reducer: {
    theme: themeReducer,
    wallet: walletReducer,
    data: dataReducer,
    location : locationReducer,
    refreshNftId :RefreshNFTIdReducer
    
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['wallet/setProviderParams'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload'],
        // Ignore these paths in the state
        ignoredPaths: ['wallet.providerParams'],
      },
    }).concat(loggerMiddleware),
});
export default store;
