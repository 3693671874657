import { useEffect } from "react";
import { useTheme } from "@mui/material";
import { MdClose } from "react-icons/md";
import Success from "../assets/Success.svg";
import Failed from "../assets/Failed.svg";

const CollectFeeConfirmationModal = ({ onClose, type, message, hashdata }) => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const handleViewTransaction = () => {
    onClose();
    window.open(`https://polygonscan.com/tx/${hashdata}`, "_blank");
  };

  return (
    <div className="fixed flex items-center justify-center z-50 font-inter inset-0 bg-black bg-opacity-40">
      <div
        className={`w-[400px] m-2 p-6 rounded-[24px] relative ${
          isLightMode ? "bg-white" : "bg-[#02042C]"
        }`}
      >
        <MdClose
          className="absolute right-6 top-6 flex items-center w-6 h-6 cursor-pointer"
          onClick={onClose}
        />
        <div className="py-12 flex flex-col justify-center items-center gap-2">
          <img
            src={`${type === "success" ? Success : Failed}`}
            className="mx-auto h-10 w-10 mb-1"
            alt={`${type === "success" ? "Success" : "Failed"}`}
          />
          <h3
            className={`text-[20px] font-bold leading-[150%] ${
              isLightMode ? "text-[#181821]" : "text-[#FFFFFF]"
            }`}
          >
            {message}
          </h3>
          {type === "success" && (
            <button
              onClick={handleViewTransaction}
              className={`rounded-[12px] text-white text-[14px] leading-[150%] px-4 py-2 mt-5 w-[250px] flex items-center justify-center font-semibold bg-[#5663F2]`}
            >
              View Transaction
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectFeeConfirmationModal;
