import {
  parseUnits,
  formatUnits,
  BrowserProvider,
  Contract,
  isAddress,
  MaxInt256,
} from "ethers";
import { CurrencyAmount, Percent } from "@uniswap/sdk-core";
import { TickMath, TICK_SPACINGS } from "@uniswap/v3-sdk";
import Quoter from "@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json";
import {
  QUOTER_CONTRACT_ADDRESS,
  USDT_TOKEN,
  XBR_TOKEN,
  TBA_TOKEN,
} from "../web3Utils";
import {
  nearestUsableTick,
  NonfungiblePositionManager,
  Pool,
  Position,
} from "@uniswap/v3-sdk";
import { getLiquidity } from "./liquidity";
import { CurrentConfig } from "./config";
import {
  ERC20_ABI,
  MAX_FEE_PER_GAS,
  MAX_PRIORITY_FEE_PER_GAS,
  NONFUNGIBLE_POSITION_MANAGER_ABI,
  NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
} from "../web3Utils";
import { fromReadableAmount, fromReadableAmount1 } from "./conversion";
import { findPoolAddress, getPoolInfo } from "./pool";
import { fetchTokenObject } from "./liquidity";
import { connectWallet, getProviders, subscribeToProviders } from "./farm";
const positionManagerAddress = "0xC36442b4a4522E871399CD717aBDD847Ab11FE88";

// NonfungiblePositionManager ABI
const positionManagerAbi = [
  "function positions(uint256 tokenId) view returns (" +
    "uint96 nonce, address operator, address token0, address token1, uint24 fee, " +
    "int24 tickLower, int24 tickUpper, uint128 liquidity, " +
    "uint256 feeGrowthInside0LastX128, uint256 feeGrowthInside1LastX128, " +
    "uint128 tokensOwed0, uint128 tokensOwed1)",
  "function collect(tuple(uint256 tokenId, address recipient, uint128 amount0Max, uint128 amount1Max) params) " +
    "payable returns (uint256 amount0, uint256 amount1)",
];

export async function getTokenTransferApproval(token, value, walletProvider) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");

      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("getTokenTransferApproval", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const tokenContract = new Contract(token.address, ERC20_ABI, signer);

    const approveTx = await tokenContract.approve(
      NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      value
    );
    console.log("approveTx", approveTx);
    const receipt = await approveTx.wait();
    // Check transaction status
    if (receipt.status === 1) {
      console.log("LP- Transaction was successful!");
    } else {
      console.log("Transaction failed.");
    }

    console.log("receipt", receipt);
    return approveTx.hash;
  } catch (error) {
    console.error(error);
    return "Failed";
  }
}

export async function constructPosition(
  token0Amount,
  token1Amount,
  poolAddress,
  walletProvider
) {
  try {
    console.log("token0Amount", token0Amount);
    console.log("token1Amount", token1Amount);
    console.log("constructPool", poolAddress);
    // get pool info
    const poolInfo = await getPoolInfo(poolAddress, walletProvider);

    console.log("poolInfo", poolInfo);

    // construct pool instance
    const configuredPool = new Pool(
      token0Amount.currency,
      token1Amount.currency,
      poolInfo.fee,
      poolInfo.sqrtPriceX96.toString(),
      poolInfo.liquidity.toString(),
      poolInfo.tick
    );

    console.log("configuredPool", configuredPool);

    // create position using the maximum liquidity from input amounts
    return Position.fromAmounts({
      pool: configuredPool,
      tickLower: nearestUsableTick(
        TickMath.MIN_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      tickUpper: nearestUsableTick(
        TickMath.MAX_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      amount0: token0Amount.quotient,
      amount1: token1Amount.quotient,
      useFullPrecision: true,
    });
  } catch (error) {
    console.error("Error at constructPosition", error);
    return null;
  }
}

export async function constructPosition1(
  positionId,
  token0Amount,
  token1Amount,
  poolAddress,
  walletProvider
) {
  try {
    console.log("token0Amount", token0Amount);
    console.log("token1Amount", token1Amount);
    console.log("constructPool", poolAddress);
    // get pool info
    const poolInfo = await getPoolInfo(poolAddress, walletProvider);
    console.log("poolInfo", poolInfo);
    // construct pool instance
    const pool = new Pool(
      token0Amount.currency,
      token1Amount.currency,
      poolInfo.fee,
      poolInfo.sqrtPriceX96.toString(),
      poolInfo.liquidity.toString(),
      poolInfo.tick
    );

    const configuredPool = await getLiquidity(positionId, walletProvider);
    return Position.fromAmounts({
      pool: pool,
      tickLower: Number(configuredPool.tickLower),
      tickUpper: Number(configuredPool.tickUpper),
      amount0: token0Amount.quotient,
      amount1: token1Amount.quotient,
      useFullPrecision: true,
    });
    // return Position.fromAmounts({
    //   pool: configuredPool,
    //   tickLower: Number(nearestUsableTick(TickMath.MIN_TICK, TICK_SPACINGS[poolInfo.fee])),
    //   tickUpper: Number(nearestUsableTick(TickMath.MAX_TICK, TICK_SPACINGS[poolInfo.fee])),
    //   amount0: token0Amount.quotient,
    //   amount1: token1Amount.quotient,
    //   useFullPrecision: true,
    // });
  } catch (error) {
    console.error("Error at constructPosition", error);
    return null;
  }
}
// UPDATE - NOT USING CURRENTLY
// export async function modifyPosition(
//   positionId,
//   token0Amount,
//   token1Amount,
//   walletProvider
// ) {
//   try {
//     let signer;
//     let provider;
//     if (walletProvider) {
//       console.log("Connected to Wallet Connect", "Provider", walletProvider);
//       provider = new BrowserProvider(walletProvider);
//       signer = await provider?.getSigner();
//     } else {
//       console.log("connecting without walletConnect!");
//       await subscribeToProviders();
//       const providers = await getProviders();
//       const wallet = localStorage.getItem("walletName");

//       const providerWithInfo = await providers.find(
//         (provider) => provider.info.name === wallet
//       );
//       console.log("modifyPosition", providerWithInfo);
//       provider = new BrowserProvider(providerWithInfo?.provider);
//       signer = await provider?.getSigner();
//     }

//     const address = await signer?.getAddress();
//     console.log("props", token0Amount, token1Amount);

//     // const getPositionData = await getPositionIds(provider, address);
//     // const positionId = bigNumberToNumber(getPositionData[0]._hex);
//     // console.log(positionId); // Output: 288181

//     const positionToIncreaseBy = await constructPosition1(
//       CurrencyAmount.fromRawAmount(
//         CurrentConfig.tokens.token0,
//         fromReadableAmount(
//           // Number(token0Amount) * CurrentConfig.tokens.fractionToAdd,
//           Number(token0Amount),
//           CurrentConfig.tokens.token0.decimals
//         )
//       ),
//       CurrencyAmount.fromRawAmount(
//         CurrentConfig.tokens.token1,
//         fromReadableAmount(
//           // Number(token1Amount) * CurrentConfig.tokens.fractionToAdd,
//           Number(token1Amount),
//           CurrentConfig.tokens.token1.decimals
//         )
//       ),
//       "0x1eb8DFe76eE03Fd68Aa58f849CAD5590B4D12617",
//       walletProvider
//     );

//     console.log("positionToIncreaseBy", positionToIncreaseBy);

//     const addLiquidityOptions = {
//       deadline: Math.floor(Date.now() / 1000) + 60 * 20,
//       slippageTolerance: new Percent(5, 100),
//       tokenId: positionId,
//     };
//     console.log("addLiquidityOptions", addLiquidityOptions);

//     // get calldata for increasing a position
//     const { calldata, value } = NonfungiblePositionManager.addCallParameters(
//       positionToIncreaseBy,
//       addLiquidityOptions
//     );

//     // build transaction
//     const transaction = {
//       data: calldata,
//       to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
//       value: value,
//       from: address,
//       maxFeePerGas: MAX_FEE_PER_GAS,
//       maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS,
//     };
//     console.log("transaction", transaction);

//     const tx = await signer?.sendTransaction(transaction);

//     const receipt = await tx.wait();
//     return receipt.status !== null ? tx.hash : null;
//   } catch (error) {
//     console.error("Error at minting position:", error);
//   }
// }

export async function modifyPositionnew(
  positionId,
  token0name,
  token1name,
  token0Amount,
  token1Amount,
  walletProvider
) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");

      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("modifyPosition", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    let token0, token1;
    if (
      (token0name === "USDT" && token1name === "XBR") ||
      (token0name === "XBR" && token1name === "USDT")
    ) {
      token0 = USDT_TOKEN;
      token1 = XBR_TOKEN;
    } else if (
      (token0name === "USDT" && token1name === "TBA") ||
      (token0name === "TBA" && token1name === "USDT")
    ) {
      token0 = TBA_TOKEN;
      token1 = USDT_TOKEN;
    } else {
      token0 = TBA_TOKEN;
      token1 = XBR_TOKEN;
    }

    const address = await signer?.getAddress();
    console.log("props", token0Amount, token1Amount);

    const { poolAddress } = await findPoolAddress(
      token0.address,
      token1.address,
      walletProvider
    );

    const positionToIncreaseBy = await constructPosition1(
      positionId,
      CurrencyAmount.fromRawAmount(
        token0,
        fromReadableAmount1(Number(token0Amount), token0.decimals)
      ),
      CurrencyAmount.fromRawAmount(
        token1,
        fromReadableAmount1(Number(token1Amount), token1.decimals)
      ),
      poolAddress,
      walletProvider
    );

    console.log("positionToIncreaseBy", positionToIncreaseBy);

    const addLiquidityOptions = {
      deadline: Math.floor(Date.now() / 1000) + 60 * 20,
      slippageTolerance: new Percent(50, 10000),
      tokenId: positionId,
    };
    console.log("addLiquidityOptions", addLiquidityOptions);

    // get calldata for increasing a position
    const { calldata, value } = NonfungiblePositionManager.addCallParameters(
      positionToIncreaseBy,
      addLiquidityOptions
    );

    const fees = await provider.getFeeData();
    const maxFeePerGas = fees.maxFeePerGas || parseUnits("100", "gwei");
    const maxPriorityFeePerGas =
      fees.maxPriorityFeePerGas || parseUnits("1", "gwei");

    console.log("maxFeePerGas", formatUnits(maxFeePerGas, "gwei"));
    console.log(
      "maxPriorityFeePerGas",
      formatUnits(maxPriorityFeePerGas, "gwei")
    );

    // build transaction
    const transaction = {
      data: calldata,
      to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      value: value,
      from: address,
      maxFeePerGas: maxFeePerGas,
      maxPriorityFeePerGas: maxPriorityFeePerGas,
    };
    console.log("transaction", transaction);

    const tx = await signer?.sendTransaction(transaction);

    const receipt = await tx.wait();
    return receipt.status !== null ? tx.hash : null;
  } catch (error) {
    console.error("Error at minting position:", error);
  }
}
export async function getPositionIds(walletProvider) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
      await subscribeToProviders();
      const providers =  await getProviders();
      const wallet = localStorage.getItem("walletName");
  
      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("executeTrade-connectWallet", providerWithInfo);

      provider = new BrowserProvider(providerWithInfo?.provider);
    }
    const positionContract = new Contract(
      NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      NONFUNGIBLE_POSITION_MANAGER_ABI,
      signer
    );

    // console.log("tokenIds", tokenIds);

    // Fetch the position data
    const positionManagerContract = new Contract(
      positionManagerAddress,
      positionManagerAbi,
      signer
    );

    const address = await signer?.getAddress();

    // Get number of positions
    const balance = await positionContract.balanceOf(address);
    // console.log("balance", balance);

    // Get all positions
    const tokenIds = [];
    for (let i = 0; i < balance; i++) {
      const tokenOfOwnerByIndex = await positionContract.tokenOfOwnerByIndex(
        address,
        i
      );

      // Fetch the position
      const position = await positionManagerContract.positions(
        tokenOfOwnerByIndex
      );
      const { token0, token1 } = position;

      const { poolAddress } = await findPoolAddress(token0, token1,walletProvider);
      if (
        poolAddress === "0x1eb8DFe76eE03Fd68Aa58f849CAD5590B4D12617" ||
        poolAddress === "0x58f4112B2f44185D2613313bF2F0C6736A2717A6" ||
        poolAddress === "0xd6A31b81199F0281bE70Caf02Af7a9A04522B48F"
      )
        tokenIds.push(tokenOfOwnerByIndex.toString());
    }

    return tokenIds;
  } catch (error) {
    console.error("Error at getPositionIds:", error);
  }
}

export async function getFarmPositionIds(walletProvider) {
  try {
    const connectedAccount = localStorage.getItem("connectedAccount");
    if (!isAddress(connectedAccount)) {
      return;
    }

    let signer;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      const provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }
    const positionContract = new Contract(
      NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      NONFUNGIBLE_POSITION_MANAGER_ABI,
      signer
    );

    // console.log("tokenIds", tokenIds);

    // Fetch the position data
    const positionManagerContract = new Contract(
      positionManagerAddress,
      positionManagerAbi,
      signer
    );

    const address = await signer?.getAddress();

    // Get number of positions
    const balance = await positionContract.balanceOf(address);
    // console.log("balance", balance);

    // Get all positions
    const tokenIds = [];
    for (let i = 0; i < balance; i++) {
      const tokenOfOwnerByIndex = await positionContract.tokenOfOwnerByIndex(
        address,
        i
      );

      // Fetch the position
      const position = await positionManagerContract.positions(
        tokenOfOwnerByIndex
      );
      const { token0, token1 } = position;

      const { poolAddress } = await findPoolAddress(token0, token1);
      if (poolAddress === "0x1eb8DFe76eE03Fd68Aa58f849CAD5590B4D12617")
        tokenIds.push(tokenOfOwnerByIndex.toString());
    }

    return tokenIds;
  } catch (error) {
    console.error("Error at getPositionIds:", error);
  }
}

export async function quoteIn(
  poolAddress,
  amountIn,
  inDecimals,
  outDecimals,
  walletProvider
) {
  try {
    let signer;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }
    console.log(
      "Quote-in",
      "amountIn",
      amountIn,
      "inDecimals",
      inDecimals,
      "outDecimals",
      outDecimals
    );
    const quoterContract = new Contract(
      QUOTER_CONTRACT_ADDRESS,
      Quoter.abi,
      signer
    );
    const poolConstants = await getPoolInfo(poolAddress, walletProvider);

    console.log("poolConstants-QuoteIn", poolConstants);

    const quotedAmount = await quoterContract.quoteExactInputSingle.staticCall(
      poolConstants.token0,
      poolConstants.token1,
      poolConstants.fee,
      parseUnits(
        parseFloat(amountIn).toFixed(inDecimals),
        inDecimals === 18 ? 18 : 6
      ).toString(),
      // fromReadableAmount(amountIn, inDecimals).toString(),
      0
    );

    console.log("QUOTE RESULT", formatUnits(quotedAmount, outDecimals));

    const outAmount = formatUnits(quotedAmount, outDecimals);

    // const truncatedNumber = await removeDecimal(outAmount);
    // console.log("turncatedNumber", truncatedNumber);

    // // Convert the integer value to a string
    // let integerValueString = truncatedNumber.toString();

    // // Concatenate '000' to the last three digits of the string
    // let formattedValue = integerValueString.slice(0, -3) + "000";

    console.log("formattedValue :", outAmount);

    return outAmount;
  } catch (error) {
    console.error("Error at quote:", error);
  }
}

export async function quoteOut(
  poolAddress,
  amountIn,
  inDecimals,
  outDecimals,
  walletProvider
) {
  try {
    let signer;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }
    console.log(
      "Quote-out",
      "amountIn",
      amountIn,
      "inDecimals",
      inDecimals,
      "outDecimals",
      outDecimals
    );

    const quoterContract = new Contract(
      QUOTER_CONTRACT_ADDRESS,
      Quoter.abi,
      signer
    );
    const poolConstants = await getPoolInfo(poolAddress, walletProvider);

    console.log("poolConstants", {
      token0: poolConstants.token0,
      token1: poolConstants.token1,
      fee: poolConstants.fee,
    });

    const quotedAmount = await quoterContract.quoteExactInputSingle.staticCall(
      poolConstants.token1,
      poolConstants.token0,
      poolConstants.fee,
      parseUnits(amountIn.toString(), inDecimals === 18 ? 18 : 6).toString(), // Updated for ethers v6
      0
    );
    console.log("quotedAmount", quotedAmount);

    console.log("QUOTE RESULT", formatUnits(quotedAmount, outDecimals));

    return formatUnits(quotedAmount, outDecimals);
  } catch (error) {
    console.error("Error at quote:", error);
  }
}

// export async function mintPosition(token0Amount, token1Amount, walletProvider) {
//   try {
//     let signer;
//     let provider;
//     if (walletProvider) {
//       console.log("Connected to Wallet Connect", "Provider", walletProvider);
//       provider = new BrowserProvider(walletProvider);
//       signer = await provider?.getSigner();
//     } else {
//       console.log("connecting without walletConnect!");
//       await subscribeToProviders();
//       const providers = await getProviders();
//       const wallet = localStorage.getItem("walletName");

//       const providerWithInfo = await providers.find(
//         (provider) => provider.info.name === wallet
//       );
//       console.log("mintPosition-connectWallet", providerWithInfo);
//       provider = new BrowserProvider(providerWithInfo?.provider);
//       signer = await provider?.getSigner();
//     }

//     const address = await signer?.getAddress();

//     console.log("props", token0Amount, token1Amount);
//     const { poolAddress } = await findPoolAddress(
//       USDT_TOKEN.address,
//       XBR_TOKEN.address,
//       walletProvider
//     );

//     const positionToMint = await constructPosition(
//       CurrencyAmount.fromRawAmount(
//         CurrentConfig.tokens.token0,
//         fromReadableAmount(
//           Number(token0Amount),
//           CurrentConfig.tokens.token0.decimals
//         )
//       ),
//       CurrencyAmount.fromRawAmount(
//         CurrentConfig.tokens.token1,
//         fromReadableAmount(
//           Number(token1Amount),
//           CurrentConfig.tokens.token1.decimals
//         )
//       ),
//       poolAddress,
//       walletProvider
//     );

//     // console.log("positionToIncreaseBy", positionToIncreaseBy);
//     console.log("positionToMint", positionToMint);

//     const mintOptions = {
//       recipient: address,
//       deadline: Math.floor(Date.now() / 1000) + 60 * 20,
//       slippageTolerance: new Percent(1, 100),
//     };
//     console.log("mintOptions", mintOptions);

//     // get calldata for increasing a position
//     const { calldata, value } = NonfungiblePositionManager.addCallParameters(
//       // positionToIncreaseBy,
//       // addLiquidityOptions
//       positionToMint,
//       mintOptions
//     );

//     const fees = await provider?.getFeeData();
//     // const maxPriorityFeePerGas = await provider.getFeeData(true);

//     console.log("maxFeePerGas", formatUnits(fees.maxFeePerGas, "wei"));
//     console.log(
//       "maxPriorityFeePerGas",
//       formatUnits(fees.maxPriorityFeePerGas, "wei")
//     );

//     // build transaction
//     const transaction = {
//       data: calldata,
//       to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
//       value: value,
//       from: address,
//       maxFeePerGas: MAX_FEE_PER_GAS, // formatUnits(fees.maxFeePerGas, "wei"), //MAX_FEE_PER_GAS,
//       maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS, // formatUnits(fees.maxPriorityFeePerGas, "wei"), // MAX_PRIORITY_FEE_PER_GAS,
//     };
//     let gasLimit = await signer?.estimateGas(transaction);
//     console.log("Estimated gas limit:", formatUnits(gasLimit, "gwei"));
//     transaction.gasLimit = gasLimit;
//     console.log("transaction", transaction);

//     const tx = await signer?.sendTransaction(transaction);

//     const receipt = await tx.wait();
//     // Check transaction status
//     if (receipt.status === 1) {
//       console.log("Transaction was successful!");
//     } else {
//       console.log("Transaction failed.");
//     }
//     console.log("receipt", receipt);
//     return tx.hash;
//   } catch (error) {
//     console.error("Error at minting position:", error);
//     return "Failed";
//   }
// }

// export async function mintPositionTBA(
//   token0Amount,
//   token1Amount,
//   walletProvider
// ) {
//   try {
//     let signer;
//     let provider;
//     if (walletProvider) {
//       console.log("Connected to Wallet Connect", "Provider", walletProvider);
//       provider = new BrowserProvider(walletProvider);
//       signer = await provider.getSigner();
//     } else {
//       console.log("Connecting without WalletConnect!");
//       await subscribeToProviders();
//       const providers = await getProviders();
//       const wallet = localStorage.getItem("walletName");
//       const providerWithInfo = providers.find(
//         (provider) => provider.info.name === wallet
//       );
//       console.log("mintPosition-connectWallet", providerWithInfo);
//       provider = new BrowserProvider(providerWithInfo?.provider);
//       signer = await provider.getSigner();
//     }

//     const address = await signer.getAddress();

//     console.log("props", token0Amount, token1Amount);
//     const { poolAddress } = await findPoolAddress(
//       TBA_TOKEN.address,
//       USDT_TOKEN.address,
//       walletProvider
//     );

//     const positionToMint = await constructPosition(
//       CurrencyAmount.fromRawAmount(
//         CurrentConfig.tokens.token2,
//         fromReadableAmount(
//           Number(token0Amount),
//           CurrentConfig.tokens.token2.decimals
//         )
//       ),
//       CurrencyAmount.fromRawAmount(
//         CurrentConfig.tokens.token0,
//         fromReadableAmount(
//           Number(token1Amount),
//           CurrentConfig.tokens.token0.decimals
//         )
//       ),
//       poolAddress,
//       walletProvider
//     );

//     console.log("positionToMint", positionToMint);

//     const mintOptions = {
//       recipient: address,
//       deadline: Math.floor(Date.now() / 1000) + 60 * 20,
//       slippageTolerance: new Percent(50, 10_000),
//     };
//     console.log("mintOptions", mintOptions);

//     const { calldata, value } = NonfungiblePositionManager.addCallParameters(
//       positionToMint,
//       mintOptions
//     );

//     const fees = await provider.getFeeData();
//     const maxFeePerGas = fees.maxFeePerGas || parseUnits("100", "gwei"); // fallback value
//     const maxPriorityFeePerGas =
//       fees.maxPriorityFeePerGas || parseUnits("1", "gwei"); // fallback value

//     console.log("maxFeePerGas", formatUnits(maxFeePerGas, "gwei"));
//     console.log(
//       "maxPriorityFeePerGas",
//       formatUnits(maxPriorityFeePerGas, "gwei")
//     );

//     const transaction = {
//       data: calldata,
//       to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
//       value: value || 0, // ensure value is a number or 0
//       from: address,
//       maxFeePerGas: maxFeePerGas,
//       maxPriorityFeePerGas: maxPriorityFeePerGas,
//     };

//     let gasLimit = await signer.estimateGas(transaction);
//     console.log("Estimated gas limit:", formatUnits(gasLimit, "gwei"));
//     transaction.gasLimit = gasLimit;

//     console.log("transaction", transaction);

//     const tx = await signer.sendTransaction(transaction);
//     const receipt = await tx.wait();

//     if (receipt.status === 1) {
//       console.log("Transaction was successful!");
//     } else {
//       console.log("Transaction failed.");
//     }
//     console.log("receipt", receipt);
//     return tx.hash;
//   } catch (error) {
//     console.error("Error at minting position:", error);
//     return "Failed";
//   }
// }

export async function mintPositionNew(
  token0Amount,
  token1Amount,
  token0name,
  token1name,
  walletProvider
) {
  try {
    let signer;
    let provider;

    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider.getSigner();
    } else {
      console.log("Connecting without WalletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");
      const providerWithInfo = providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("mintPosition-connectWallet", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider.getSigner();
    }

    const address = await signer.getAddress();

    // Token selection logic based on the tokenPair
    let token0, token1;
    if (
      (token0name === "USDT" && token1name === "XBR") ||
      (token0name === "XBR" && token1name === "USDT")
    ) {
      token0 = USDT_TOKEN;
      token1 = XBR_TOKEN;
    } else if (
      (token0name === "USDT" && token1name === "TBA") ||
      (token0name === "TBA" && token1name === "USDT")
    ) {
      token0 = TBA_TOKEN;
      token1 = USDT_TOKEN;
    } else {
      token0 = TBA_TOKEN;
      token1 = XBR_TOKEN;
    }

    console.log("props", token0Amount, token1Amount);
    const { poolAddress } = await findPoolAddress(
      token0.address,
      token1.address,
      walletProvider
    );

    const positionToMint = await constructPosition(
      CurrencyAmount.fromRawAmount(
        token0,
        fromReadableAmount1(Number(token0Amount), token0.decimals)
      ),
      CurrencyAmount.fromRawAmount(
        token1,
        fromReadableAmount1(Number(token1Amount), token1.decimals)
      ),
      poolAddress,
      walletProvider
    );

    console.log("positionToMint", positionToMint);

    const mintOptions = {
      recipient: address,
      deadline: Math.floor(Date.now() / 1000) + 60 * 20,
      slippageTolerance: new Percent(50, 10_000),
    };
    console.log("mintOptions", mintOptions);

    const { calldata, value } = NonfungiblePositionManager.addCallParameters(
      positionToMint,
      mintOptions
    );

    const fees = await provider.getFeeData();
    const maxFeePerGas = fees.maxFeePerGas || parseUnits("100", "gwei");
    const maxPriorityFeePerGas =
      fees.maxPriorityFeePerGas || parseUnits("1", "gwei");

    console.log("maxFeePerGas", formatUnits(maxFeePerGas, "gwei"));
    console.log(
      "maxPriorityFeePerGas",
      formatUnits(maxPriorityFeePerGas, "gwei")
    );

    const transaction = {
      data: calldata,
      to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      value: value || 0,
      from: address,
      maxFeePerGas: maxFeePerGas,
      maxPriorityFeePerGas: maxPriorityFeePerGas,
    };

    let gasLimit = await signer.estimateGas(transaction);
    console.log("Estimated gas limit:", formatUnits(gasLimit, "gwei"));
    transaction.gasLimit = gasLimit;

    console.log("transaction", transaction);

    const tx = await signer.sendTransaction(transaction);
    const receipt = await tx.wait();

    if (receipt.status === 1) {
      console.log("Transaction was successful!");
    } else {
      console.log("Transaction failed.");
    }
    console.log("receipt", receipt);
    return tx.hash;
  } catch (error) {
    console.error("Error at minting position:", error);
    return "Failed";
  }
}

//CHANGE THIS TO DYNAMIC
// export async function removeLiquidity(
//   token0Address,
//   token1Address,
//   token0Amount,
//   token1Amount,
//   positionId,
//   walletProvider,
//   setIsConfirmModalOpen,
//   setIsRemoveConfirmed
// ) {
//   if (positionId.length === 0) {
//     alert("No Positions Selected!");
//     return;
//   }
//   setIsConfirmModalOpen(true);

//   console.log(
//     "removeLiquidity",
//     token0Address,
//     token1Address,
//     token0Amount,
//     token1Amount,
//     positionId
//   );

//   try {
//     let signer;
//     let provider;
//     if (walletProvider) {
//       console.log("Connected to Wallet Connect", "Provider", walletProvider);
//       provider = new BrowserProvider(walletProvider);
//       signer = await provider?.getSigner();
//     } else {
//       console.log("connecting without walletConnect!");
//       await subscribeToProviders();
//       const providers = await getProviders();
//       const wallet = localStorage.getItem("walletName");

//       const providerWithInfo = await providers.find(
//         (provider) => provider.info.name === wallet
//       );
//       console.log("removeLiquidity-connectWallet", providerWithInfo);
//       provider = new BrowserProvider(providerWithInfo?.provider);
//       signer = await provider?.getSigner();
//     }

//     const address = await signer.getAddress();

//     const token0 = await fetchTokenObject(token0Address, signer);
//     const token1 = await fetchTokenObject(token1Address, signer);

//     const { poolAddress, fee } = await findPoolAddress(
//       token0Address,
//       token1Address,
//       walletProvider
//     );

//     const currentPosition = await constructPosition1(
//         CurrencyAmount.fromRawAmount(
//           CurrentConfig.tokens.token0,
//           fromReadableAmount(
//             Number(token0Amount),
//             CurrentConfig.tokens.token0.decimals
//           )
//         ),
//         CurrencyAmount.fromRawAmount(
//           CurrentConfig.tokens.token1,
//           fromReadableAmount(
//             Number(token1Amount),
//             CurrentConfig.tokens.token1.decimals
//           )
//         ),
//         poolAddress,
//         walletProvider
//       );

//     console.log("currentPosition", currentPosition);

//     const collectOptions = {
//       expectedCurrencyOwed0: CurrencyAmount.fromRawAmount(token0, 0),
//       expectedCurrencyOwed1: CurrencyAmount.fromRawAmount(token1, 0),
//       recipient: address,
//     };

//     const removeLiquidityOptions = {
//       deadline: Math.floor(Date.now() / 1000) + 60 * 20,
//       slippageTolerance: new Percent(5, 100),
//       tokenId: positionId,
//       // percentage of liquidity to remove
//       liquidityPercentage: new Percent(1),
//       collectOptions,
//     };
//     // get calldata for minting a position
//     const { calldata, value } = NonfungiblePositionManager.removeCallParameters(
//       currentPosition,
//       removeLiquidityOptions
//     );

//     const fees = await provider?.getFeeData();
//     // const maxPriorityFeePerGas = await provider.getFeeData(true);

//     console.log("maxFeePerGas", formatUnits(fees.maxFeePerGas, "wei"));
//     console.log(
//       "maxPriorityFeePerGas",
//       formatUnits(fees.maxPriorityFeePerGas, "wei")
//     );

//     // build transaction
//     const transaction = {
//       data: calldata,
//       to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
//       value: value,
//       from: address,
//       maxFeePerGas: MAX_FEE_PER_GAS, // formatUnits(fees.maxFeePerGas, "wei"),
//       // maxPriorityFeePerGas: MAX_PRIORITY_FEE_PER_GAS, // formatUnits(fees.maxPriorityFeePerGas, "wei"),
//     };
//     let gasLimit = await signer.estimateGas(transaction);
//     transaction.gasLimit = gasLimit;

//     const tx = await signer?.sendTransaction(transaction);
//     const receipt = await tx.wait();
//     // Check transaction status
//     if (receipt.status === 1) {
//       setIsConfirmModalOpen(false);
//       setIsRemoveConfirmed(true);
//       console.log("RemoveLiquidity- Transaction was successful!");
//     } else {
//       console.log("Transaction failed.");
//     }
//     console.log("receipt", receipt);
//     return tx.hash;
//   } catch (error) {
//     setIsConfirmModalOpen(false);
//     console.error("Error at removing liquidity:", error);
//   }
// }

export async function removeLiquiditynew(
  token0Amount,
  token1Amount,
  token0name,
  token1name,
  positionId,
  walletProvider,
  setIsConfirmModalOpen,
  setIsRemoveConfirmed,
  setIsTransactionFailed,
  setError,
  setHashdata,
  setIsLiquidityChanged,
  setRemoveLiquidity
) {
  if (positionId.length === 0) {
    alert("No Positions Selected!");
    return;
  }
  setIsConfirmModalOpen(true);

  console.log(
    "Props",
    token0Amount,
    token1Amount,
    token0name,
    token1name,
    positionId
  );

  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");

      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("removeLiquidity-connectWallet", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const address = await signer.getAddress();

    let token0, token1;
    if (
      (token0name === "USDT" && token1name === "XBR") ||
      (token0name === "XBR" && token1name === "USDT")
    ) {
      token0 = USDT_TOKEN;
      token1 = XBR_TOKEN;
    } else if (
      (token0name === "USDT" && token1name === "TBA") ||
      (token0name === "TBA" && token1name === "USDT")
    ) {
      token0 = TBA_TOKEN;
      token1 = USDT_TOKEN;
    } else {
      token0 = XBR_TOKEN;
      token1 = TBA_TOKEN;
    }

    const { poolAddress, fee } = await findPoolAddress(
      token0.address,
      token1.address,
      walletProvider
    );

    const currentPosition = await constructPosition1(
      positionId,
      CurrencyAmount.fromRawAmount(
        token0,
        fromReadableAmount1(Number(token0Amount), token0.decimals)
      ),
      CurrencyAmount.fromRawAmount(
        token1,
        fromReadableAmount1(Number(token1Amount), token1.decimals)
      ),
      poolAddress,
      walletProvider
    );

    console.log("currentPosition", currentPosition);

    const collectOptions = {
      expectedCurrencyOwed0: CurrencyAmount.fromRawAmount(token0, 0),
      expectedCurrencyOwed1: CurrencyAmount.fromRawAmount(token1, 0),
      recipient: address,
    };

    const removeLiquidityOptions = {
      deadline: Math.floor(Date.now() / 1000) + 60 * 20,
      slippageTolerance: new Percent(5, 100),
      tokenId: positionId,
      liquidityPercentage: new Percent(1),
      collectOptions,
    };
    // get calldata for minting a position
    const { calldata, value } = NonfungiblePositionManager.removeCallParameters(
      currentPosition,
      removeLiquidityOptions
    );

    const fees = await provider.getFeeData();
    const maxFeePerGas = fees.maxFeePerGas || parseUnits("100", "gwei");
    const maxPriorityFeePerGas =
      fees.maxPriorityFeePerGas || parseUnits("1", "gwei");

    console.log("maxFeePerGas", formatUnits(maxFeePerGas, "gwei"));
    console.log(
      "maxPriorityFeePerGas",
      formatUnits(maxPriorityFeePerGas, "gwei")
    );

    // build transaction
    const transaction = {
      data: calldata,
      to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      value: value,
      from: address,
      maxFeePerGas: maxFeePerGas, // formatUnits(fees.maxFeePerGas, "wei"),
      maxPriorityFeePerGas: maxPriorityFeePerGas, // formatUnits(fees.maxPriorityFeePerGas, "wei"),
    };
    let gasLimit = await signer.estimateGas(transaction);
    transaction.gasLimit = gasLimit;

    const tx = await signer?.sendTransaction(transaction);
    const receipt = await tx.wait();
    // Check transaction status
    if (receipt.status === 1) {
      setHashdata(tx.hash);
      setIsConfirmModalOpen(false);
      setIsRemoveConfirmed(true);
      setIsLiquidityChanged(true);
      setTimeout(() => setRemoveLiquidity(false), 5000);
      console.log("RemoveLiquidity- Transaction was successful!");
    } else {
      console.log("Transaction failed.");
    }
    console.log("receipt", receipt);
    return tx.hash;
  } catch (error) {
    setIsConfirmModalOpen(false);
    setIsTransactionFailed(true);
    setError(error.message);
    console.error("Error at removing liquidity:", error);
  }
}

export async function getEstimatedValueForLiquidity(
  token,
  amount,
  walletProvider
) {
  const { poolAddress } = await findPoolAddress(
    USDT_TOKEN.address,
    XBR_TOKEN.address,
    walletProvider
  );

  const poolInfo = await getPoolInfo(poolAddress, walletProvider);

  const tokens = {
    USDT: CurrentConfig.tokens.token0,
    XBR: CurrentConfig.tokens.token1,
  };

  // construct pool instance
  const pool = new Pool(
    token === CurrentConfig.tokens.token0.name ? tokens.USDT : tokens.XBR,
    token === CurrentConfig.tokens.token0.name ? tokens.XBR : tokens.USDT,
    poolInfo.fee,
    poolInfo.sqrtPriceX96.toString(),
    poolInfo.liquidity.toString(),
    poolInfo.tick
  );
  console.log("TOKEN-SELECT", token);
  if (token === USDT_TOKEN.symbol) {
    const position = new Position.fromAmount0({
      pool,
      tickLower: nearestUsableTick(
        TickMath.MIN_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      tickUpper: nearestUsableTick(
        TickMath.MAX_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      amount0: CurrencyAmount.fromRawAmount(
        CurrentConfig.tokens.token0,
        fromReadableAmount(Number(amount), CurrentConfig.tokens.token0.decimals)
      ).quotient,
      useFullPrecision: true, // Recommended
    });
    return position.amount1.toSignificant(6);
  } else {
    const position = new Position.fromAmount1({
      pool,
      tickLower: nearestUsableTick(
        TickMath.MIN_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      tickUpper: nearestUsableTick(
        TickMath.MAX_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      amount1: CurrencyAmount.fromRawAmount(
        CurrentConfig.tokens.token1,
        fromReadableAmount(Number(amount), CurrentConfig.tokens.token1.decimals)
      ).quotient,
      useFullPrecision: true, // Recommended
    });
    return position.amount0.toSignificant(6);
  }
}

export async function getEstimatedValueForLiquidityTBA(
  token,
  amount,
  walletProvider
) {
  const { poolAddress } = await findPoolAddress(
    TBA_TOKEN.address,
    USDT_TOKEN.address,
    walletProvider
  );

  const poolInfo = await getPoolInfo(poolAddress, walletProvider);

  const tokens = {
    USDT: CurrentConfig.tokens.token0,
    TBA: CurrentConfig.tokens.token2, // TBA token assignment
  };

  // Construct pool instance with appropriate tokens
  const pool = new Pool(
    token === TBA_TOKEN.symbol ? tokens.TBA : tokens.USDT,
    token === TBA_TOKEN.symbol ? tokens.USDT : tokens.TBA,
    poolInfo.fee,
    poolInfo.sqrtPriceX96.toString(),
    poolInfo.liquidity.toString(),
    poolInfo.tick
  );
  console.log("TOKEN-SELECT", token);

  // Handle precision for the USDT token
  if (token === USDT_TOKEN.symbol) {
    const position = new Position.fromAmount1({
      pool,
      tickLower: nearestUsableTick(
        TickMath.MIN_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      tickUpper: nearestUsableTick(
        TickMath.MAX_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      amount1: CurrencyAmount.fromRawAmount(
        tokens.USDT, // USDT is the token with amount1
        fromReadableAmount(Number(amount), tokens.USDT.decimals) // Ensuring proper decimal conversion
      ).quotient,
      useFullPrecision: true, // Recommended
    });
    return position.amount0.toSignificant(6); // Return the corresponding TBA value
  } else {
    // Handle precision for the TBA token
    const position = new Position.fromAmount0({
      pool,
      tickLower: nearestUsableTick(
        TickMath.MIN_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      tickUpper: nearestUsableTick(
        TickMath.MAX_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      amount0: CurrencyAmount.fromRawAmount(
        tokens.TBA, // TBA is the token with amount0
        fromReadableAmount(Number(amount), tokens.TBA.decimals) // Ensuring proper decimal conversion
      ).quotient,
      useFullPrecision: true, // Recommended
    });
    return position.amount1.toSignificant(6); // Return the corresponding USDT value
  }
}

export async function getEstimatedValueForLiquiditynew(
  token0name,
  token1name,
  amount,
  walletProvider
) {
  let token0, token1;
  if (
    (token0name === "USDT" && token1name === "XBR") ||
    (token0name === "XBR" && token1name === "USDT")
  ) {
    token0 = USDT_TOKEN;
    token1 = XBR_TOKEN;
  } else if (
    (token0name === "USDT" && token1name === "TBA") ||
    (token0name === "TBA" && token1name === "USDT")
  ) {
    token0 = TBA_TOKEN;
    token1 = USDT_TOKEN;
  } else {
    token0 = TBA_TOKEN;
    token1 = XBR_TOKEN;
  }

  const { poolAddress } = await findPoolAddress(
    token0.address,
    token1.address,
    walletProvider
  );

  const poolInfo = await getPoolInfo(poolAddress, walletProvider);

  // Construct pool instance using dynamic token selection
  const pool = new Pool(
    token0 === token0.symbol ? token0 : token1,
    token0 === token0.symbol ? token1 : token0,
    poolInfo.fee,
    poolInfo.sqrtPriceX96.toString(),
    poolInfo.liquidity.toString(),
    poolInfo.tick
  );

  // Handle precision for token0
  if (token0name === token0.symbol) {
    const position = new Position.fromAmount0({
      pool,
      tickLower: nearestUsableTick(
        TickMath.MIN_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      tickUpper: nearestUsableTick(
        TickMath.MAX_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      amount0: CurrencyAmount.fromRawAmount(
        token0,
        fromReadableAmount(Number(amount), token0.decimals)
      ).quotient,
      useFullPrecision: true, // Recommended
    });
    return position.amount1.toSignificant(6); // Return corresponding token1 value
  } else {
    // Handle precision for token1
    const position = new Position.fromAmount1({
      pool,
      tickLower: nearestUsableTick(
        TickMath.MIN_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      tickUpper: nearestUsableTick(
        TickMath.MAX_TICK,
        TICK_SPACINGS[poolInfo.fee]
      ),
      amount1: CurrencyAmount.fromRawAmount(
        token1,
        fromReadableAmount(Number(amount), token1.decimals)
      ).quotient,
      useFullPrecision: true, // Recommended
    });
    return position.amount0.toSignificant(6); // Return corresponding token0 value
  }
}

export async function collectfees(
  positionId,
  token0name,
  token1name,
  walletProvider
) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");

      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("removeLiquidity-connectWallet", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const address = await signer.getAddress();
    const positionManagerContract = new Contract(
      positionManagerAddress,
      positionManagerAbi,
      signer
    );

    let token0, token1;
    if (
      (token0name === "USDT" && token1name === "XBR") ||
      (token0name === "XBR" && token1name === "USDT")
    ) {
      token0 = USDT_TOKEN;
      token1 = XBR_TOKEN;
    } else if (
      (token0name === "USDT" && token1name === "TBA") ||
      (token0name === "TBA" && token1name === "USDT")
    ) {
      token0 = TBA_TOKEN;
      token1 = USDT_TOKEN;
    } else {
      token0 = TBA_TOKEN;
      token1 = XBR_TOKEN;
    }

    const position = await positionManagerContract.positions(positionId);

    const collectOptions = {
      tokenId: positionId,
      expectedCurrencyOwed0: CurrencyAmount.fromRawAmount(
        token0,
        Number(position.tokensOwed0)
      ),
      expectedCurrencyOwed1: CurrencyAmount.fromRawAmount(
        token1,
        Number(position.tokensOwed1)
      ),
      recipient: address,
    };
    console.log("collect options", collectOptions);
    const { calldata, value } =
      NonfungiblePositionManager.collectCallParameters(collectOptions);

    const fees = await provider.getFeeData();
    const maxFeePerGas = fees.maxFeePerGas || parseUnits("100", "gwei");
    const maxPriorityFeePerGas =
      fees.maxPriorityFeePerGas || parseUnits("1", "gwei");

    console.log("maxFeePerGas", formatUnits(maxFeePerGas, "gwei"));
    console.log(
      "maxPriorityFeePerGas",
      formatUnits(maxPriorityFeePerGas, "gwei")
    );

    const transaction = {
      data: calldata,
      to: NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      value: value,
      from: address,
      maxFeePerGas: maxFeePerGas,
      maxPriorityFeePerGas: maxPriorityFeePerGas,
    };
    let gasLimit = await signer.estimateGas(transaction);
    transaction.gasLimit = gasLimit;

    const tx = await signer?.sendTransaction(transaction);
    const receipt = await tx.wait();

    if (receipt.status === 1) {
      console.log("Transaction was successful!");
    } else {
      console.log("Transaction failed.");
    }
    return tx.hash;
  } catch (error) {
    console.error("Error at removing liquidity:", error);
  }
}

export async function getCollectFees(
  tokenId,
  token0name,
  token1name,
  walletProvider
) {
  try {
    let signer;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }

    const MAX_UINT128 = "340282366920938463463374607431768211455";
    const address = await signer.getAddress();
    const positionManagerContract = new Contract(
      positionManagerAddress,
      positionManagerAbi,
      signer
    );

    let token0, token1;
    if (
      (token0name === "USDT" && token1name === "XBR") ||
      (token0name === "XBR" && token1name === "USDT")
    ) {
      token0 = USDT_TOKEN;
      token1 = XBR_TOKEN;
    } else if (
      (token0name === "USDT" && token1name === "TBA") ||
      (token0name === "TBA" && token1name === "USDT")
    ) {
      token0 = TBA_TOKEN;
      token1 = USDT_TOKEN;
    } else {
      token0 = TBA_TOKEN;
      token1 = XBR_TOKEN;
    }

    const params = {
      tokenId,
      recipient: address,
      amount0Max: MAX_UINT128,
      amount1Max: MAX_UINT128,
    };

    const result = await positionManagerContract.collect.staticCall(params);

    const processedResult = [
      formatUnits(result[0].toString(), token0.decimals),
      formatUnits(result[1].toString(), token1.decimals),
    ];

    return processedResult;
  } catch (error) {
    console.error("Error at removing liquidity:", error);
  }
}
