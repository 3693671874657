import { useTheme } from "@emotion/react";

const MyNftShimmer = () => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";
  return (
    <div className="animate-pulse flex flex-col gap-4 p-4 sm:p-6 mr-2 rounded-[16px]">
      <div className="flex items-center justify-between">
        <div className="flex flex-col-reverse sm:flex-row justify-between sm:items-center gap-3">
          <div
            className={`w-[200px] h-10 rounded-[8px] ${
              isLightMode ? "bg-[#ccc8ed]" : "bg-[#2f265c]"
            }`}
          ></div>
          <div
            className={`w-[78px] h-3 ${
              isLightMode ? "bg-[#ccc8ed]" : "bg-[#2f265c]"
            } rounded-[8px]`}
          ></div>
        </div>
        <div
          className={`w-[78px] h-3 ${
            isLightMode ? "bg-[#ccc8ed]" : "bg-[#2f265c]"
          } rounded-[8px]`}
        ></div>
      </div>
      <div
        className={`w-[300px] sm:w-[381px] h-3 ${
          isLightMode ? "bg-[#ccc8ed]" : "bg-[#2f265c]"
        } rounded-[8px]`}
      ></div>
    </div>
  );
};

export default MyNftShimmer;
