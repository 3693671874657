import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import LoaderComponent from "../components/LoaderComponent";
import NewSwapComponent from "./newSwap";
import LiquidityComponent from "./liquidity";
import LPFarmComponent from "./lpFarm";
import ShowToast from "../components/showToast";
import Footer from "../components/Footer";
import axios from "axios";
import FailedTransaction from "../components/FailedTransaction.jsx";
import Tooltip from "@mui/material/Tooltip";
import CustomTooltip from "../components/CustomTooltip.jsx";
import NewLiquidityComponent from "./newLiquidity.jsx";
import NewLPFarmComponent from "../components/NewLPFarm.jsx";

const NewFarm = () => {
  const theme = useTheme();
  const alt = theme.palette.background.alt;
  const [activeSection, setActiveSection] = useState(
    parseInt(localStorage.getItem("activeTab")) || 1
  );
  const [isTransactionFailedOpen, setIsTransactionFailedOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSectionClick = (sectionNumber) => {
    setActiveSection(sectionNumber);
    localStorage.setItem("activeTab", sectionNumber.toString());
  };

  const fetchUsdtPrice = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/v1/xbr/usdt-price`
      );
      return response.data?.usd;
    } catch (error) {
      console.error("Error in fetching USDT Price", error);
    }
  };

  const openFailedTransactionModal = () => {
    setIsTransactionFailedOpen(true);
  };

  const closeFailedTransactionModal = () => {
    setIsTransactionFailedOpen(false);
  };

  return (
    <div className="relative">
      {/* Background decorations */}
      {theme.palette.mode === "light" ? (
        <>
          <div
            className="absolute top-10 right-0 4xl:right-[20%] h-[507.098px] w-[410.121px] opacity-20 flex-shrink-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
              filter: "blur(190.72238159179688px)",
            }}
          ></div>
          <div
            className="absolute mt-[25vh] left-0 4xl:left-[20%] h-[507.098px] w-[410.121px] opacity-20 flex-shrink-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
              filter: "blur(190.72238159179688px)",
            }}
          ></div>
        </>
      ) : (
        <>
          <div
            className="absolute top-10 right-0 h-[507.098px] w-[310.121px] opacity-30 flex-shrink-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
              filter: "blur(230.72238159179688px)",
            }}
          ></div>
          <div
            className="absolute top-[20vh] -left-10 h-[507.098px] w-[310.121px] opacity-30 flex-shrink-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
              filter: "blur(230.72238159179688px)",
            }}
          ></div>
        </>
      )}
      <div
        className={`pb-5 sm:pb-10 ${
          theme.palette.mode === "light" ? "bg-[#EEEFFE]" : "bg-[#02042C]"
        }`}
      >
        {loading && (
          <LoaderComponent transactionText="Transaction is in progress..." />
        )}
        <div className="sm:pt-15 pb-1 flex flex-col font-inter mx-auto w-full max-w-[calc(80rem+2rem)] z-10">
          <div className="flex flex-col font-inter mx-auto w-full  items-center justify-center z-10 p-2 sm:p-4">
            {/* Tab Section */}
            <div
              className={`w-full xs:w-[30.5rem] flex flex-row items-center justify-center box-border text-white  p-6 pb-0 ${
                theme.palette.mode === "light" ? "bg-white" : "bg-[#050840]"
              }`}
              style={{
                borderRadius: "32px 32px 0px 0px",
              }}
            >
              {/* Tab buttons */}
              <div
                className={`rounded-lg  w-full flex flex-row items-center justify-between box-border text-white  m-auto text-base`}
              >
                <button
                  className={`flex-1 rounded-[24px] flex flex-row items-center justify-center p-3 cursor-pointer ${
                    activeSection === 1
                      ? theme.palette.mode === "light"
                        ? "bg-[#EEEFFE] text-[#4954CE] font-semibold"
                        : "bg-[#181B56]"
                      : theme.palette.mode === "light"
                      ? "text-mediumslateblue-600 font-normal"
                      : ""
                  }`}
                  onClick={() => handleSectionClick(1)}
                >
                  Swap
                </button>

                {/* Liquidity Button (Disabled) with Tooltip */}

                <button
                  className={`flex-1 rounded-[24px] flex flex-row items-center justify-center p-3 cursor-pointer ${
                    activeSection === 2
                      ? theme.palette.mode === "light"
                        ? "bg-[#EEEFFE] text-[#4954CE] font-semibold"
                        : "bg-[#181B56]"
                      : theme.palette.mode === "light"
                      ? "text-mediumslateblue-600 font-normal"
                      : ""
                  }`}
                  onClick={() => handleSectionClick(2)}
                >
                  Liquidity
                </button>

                {/* LP Farm Button (Disabled) with Tooltip */}

                <button
                  className={`flex-1 rounded-lg flex flex-row items-center justify-center p-3 ${
                    theme.palette.mode === "light"
                      ? "text-mediumslateblue-600"
                      : "text-white"
                  } bg-transparent cursor-default`}
                  disabled
                >
                  <CustomTooltip content="Coming Soon">LP Farm</CustomTooltip>
                </button>
              </div>
            </div>

            {activeSection === 1 && (
              <NewSwapComponent fetchUsdtPrice={fetchUsdtPrice} />
            )}
            {activeSection === 2 && (
              <NewLiquidityComponent
                fetchUsdtPrice={fetchUsdtPrice}
                setMainActiveSection={setActiveSection}
              />
            )}
            {activeSection === 3 && (
              <NewLPFarmComponent
                fetchUsdtPrice={fetchUsdtPrice}
                setMainActiveSection={setActiveSection}
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NewFarm;
