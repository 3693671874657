import JSBI from "jsbi";
import { connectWallet } from "./farm";
import { Contract, formatUnits } from "ethers";
import { USDT_ABI } from "../text";

function fromReadableAmount(amount, decimals) {
  const extraDigits = Math.pow(10, countDecimals(amount));
  const adjustedAmount = amount * extraDigits;
  return JSBI.divide(
    JSBI.multiply(
      JSBI.BigInt(adjustedAmount),
      JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))
    ),
    JSBI.BigInt(extraDigits)
  );
}

function fromReadableAmount1(amount, decimals) {
  // Ensure amount is a fixed-point number with appropriate precision
  const amountAsBigInt = JSBI.BigInt(Math.round(amount * Math.pow(10, decimals)));
  
  // Perform calculations using BigInt
  return JSBI.divide(
    JSBI.multiply(
      amountAsBigInt,
      JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))
    ),
    JSBI.BigInt(Math.pow(10, decimals))
  );
}

function toReadableAmount(rawAmount, decimals) {
  return JSBI.divide(
    JSBI.BigInt(rawAmount),
    JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(decimals))
  ).toString();
}

function countDecimals(x) {
  if (Math.floor(x) === x) {
    return 0;
  }
  return x.toString().split(".")[1].length || 0;
}

async function estimateLiquidityPrice(token0Amount) {
  try {
    const price = Number(1.06618);
    console.log("TOKEN0AMOUNT", token0Amount);
    console.log("PRICE", price);
    return token0Amount * price;
  } catch (error) {
    console.error("error at price conversion", error);
  }
}

async function estimateGasPriceForXBR(tokens, toAddress) {
  try {
    const { signer } = await connectWallet();

    const xbrContract = new Contract(
      process.env.REACT_APP_XBR_CONTRACT_ADDRESS,
      USDT_ABI,
      signer
    );

    const gasLimit = await xbrContract.estimateGas.transfer(toAddress, tokens);

    const gasPrice = await signer.getGasPrice();

    return {
      gasPrice: formatUnits(gasPrice, "gwei"),
      gasLimit: formatUnits(gasLimit, "gwei"),
    };
  } catch (error) {
    console.error("Error estimating gas price for XBR:", error);
    return null;
  }
}

export {
  fromReadableAmount,
  toReadableAmount,
  estimateLiquidityPrice,
  estimateGasPriceForXBR,
  fromReadableAmount1,
};
