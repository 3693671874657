import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main styles
import "react-date-range/dist/theme/default.css"; // theme styles
import { calendarToday, swapVert1 } from "../../assets";
import { addDays } from "date-fns";
import { format } from "date-fns";
import { useTheme, IconButton } from "@mui/material";

const formatTimeAgo = (timestamp) => {
  const timeDifference = Date.now() - new Date(timestamp).getTime();
  const minutes = Math.floor(timeDifference / (60 * 1000));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days}d ${hours % 24}h ago`;
  if (hours > 0) return `${hours}h ${minutes % 60}m ago`;
  return `${minutes}m ago`;
};

const formatAmount = (amount) => {
  if (!amount) return "N/A";
  const num = parseFloat(amount);
  let integerPart = Math.floor(num).toString().slice(0, 5);
  let fractionalPart = num.toFixed(4).split(".")[1].slice(0, 5);
  return `${integerPart}.${fractionalPart}`;
};

const TradesTable = ({
  activeTab,
  connectedWalletAddress,
  selectedToken,
  selectedToken2,
  swapConfirmModal
}) => {
  const [tradesData, setTradesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [showPicker, setShowPicker] = useState(false);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setShowPicker(false); // Close picker after selection
  };
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "descending",
  });

  const token1 = selectedToken?.name === "USDT" 
  ? selectedToken2?.name 
  : selectedToken2?.name === "USDT" 
    ? selectedToken?.name 
    : "XBR"

  // const token2 = selectedToken?.name === "USDT" 
  // ? selectedToken?.name 
  // : selectedToken2?.name === "USDT" 
  //   ? selectedToken2?.name 
  //   : "XBR";


  useEffect(() => {
    const fetchTrades = async () => {
      setIsLoading(true);
      const headers = {
        accept: "application/json",
        "x-cg-pro-api-key": "CG-opSoXUB7U7UYsV6SkYnNoSYX",
      };
      let poolAddress;
      if ((selectedToken.name === "XBR" && selectedToken2.name === "USDT") ||
          (selectedToken.name === "USDT" && selectedToken2.name === "XBR")) {
              poolAddress = "0x1eb8dfe76ee03fd68aa58f849cad5590b4d12617";
      } else if ((selectedToken.name === "TBA" && selectedToken2.name === "USDT") ||
              (selectedToken.name === "USDT" && selectedToken2.name === "TBA")) {
              poolAddress = "0x58f4112b2f44185d2613313bf2f0c6736a2717a6";
      } else {
        poolAddress = "0xd6a31b81199f0281be70caf02af7a9a04522b48f";
      }

      const baseUrl =
        activeTab === "latest"
          ? `https://pro-api.coingecko.com/api/v3/onchain/networks/polygon_pos/pools/${poolAddress}/trades`
          : `https://app.geckoterminal.com/api/p1/polygon_pos/pools/0x58f4112b2f44185d2613313bf2f0c6736a2717a6/swaps?include=from_token%2Cto_token&inverted=0&page%5Bafter%5D=1715366872&pair_id=2927146&sender=${connectedWalletAddress}`;

      const fetchTradesData = async (url) => {
        try {
          const response = await fetch(url, { method: "GET", headers });
          if (!response.ok)
            throw new Error(`HTTP error! Status: ${response.status}`);
          const data = await response.json();
      
          if (data.data.length === 0) {
            throw new Error("No data found.");
          }
          return data;
        } catch (err) {
          console.error("Error fetching trades:", err);
          return null; // return null if there's an error
        }
      };

      try {
        let data = await fetchTradesData(baseUrl);
        if (!data) {
          const fallbackPoolAddress = "0x1eb8dfe76ee03fd68aa58f849cad5590b4d12617";
          const fallbackUrl = `https://pro-api.coingecko.com/api/v3/onchain/networks/polygon_pos/pools/${fallbackPoolAddress}/trades`;
          console.log("Fetching fallback XBR/USDT pool...");
          data = await fetchTradesData(fallbackUrl);
        }
        if (data) {
          setTradesData(
            data.data.map((item) => ({
              block_number: item.attributes.block_number,
              block_timestamp: formatTimeAgo(item.attributes.block_timestamp),
              kind: item.attributes.kind,
              from_token_amount: formatAmount(item.attributes.from_token_amount),
              to_token_amount: formatAmount(item?.attributes?.to_token_amount),
              price_to_in_currency_token: formatAmount(
                item?.attributes?.price_to_in_currency_token
              ),
              price_from_in_usd: formatAmount(
                item?.attributes?.price_from_in_usd
              ),
            }))
          );
        }
      } catch (err) {
        console.error("Error fetching trades:", err);
      }
      setIsLoading(false);
    };
    fetchTrades();
  }, [
    activeTab,
    connectedWalletAddress,
    dateRange,
    sortConfig,
    selectedToken,
    selectedToken2,
    swapConfirmModal
  ]);

  const sortedTrades = tradesData.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const theme = useTheme();
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);


  return (
    <div className="overflow-x-auto relative w-full">
      <table className="w-full text-sm text-left">
        <thead className="sticky top-0 z-10 ">
          <tr
            className={`flex justify-start items-center w-full ${
              theme.palette.mode === "light"
                ? "text-[#181821]"
                : "text-[#DCDCDE]"
            }`}
          >
            <th className="min-w-[23%] py-[12.5px]">INDEX</th>
            <th className="min-w-[31%] py-[12.5px]">TIME</th>
            <th className="min-w-[23%] py-[12.5px]">USDT</th>
            <th className="min-w-[23%] py-[12.5px]">
              {token1}
            </th>
          </tr>
        </thead>
        <tbody className="block overflow-y-auto hide-scrollbar h-[225px]">
          {tradesData.map((row, index) => (
            <tr
              key={index}
              className={`flex w-full ${
                row.kind === "buy" ? "text-[#3E9536]" : "text-[#AC3D46]"
              }`}
            >
              <td className="flex-1 min-w-[23%] py-3 truncate text-[#56565D]">
                {row.block_number}
              </td>
              <td
                className={`flex-1 min-w-[31%] py-3 truncate ${
                  theme.palette.mode === "light"
                    ? " text-[#56565D]"
                    : "text-[#97979B]"
                }`}
              >
                {row.block_timestamp}
              </td>
              <td className="flex-1 min-w-[23%] py-3 truncate">
                {row.kind === "buy"
                  ? row.from_token_amount
                  : row.to_token_amount}
              </td>
              <td className="flex-1 min-w-[23%] py-3 truncate">
                {row.kind === "buy"
                  ? row.to_token_amount
                  : row.from_token_amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TradesTable;
