import { connectWallet, getProviders, subscribeToProviders } from "./farm";
import { fetchTokenObject } from "./liquidity";
import { findPoolAddress, getPoolInfo } from "./pool";
import { quoteIn, quoteOut } from "./position";
import { executeTrade } from "./swap";
import axios from "axios";
import {
  formatUnits,
  Contract,
  parseUnits,
  BrowserProvider,
  JsonRpcProvider,
  Wallet,
} from "ethers";
import { ERC20_ABI, xbrMiningAbi, xbrNodeAbi } from "../web3Utils";
export const erc20Abi = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function decimals() view returns (uint8)",
  "function balanceOf(address account) view returns (uint256)",
  "function totalSupply() view returns (uint256)",
];

const CLAIM_XBR_SIGNATURE = "0x15d5692e";


export async function getQuotes(type1, type2, value, walletProvider) {
  try {
    console.log("getQuotes", type1, type2, value);
    const TOKEN0 = await fetchTokenAddress(type1, walletProvider);
    const TOKEN1 = await fetchTokenAddress(type2, walletProvider);
    const { poolAddress } = await findPoolAddress(
      TOKEN0.address,
      TOKEN1.address,
      walletProvider
    );
    console.log({ TOKEN0: TOKEN0.address, TOKEN1: TOKEN1.address });
    console.log("PPOL ADDRESS", poolAddress);

    const { token0, token1 } = await getPoolInfo(poolAddress, walletProvider);
    console.log("token0", token0, "token1", token1);

    if (token0 === TOKEN0.address && token1 === TOKEN1.address) {
      console.log("using tokenIn");
      return await quoteIn(
        poolAddress,
        Number(value).toFixed(TOKEN1.decimals),
        TOKEN0.decimals,
        TOKEN1.decimals,
        walletProvider
      );
    }
    if (token0 === TOKEN1.address && token1 === TOKEN0.address) {
      console.log("using tokneOut");
      return await quoteOut(
        poolAddress,
        Number(value).toFixed(TOKEN0.decimals),
        TOKEN0.decimals,
        TOKEN1.decimals,
        walletProvider
      );
    }
  } catch (error) {
    console.log("Error at getQuotes:", error);
  }
}

export async function fetchTokenAddress(token, walletProvider) {
  try {
    let signer;
    if (walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }

    if (token === "USDT") {
      return fetchTokenObject(process.env.REACT_APP_USDT_ADDRESS, signer);
    } else if (token === "TBA") {
      return fetchTokenObject(
        process.env.REACT_APP_TBA_CONTRACT_ADDRESS,
        signer
      );
    } else if (token === "XBR") {
      return fetchTokenObject(
        process.env.REACT_APP_XBR_CONTRACT_ADDRESS,
        signer
      );
    } else {
      throw new Error(`Invalid token: ${token}`);
    }
  } catch (error) {
    console.log("Error at fetchTokenAddress:", error);
    throw error;
  }
}

export async function createSwap(
  token0,
  token1,
  token0Amount,
  token1Amount,
  slippageTolerance, 
  walletProvider
) {
  try {
    console.log(
      "CreateSwap",
      "token0: ",
      token0,
      "token1: ",
      token1,
      "token0Amount: ",
      token0Amount,
      "token1Amount: ",
      token1Amount,
      "slippageTolerance: ",
      slippageTolerance
    );

    const token0Object = await fetchTokenAddress(token0, walletProvider);
    const token1Object = await fetchTokenAddress(token1, walletProvider);
    console.log(
      "token0Object",
      token0Object.address,
      "token1Object",
      token1Object.address
    );

    const { poolAddress } = await findPoolAddress(
      token0Object.address,
      token1Object.address,
      walletProvider
    );

    if (!poolAddress) {
      throw new Error("Could not find pool address");
    }

    console.log("Found Pool", poolAddress);

    const createSwapTrade = await executeTrade(
      token0Object,
      token1Object,
      parseFloat(token0Amount).toFixed(token0Object.decimals),
      parseFloat(token1Amount).toFixed(token1Object.decimals),
      slippageTolerance,
      poolAddress,
      walletProvider
    );
    return createSwapTrade;
  } catch (error) {
    console.log("Error at createSwap:", error);
  }
}

export function shortenTransactionHash(txHash, length = 4) {
  if (!txHash || typeof txHash !== "string" || txHash.length < 2 * length + 2) {
    throw new Error("Invalid transaction hash");
  }
  return `${txHash.slice(0, length + 2)}.......${txHash.slice(-length)}`;
}

const formatBalance = (balance) => {
  // Ensure the balance is treated as a number
  const num = parseFloat(balance);
  if (num >= 1e12) {
    return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
  } else if (num >= 1e9) {
    return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
  } else {
    return num.toFixed(2).replace(/\.00$/, ""); // Output as a string with two decimal places
  }
};

export async function fetchMarketData(poolAddress) {
  try {
    // const token0 = await fetchTokenAddress(token0Name);
    // const token1 = await fetchTokenAddress(token1Name);
    // const { poolAddress } = await findPoolAddress(
    //   token0.address,
    //   token1.address
    // );
    const URL = process.env.REACT_APP_API;
    const responses = await axios.get(`${URL}/v1/xbr/coin-price?`, {
      params: {
        address: poolAddress,
        count: 157860000,
      },
    });

    const coinMarketCapData = await responses.data.response
      .coinMarketCapResponseData.data;
    const poolInfoHolders = formatBalance(coinMarketCapData.poolInfoD.holders);
    const liquidity = formatBalance(coinMarketCapData?.poolInfoD.liquidity);
    const totalSupply = formatBalance(coinMarketCapData?.totalSupply);
    const fdv = formatBalance(coinMarketCapData?.fdv);
    const volume24h = formatBalance(coinMarketCapData?.volume24h);
    const price = await coinMarketCapData?.priceUsd;

    // console.log("coinMarketCapData", {
    //   poolInfoHolders,
    //   liquidity,
    //   totalSupply,
    //   fdv,
    //   volume24h,
    //   price,
    // });

    return { liquidity, totalSupply, fdv, volume24h, price, poolInfoHolders };
  } catch (error) {
    console.error("Error fetching market data:", error);
  }
}

export async function fetchBalance(token) {
  try {
    const { signer, address } = await connectWallet();
    const tokenObject = await fetchTokenAddress(token);
    const contract = new Contract(tokenObject.address, erc20Abi, signer);
    const balance = await contract.balanceOf(address);
    const formattedBalance = formatBalance(
      formatUnits(balance, tokenObject.decimals)
    );
    return formattedBalance;
  } catch (error) {
    console.error("Error fetching balance:", error);
  }
}

export async function getTotalSupply(token) {
  try {
    const provider = new JsonRpcProvider(process.env.REACT_APP_INFURA_URL);
    const contract = new Contract(token, erc20Abi, provider);
    const totalSupply = await contract.totalSupply();
    console.log("totalSupply", totalSupply.toString());
    return totalSupply.toString();
  } catch (error) {
    console.error("Error fetching total supply:", error);
  }
}

export async function getNodeIds(walletProvider) {
  try {
    console.log("getNodeIds-walletProvider", walletProvider);
    let signer;
    if (walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }

    const contract = new Contract(
      process.env.REACT_APP_CONTRACT_ADDRESS,
      xbrNodeAbi,
      signer
    );
    const signerAddress = await signer.getAddress();

    const balance = await contract.balanceOf(signerAddress);
    const tokenIds = [];
    for (let i = 0; i < balance; i++) {
      const tokenOfOwnerByIndex = await contract.tokenOfOwnerByIndex(
        signerAddress,
        i
      );
      tokenIds.push(tokenOfOwnerByIndex.toString());
    }
    return tokenIds;
  } catch (error) {
    console.log("Error fetching getNodeIds:", error);
  }
}

export async function getMiningRewards(walletProvider) {
  try {
    let signer;
    if (walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }

    const contract = new Contract(
      process.env.REACT_APP_XBR_MINING_CONTRACT_ADDRESS,
      xbrMiningAbi,
      signer
    );

    const getNodes = await getNodeIds(walletProvider);
    // console.log("getNodes", getNodes);

    const allNodes = await contract.getAllNodeIds();

    // Convert the Proxy object to a normal array and convert BigInt to string
    const nodeIds = Array.from(allNodes).map((nodeId) => nodeId.toString());
    // console.log("getAllNodeIds", nodeIds);

    const filterNodes = getNodes.filter((node) => nodeIds.includes(node));
    // console.log("filterNodes", filterNodes);

    if (filterNodes.length === 0) return [];

    const currentBlock = Number(await getCurrentBlockNumber());

    const nodeDataPromises = filterNodes.map(async (nodeId) => {
      const [details] = await Promise.all([contract.getNodeDetails(nodeId)]);

      return {
        nodeId,
        miningStartedBlockNumber: details.miningStartedBlockNumber.toString(),
        lastlyClaimedRewardsBlock: details.lastlyClaimedRewardsBlock.toString(),
        totalRewardsClaimed: formatUnits(
          parseFloat(details.totalRewardsClaimed).toString(),
          18
        ),
        // details.totalRewardsClaimed.toString(),
        rewardsEarned: await getRewardsFromServer(
          details.lastlyClaimedRewardsBlock.toString() !== "0"
            ? details.lastlyClaimedRewardsBlock.toString()
            : details.miningStartedBlockNumber.toString(),
          currentBlock
        ),
        isActive: details.isActive,
      };
    });

    // Await all node data
    const Nodes = await Promise.all(nodeDataPromises);

    Nodes.totalClaims = Nodes.map((node) => node.totalRewardsClaimed).reduce(
      (a, b) => Number(a) + Number(b),
      0
    );

    // console.log("Nodes", Nodes);

    return Nodes;
  } catch (error) {
    console.error("Error fetching mining rewards:", error);
  }
}

export async function getCurrentBlockNumber() {
  try {
    // const API_KEY = "Z4C4BRJD8EB51HEP4HWPX5745FB3P2TP5I";
    // const URL = `https://api.polygonscan.com/api?module=proxy&action=eth_blockNumber&apikey=${API_KEY}`;
    // const response = await axios.get(URL);
    // if (response.data && response.data.result) {
    //   const currentBlockNumber = parseInt(response.data.result, 16);
    //   console.log(`Current Block Number: ${currentBlockNumber}`);

    const provider = new JsonRpcProvider(process.env.REACT_APP_INFURA_URL);

    const blockNumber = await provider.getBlockNumber();
    console.log("Current block number:", blockNumber);
    return blockNumber;
  } catch (error) {
    console.error("Error fetching block number", error);
  }
}

export async function getRewardsFromServer(lastBlock, currentBlock) {
  try {
    const URL = process.env.REACT_APP_API;
    const response = await axios.get(`${URL}/v1/xbr/rewards`, {
      params: {
        lastBlock,
        currentBlock,
      },
    });
    // console.log("getRewardsFromServer:", response.data);
    return response.data.totalRewards;
  } catch (error) {
    console.error("Error fetching rewards:", error);
  }
}

export async function approveUSDTForXBRNODE(amount, walletProvider) {
  try {
    console.log("approveUSDTForXBRNODE", amount, walletProvider);
    let signer;
    if (walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }

    const contract = new Contract(
      process.env.REACT_APP_USDT_ADDRESS,
      xbrNodeAbi,
      signer
    );

    const token0 = await fetchTokenObject(
      process.env.REACT_APP_USDT_ADDRESS,
      signer
    );
    const formatAmount = parseUnits(amount.toString(), token0.decimals);
    const tx = await contract.approve(
      process.env.REACT_APP_CONTRACT_ADDRESS,
      formatAmount
    );
    const receipt = await tx.wait();
    return receipt.status !== null ? tx.hash : null;
  } catch (error) {
    console.error("Error approveUSDTForXBRNODE:", error.reason);
    throw new Error(error?.reason);
  }
}

export async function buyNode(_quantity, _referralWallet, _walletProvider) {
  try {
    console.log("buyNode", _quantity, _referralWallet, _walletProvider);
    let signer;
    if (_walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", _walletProvider);
      const ethersProvider = new BrowserProvider(_walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }

    const contract = new Contract(
      process.env.REACT_APP_CONTRACT_ADDRESS,
      xbrNodeAbi,
      signer
    );

    const tx = await contract.buyNode(_quantity, _referralWallet);
    const receipt = await tx.wait();
    return receipt.status !== null ? receipt : null;
  } catch (error) {
    console.error("Error buyNode:", error);
    throw new Error(error?.reason);
  }
}

export async function claimXBRRewardsForNodes(
  nodeId,
  rewardAmount,
  walletProvider
) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers =  await getProviders();
      const wallet = localStorage.getItem("walletName");
  
      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("claimXBRRewardsForNodes", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }


    const address = await signer?.getAddress();
    const contract = new Contract(
      process.env.REACT_APP_XBR_MINING_CONTRACT_ADDRESS,
      xbrMiningAbi,
      signer
    );

    const feeData = await provider?.getFeeData();
    const gasPrice = parseInt(feeData.gasPrice) * 5;

    const formatAmount = parseUnits(rewardAmount.toString(), 18);

    const gasLimit = parseInt(await contract.claimXBR.estimateGas(
      nodeId, address, formatAmount
    )) * 5;

    console.log("GAS", feeData.gasPrice);
    console.log("gasLimit", gasLimit.toString(), "gasPrice", gasPrice.toString());

    console.log("claimXBRRewards", formatAmount.toString(), address, nodeId);
    const tx = await contract.claimXBR(nodeId, address, formatAmount, {
      gasLimit: gasLimit.toString(),
      gasPrice: gasPrice.toString(),
    });
    const receipt = await tx.wait();
    return receipt.status !== null ? tx.hash : null;
  } catch (error) {
    console.error("Error claimXBRRewardsForNodes:", error);
    throw new Error(error?.reason);
  }
}

export async function transferNodes(nodeId, address, walletProvider) {
  try {
    console.log("transferNodes", nodeId, address, walletProvider);
    let signer;
    if (walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }

    const contract = new Contract(
      process.env.REACT_APP_CONTRACT_ADDRESS,
      xbrNodeAbi,
      signer
    );
    const transferFromTx = await contract.transferFrom(
      await signer.getAddress(),
      address,
      nodeId
    );
    const receipt = await transferFromTx.wait();
    return receipt.status !== null ? transferFromTx.hash : null;
  } catch (error) {
    console.error("Error transferNodes:", error);
  }
}

export async function approveForTransferNodes(nodeId, address, walletProvider) {
  try {
    console.log("approveForTransferNodes", nodeId, address, walletProvider);
    let signer;
    if (walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      signer = await ethersProvider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
    }

    const contract = new Contract(
      process.env.REACT_APP_CONTRACT_ADDRESS,
      xbrNodeAbi,
      signer
    );
    const approveTx = await contract.approve(address, nodeId);

    const receipt = await approveTx.wait();
    return receipt.status !== null ? approveTx.hash : null;
  } catch (error) {
    console.error("Error transferNodes:", error);
  }
}

export async function registerNode(nodeIds, address) {
  try {

    const url = process.env.REACT_APP_API;
    const apiUrl = `${url}/v1/xbr/register`;
    const payload = {
      nodeIds: nodeIds,
      address: address
    };

    // Make the HTTP POST request
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    // Check if the response is okay
    if (!response.ok) {
      const error = await response.text();
      throw new Error(`API call failed: ${error}`);
    }

    // Parse the JSON response
    const data = await response.json();

    // Log and return the result
    console.log("registerNode-response", data);
    return data;

  } catch (error) {
    console.error("Error in registerNode:", error);
    throw new Error(error.message);
  }
}

export async function approveRewardsFromMediator(rewardAmount) {
  try {
    const url = process.env.REACT_APP_API;
    const apiUrl = `${url}/v1/xbr/mediator`;
    const payload = {
      reward: rewardAmount.toString()
    };

    // Make the HTTP POST request
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    // Check if the response is okay
    if (!response.ok) {
      const error = await response.text();
      throw new Error(`API call failed: ${error}`);
    }

    // Parse the JSON response
    const data = await response.json();

    // Log and return the result
    console.log("approveRewardsFromMediator-response", data);
    return data;

  } catch (error) {
    console.error("Error approveRewardsFromMediator", error);
    throw new Error(error.message);
  }
}

export async function balanceOfFromContract(owner, length) {
  try {
    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const provider = new JsonRpcProvider(process.env.REACT_APP_INFURA_URL);
    const contract = new Contract(contractAddress, erc20Abi, provider);

    const balance = await contract.balanceOf(owner);
    console.log("balance: " + balance);
    return balance;
  } catch (error) {
    console.error("Error fetching balance:", error);
    throw error;
  }
}

export async function getNFTCount(walletAddress) {
  const API_KEY = "Z4C4BRJD8EB51HEP4HWPX5745FB3P2TP5I";
       const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const url = `https://api.polygonscan.com/api?module=account&action=tokennfttx&address=${walletAddress}&contractaddress=${contractAddress}&page=1&offset=100&sort=asc&apikey=${API_KEY}`;

  try {
    const response = await axios.get(url);
    const data = response.data;

    if (data.status === '1') {
      console.log(data.result);
      const nftCount = data.result.length;
      console.log(`The wallet ${walletAddress} holds ${nftCount} NFTs from the contract ${contractAddress}.`);
      return nftCount;
    } else if (data.status === '0' && data.message === 'No transactions found') {
      console.log(`The wallet ${walletAddress} does not hold any NFTs from the contract ${contractAddress}.`);
      return 0;
    } else {
      console.error('Error fetching data:', data.message);
      return 0;
    }
  } catch (error) {
    console.error('Error during API request:', error);
    return 0;
  }
}

export async function getUserTransactions() {
  try {
    const address = localStorage.getItem("connectedAccount");
    console.log("getUserTransactions", address);
    const response = await axios.get(`https://api.polygonscan.com/api`, {
      params: {
        module: "account",
        action: "txlist",
        address,
        startblock: 0,
        endblock: 99999999,
        sort: "asc",
        apikey: '2SUHUZHN7KW5RMF9W2FZQJ8PHVIVIQI6QU',
      },
    });
    return response.data.result;
  } catch (error) {
    console.error("Error fetching user transactions:", error);
  }
}

export async function filterClaimXBRTransactions() {
  const transactions = await getUserTransactions();
  if (!transactions || !Array.isArray(transactions)) return [];

  return transactions.filter(
    (tx) =>
      tx.to.toLowerCase() === process.env.REACT_APP_XBR_MINING_CONTRACT_ADDRESS.toLowerCase() &&
      tx.input.startsWith(CLAIM_XBR_SIGNATURE) &&
      tx.txreceipt_status === "1"
  );
}


export async function getClaimXBRDetails() {
  
  // Filter transactions based on existing logic (assuming it works as intended)
  const filteredTransactions = await filterClaimXBRTransactions();

  if (!filteredTransactions.length) return [];

  const txHashs = filteredTransactions.map((tx) => tx.hash);
  // console.log("txHashs", txHashs);

  const providerUrl = process.env.REACT_APP_INFURA_URL;
  const provider = new JsonRpcProvider(providerUrl);

  const contract = new Contract(process.env.REACT_APP_XBR_MINING_CONTRACT_ADDRESS, xbrMiningAbi, provider);

  const results = [];

  // console.log("txHash", txHashs);
  for (const txHash of txHashs) {
    const receipt = await provider.getTransactionReceipt(txHash);
    const transferLogs = receipt?.logs[2];
    const parsedLog = contract.interface.parseLog(transferLogs);
    results.push({
      txHash,
      address: parsedLog.args?.user.toString(),
      nodeId: parsedLog.args?.nodeId.toString(),
      claimedBlock: parsedLog.args?.claimedBlock.toString(),
      rewardPricePerBlock: formatUnits(parsedLog.args?.rewardPricePerBlock.toString(), 18),
    });
  }
  // console.log("REWARD_TX", results)
  return results;
}


export  async function isValidReferralAddress(address) {
  try {
    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const provider = new JsonRpcProvider(process.env.REACT_APP_INFURA_URL);
    const contract = new Contract(contractAddress, xbrNodeAbi, provider);

    const hasXBRNodeNFT = await contract.hasXBRNodeNFT(address);
    console.log('hasXBRNodeNFT: ' + hasXBRNodeNFT) 
    return hasXBRNodeNFT;
  } catch (error) {
    console.error("Error isValidReferralAddress:", error);
    throw new Error(error?.reason);
  }
}

export async function totalClaimedXBRRewards() {
  try {
    const contractAddress = process.env.REACT_APP_XBR_MINING_CONTRACT_ADDRESS;
    const provider = new JsonRpcProvider(process.env.REACT_APP_INFURA_URL);
    const contract = new Contract(contractAddress, xbrMiningAbi, provider);

    const totalDistributed = await contract.getTotalRewardsDistributed();

    const format = formatUnits(totalDistributed.toString(), 18);
    // console.log("totalDistributed: " + format);
    return format;
  } catch (error) {
    console.error("Error totalClaimedXBRRewards:", error);
    throw new Error(error?.reason);
  }
}