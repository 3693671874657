import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main styles
import "react-date-range/dist/theme/default.css"; // theme styles
import { calendarToday, swapVert1 } from "../../assets";
import { addDays } from "date-fns";
import { format } from "date-fns";
import { useTheme, IconButton } from "@mui/material";
import { fetchTransactions } from "../../uniswap/lpfarm";

const formatTimeAgo = (timestamp) => {
  const timeDifference = Date.now() - new Date(timestamp).getTime();
  const minutes = Math.floor(timeDifference / (60 * 1000));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days}d ${hours % 24}h ago`;
  if (hours > 0) return `${hours}h ${minutes % 60}m ago`;
  return `${minutes}m ago`;
};

const formatAmount = (amount) => {
  if (!amount) return "N/A";
  const num = parseFloat(amount);
  let integerPart = Math.floor(num).toString().slice(0, 5);
  let fractionalPart = num.toFixed(4).split(".")[1].slice(0, 5);
  return `${integerPart}.${fractionalPart}`;
};

const FarmingTable = ({
  activeTab,
  connectedWalletAddress,
  selectedToken,
  selectedToken2,
  swapConfirmModal,
}) => {
  const [tradesData, setTradesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [showPicker, setShowPicker] = useState(false);
  const [farmTrxns, setFarmTrxns] = useState([]);
  const [userFarmTxs, setUserFarmTxs] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "descending",
  });

  // Fetch data from the API
  const getData = async () => {
    const userAddress = localStorage.getItem("connectedAccount");
    try {
      const farmAllTrades = await fetchTransactions();
      const farmUserTrades = await fetchTransactions(userAddress);

      setUserFarmTxs(farmUserTrades);
      if (farmAllTrades) {
        setTradesData(
          farmAllTrades.map((item) => ({
            id: item.tokenId,
            timestamp: formatTimeAgo(item.timeStamp * 1000), // Convert UNIX timestamp to ms
            event: item.eventName,
            txhash: item.transactionHash,
          }))
        );
      }
      setFarmTrxns(farmAllTrades);
    } catch (error) {
      console.error("Error getting data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Handle sorting logic
  const sortedTrades = tradesData.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const theme = useTheme();

  return (
    <div className="overflow-x-auto relative w-full">
      <table className="w-full text-sm text-left">
        <thead className="sticky top-0 z-10 ">
          <tr
            className={`flex justify-start items-center w-full ${
              theme.palette.mode === "light"
                ? "text-[#181821]"
                : "text-[#DCDCDE]"
            }`}
          >
            <th className="min-w-[23%] py-[12.5px]">ID</th>
            <th className="min-w-[31%] py-[12.5px]">TIME</th>
            <th className="min-w-[23%] py-[12.5px]">EVENT</th>
            <th className="min-w-[23%] py-[12.5px]">TX HASH</th>
          </tr>
        </thead>
        <tbody className="block overflow-y-auto hide-scrollbar h-[225px]">
          {sortedTrades.map((row, index) => (
            <tr key={index} className="flex w-full text-[#56565D]">
              <td className="flex-1 min-w-[23%] py-3 truncate">{row.id}</td>
              <td
                className={`flex-1 min-w-[31%] py-3 truncate ${
                  theme.palette.mode === "light"
                    ? " text-[#56565D]"
                    : "text-[#97979B]"
                }`}
              >
                {row.timestamp}
              </td>
              <td
                className={`flex-1 min-w-[23%] py-3 truncate capitalize ${
                  row.event === "stake" ? "text-[#3E9536]" : "text-[#AC3D46]"
                }`}
              >
                {row.event}
              </td>
              <td className="flex-1 min-w-[23%] py-3 truncate">{`${row.txhash.slice(
                0,
                4
              )}...${row.txhash.slice(-3)}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FarmingTable;
