import { useTheme } from "@mui/material";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { stake, getApprove } from "../uniswap/farm";
import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { getPositionIds, removeLiquidity, getFarmPositionIds } from "../uniswap/position";
import { NavLink } from "react-router-dom";
import { NewCheck } from "../assets";
import { USDTimg, XBRimg, tba } from "../assets";
import { getLiquidity } from "../uniswap/liquidity.js";
import { ReactComponent as NoLiquidity } from "../assets/NoLiquidity.svg";
import LiquidityConfirmationModal from "./LiquidityConfirmationModal";
import LiquidityConfirmedModal from "./LiquidityConfirmedModal";
import { getEstimatedLPTokens , farmShare } from "../uniswap/farm.js";
import { BiLoaderAlt } from "react-icons/bi";
import { useRef } from "react";
import DepositConfirmationModal from "./DepositConfirmationModal.jsx";
import LPFarmSuccessModal from "./LPFarmSuccessModal.jsx";
import { isAddress } from "ethers";

const DepositLiquidity = (props) => {
  const { setMainActiveSection } = props;
  const { walletProvider } = useWeb3ModalProvider();
  const theme = useTheme();
  const connectedAccount = localStorage.getItem("connectedAccount");
  const [positionIds, setPositionIds] = useState([]);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [removeLiquidityValue, setRemoveLiquidityValue] = useState(0);
  const [removeToken0Liquidity, setRemoveToken0Liquidity] = useState(0);
  const [removeToken1Liquidity, setRemoveToken1Liquidity] = useState(0);
  const [token0LiquidityAmount, setToken0LiquidityAmount] = useState("0.00");
  const [token1LiquidityAmount, setToken1LiquidityAmount] = useState("0.00");
  const [token0Address, setToken0Address] = useState("");
  const [token1Address, setToken1Address] = useState("");
  const [liquidityData, setLiquidityData] = useState({});
  const [token0Image, setToken0Image] = useState(USDTimg);
  const [token1Image, setToken1Image] = useState(XBRimg);
  const [token0Name, setToken0Name] = useState("USDT");
  const [token1Name, setToken1Name] = useState("XBR");
  const [farmInputValue, setFarmInputValue] = useState("");
  const [isFarm, setIsFarm] = useState(false);
  const [isfarmLoading, setIsFarmLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDepositLoading, setDepositIsLoading] = useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDepositConfirmed, setIsDepositConfirmed] = useState(false);
  const [isDepositFailed, setIsDepositFailed] = useState(false);
  const [isFetchingPositions, setIsFetchingPositions] = useState(false);
  const [farmpercentrage, setfarmpercentrage] = useState();
  const tokenImageMap = {
    XBR: XBRimg,
    USDT: USDTimg,
    TBA: tba,
  };

  
  const calculateRemoveLiquidity = (balance) => {
    return (balance * removeLiquidityValue) / 100;
  };

  const formatBalanceForPrice = (balance) => {
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return num.toFixed(4).replace(/\.?0+$/, "");
    }
  };

  useEffect(() => {
    const fetchPositionIds = async () => {
      setIsFetchingPositions(true);
      try {
        const isConnected = localStorage.getItem("connectedAccount") !== null;
        if (!isConnected) {
          setPositionIds([]);
          return;
        }
        const positions = await getFarmPositionIds(walletProvider);
        if (positions && positions.length) {
          setPositionIds(positions);
        } else {
          setPositionIds([]);
        }
      } catch (error) {
        console.error("Error fetching positionIds:", error);
        setPositionIds([]);
      } finally {
        setIsFetchingPositions(false);
      }
    };
    fetchPositionIds();
  }, [walletProvider]);

  useEffect(() => {
    setRemoveToken0Liquidity(calculateRemoveLiquidity(token0LiquidityAmount));
    setRemoveToken1Liquidity(calculateRemoveLiquidity(token1LiquidityAmount));
  }, [removeLiquidityValue]);

  const handleDropdownToggle = (event) => {
    event.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };

  const getLiquidityDataforid = async (option) => {
    const { token0, token1, fee } = await getLiquidity(
      Number(option),
      walletProvider
    );
    setToken0LiquidityAmount(token0?.amount);
    setToken1LiquidityAmount(token1?.amount);

    setToken0Address(token0?.address);
    setToken1Address(token1?.address);
  };

  const handlePositionSelect = async (position) => {
    setSelectedPosition(position);
    setDropdownOpen(false);
    setIsFarm(false);
    try {
      const result = await getEstimatedLPTokens(
        position,
        process.env.REACT_APP_FARMV2_CONTRACT_ADDRESS
      );
      console.log("Estimated LP Tokens: ", result);
      setFarmInputValue(result);
      console.log("Estimated LP Tokens: ", result);
    } catch (error) {
      console.error("Error getting estimated LP Tokens:", error);
    }
  };

  const getLiquidityData = async (option) => {
    const { token0, token1, fee } = await getLiquidity(
      Number(option),
      walletProvider
    );

    const token0LiquidityAmount = token0.amount;
    const token1LiquidityAmount = token1.amount;

    const token0Name = token0.symbol;
    const token1Name = token1.symbol;

    setLiquidityData((prevData) => ({
      ...prevData,
      [option]: {
        token0LiquidityAmount,
        token1LiquidityAmount,
        token0Name,
        token1Name,
      },
    }));
  };

  useEffect(() => {
    if (!positionIds.length) return;
    positionIds.forEach(async (option) => {
      await getLiquidityData(option);
    });
  }, [positionIds]);

  const handleApproveForFarm = async (token) => {
    try {
      setIsLoading(true);
      // fetch tokenId
      setIsFarmLoading(true);
      const tokenId = Number(selectedPosition);
      console.log("tokenId", typeof tokenId);
      const res = await getApprove(walletProvider, tokenId);
      if (res !== "Failed" && res !== null && res !== undefined) {
        console.log("Called Approve Swap Pop Up");
        setIsFarm(true);
        setIsLoading(false);
      }

      console.log("Farm Approve", res);
    } catch (error) {
      console.error("Error at handleApproveForFarm", error);
      setIsFarmLoading(false);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFarmDeposit = async (token) => {
    try {
      // fetch tokenId
      setDepositIsLoading(true);
      const tokenId = Number(selectedPosition);
      console.log("tokenId", tokenId);
      const deposit = await stake(walletProvider, tokenId, 0);
      console.log("Farm Deposit", deposit);
      if (
        deposit === "Failed" ||
        deposit === null ||
        deposit === "" ||
        deposit === undefined
      ) {
        setDepositIsLoading(false);
        setIsConfirmModalOpen(false);
        setIsDepositFailed(true);
      }
      if (
        deposit !== "Failed" &&
        deposit !== null &&
        deposit !== "" &&
        deposit !== undefined
      ) {
        setIsConfirmModalOpen(false);
        setIsDepositConfirmed(true);
        setDepositIsLoading(false);
        setTimeout(() => {
          setFarmInputValue("");
          setIsFarm(false);
        }, 15000);
      }
    } catch (error) {
      setDepositIsLoading(false);
      console.error("Error at handleFarmDeposit", error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the clicked target is outside the dropdown and the button
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    if (isDropdownOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    const fetchfarmdata = async () => {
      try {
        const connectedAccount = localStorage.getItem("connectedAccount");
        if (!isAddress(connectedAccount)) {
          return;
        }
        const farmdata = await farmShare(walletProvider);
        setfarmpercentrage(farmdata);
      } catch (error) {
        console.error("Error fetching positionIds:", error);
      }
    };
    fetchfarmdata();
  }, []);
  return (
    <>
      {positionIds ? (
        <div className="flex flex-col justify-center items-center gap-4">
          {/* Dropdown for NFT ID selection */}
          <div
            className={`relative w-full rounded-[16px]  ${
              theme.palette.mode === "light"
                ? "bg-white text-[#69696F]"
                : "bg-[#050840] text-[#97979B]"
            } z-40`}
          >
            <button
              onClick={(event) => handleDropdownToggle(event)}
              className="w-full text-left flex justify-between items-center  p-4 text-base rounded-[16px]"
            >
              {selectedPosition || "Select NFT ID"}{" "}
              {/* Show placeholder text */}
              <span className="transform transition-transform duration-300">
                {isDropdownOpen ? (
                  <FaAngleUp className="h-5 w-5" />
                ) : (
                  <FaAngleDown className="h-5 w-5" />
                )}
              </span>
            </button>

            {isDropdownOpen && connectedAccount && (
              <div
                ref={dropdownRef}
                className={`absolute left-0 top-full w-full rounded-[16px] transition-all duration-500 ease-in-out z-50 max-h-[300px] overflow-y-auto hide-scrollbar font-inter ${
                  theme.palette.mode === "light"
                    ? "bg-white border-2 border-[#959DF7]"
                    : "bg-[#050840] border-2 border-[#5663F2]"
                }`}
                style={{
                  maxHeight: isDropdownOpen ? "250px" : "0px",
                }}
              >
                {isFetchingPositions ? (
                  <div className="flex justify-center items-center h-[250px]">
                    <BiLoaderAlt className="animate-spin text-gray-500 text-xl" />
                  </div>
                ) : positionIds.length > 0 ? (
                  positionIds.map((option) => (
                    <div
                      key={option}
                      onClick={() => handlePositionSelect(option)}
                      className={`py-3 px-4 ${
                        theme.palette.mode === "light"
                          ? "hover:bg-[#D6DAFC] border-b-2 border-[#D6DAFC]"
                          : "hover:bg-[#21246A] border-b-2 border-[#21246A]"
                      } cursor-pointer hover:transition transition-colors duration-300`}
                    >
                      <span
                        className={`text-base font-semibold ${
                          theme.palette.mode === "light"
                            ? "text-[#69696F]"
                            : "text-[#DCDCDE]"
                        }`}
                      >
                        {option}
                      </span>
                      {liquidityData[option] && (
                        <div className="flex justify-between items-center">
                          <div
                            className={`flex items-center justify-between w-full ${
                              theme.palette.mode === "light"
                                ? "text-[#69696F]"
                                : "text-[#97979B]"
                            }`}
                          >
                            <div className="flex flex-shrink-0 items-center rounded-[8px]">
                              <div className="flex justify-center items-center gap-1 mt-1">
                                <div className="flex items-center">
                                  <img
                                    src={
                                      tokenImageMap[
                                        liquidityData[option].token0Name
                                      ]
                                    }
                                    alt=""
                                    className="w-7 h-7 border-2 border-[#21246A] rounded-[24px]"
                                  />
                                  <img
                                    src={
                                      tokenImageMap[
                                        liquidityData[option].token1Name
                                      ]
                                    }
                                    alt=""
                                    className="w-[29px] h-[29px] border-2 border-[#21246A] rounded-[24px] -ml-[10px]"
                                  />
                                </div>
                                <span className="text-base inline-block">
                                  {liquidityData[option].token0Name} /{" "}
                                  {liquidityData[option].token1Name}
                                </span>
                              </div>
                            </div>
                            <div className="text-base ml-6 leading-6 flex items-center">
                              {formatBalanceForPrice(liquidityData[option].token0LiquidityAmount)} :{" "}
                              {formatBalanceForPrice(liquidityData[option].token1LiquidityAmount)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="flex flex-col gap-2 justify-center items-center h-[100px]">
                    <div>No NFT ID found</div>
                    <button
                      className="underline"
                      onClick={() => setMainActiveSection(2)}
                    >
                      Add Liquidity
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          {selectedPosition && selectedPosition !== "Select NFT ID" && (
            <div
              className={`rounded-2.5 mobile:rounded-2.5 flex-row items-center p-4 z-20 w-full ${
                theme.palette.mode === "light"
                  ? "bg-white text-[#69696F]"
                  : "bg-[#02042C] text-[#97979B]"
              }`}
            >
              <div className={`flex justify-between  h-[24px]`}>
                <span
                  className={`text-base ${
                    theme.palette.mode === "light"
                      ? "text-[#181821]"
                      : " text-[#97979B]"
                  }`}
                >
                  LP Tokens
                </span>
              </div>
              <div className={`flex justify-between w-full`}>
                <div
                  className={`flex flex-shrink-0 p-2 pr-4 items-center rounded-[8px] ${
                    theme.palette.mode === "light"
                      ? "bg-[#EEEFFE] text-[#181821]"
                      : "bg-[#0B0E4A] text-[#97979B]"
                  }`}
                >
                  <div className="flex justify-center items-center gap-1">
                    <div className="flex items-center">
                      <img
                        src={token0Image}
                        alt={token0Name}
                        className="w-7 h-7 border-2 border-[#21246A] rounded-[24px]"
                      />
                      <img
                        src={token1Image}
                        alt={token1Name}
                        className="w-[29px] h-[29px] border-2 border-[#21246A] rounded-[24px] -ml-[10px]"
                      />
                    </div>
                    <span className={`text-base font-semibold inline-block`}>
                      {token0Name} / {token1Name}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col items-end justify-center gap-1 text-right w-1/2">
                  <input
                    className={`
            relative bg-[#02042C] text-right ${
              theme.palette.mode === "light"
                ? "text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
                : "text-white"
            } 
            outline-none font-inter text-[31px] w-30 md:w-80per font-bold placeholder-white 
          `}
                    placeholder="0.00"
                    type="text"
                    onChange={(event) => {
                      // Apply regex validation to allow only numbers and a single dot
                      let inputValue = event.target.value.replace(
                        /[^0-9.]/g,
                        ""
                      );

                      // Limit to a single dot
                      const dotCount = (inputValue.match(/\./g) || []).length;
                      if (dotCount > 1) {
                        inputValue = inputValue.slice(0, -1);
                      }

                      // Update state with the sanitized input value
                      setFarmInputValue(inputValue);
                    }}
                    value={farmInputValue}
                    onWheel={(e) => e.preventDefault()} // Prevent scrolling
                    maxLength="20"
                  />
                </div>
              </div>
              {/* <div className={`flex justify-between`}>
                <div
                  className={`text-base leading-6 mt-[2px] ${
                    theme.palette.mode === "light"
                      ? "text-[#69696F]"
                      : "text-[#97979B]"
                  } text-ft5`}
                >
                  
                </div>
                <div
                  className={`text-base leading-6 ${
                    theme.palette.mode === "light"
                      ? "text-[#69696F]"
                      : "text-[#97979B]"
                  } text-ft5`}
                >
                  ≈ --
                </div>
              </div> */}
            </div>
          )}
          {connectedAccount ? (
            <div className="flex gap-5 items-center justify-between text-base font-inter z-0 w-full">
              {!selectedPosition ? (
                // Show only "Select NFT ID" button if no NFT ID is selected
                <button
                  className={`w-full h-[47px] py-3 px-6 z-10 rounded-[12px] text-base font-semibold leading-6 text-white bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300`}
                  disabled={true}
                >
                  Select NFT ID
                </button>
              ) : (
                // Show "Approve" and "Deposit" buttons if NFT ID is selected
                <>
                  <button
                    className={`w-1/2 h-[47px] rounded-[12px] flex items-center justify-center py-3 px-3 ${
                      theme.palette.mode === "light"
                        ? "text-white"
                        : "text-white"
                    } bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300`}
                    disabled={!selectedPosition}
                    onClick={() => handleApproveForFarm()}
                  >
                    <div
                      className={`relative font-paragraph-p1-regular text-ft15 font-semibold flex`}
                    >
                      {isLoading ? (
                        <div>
                          <BiLoaderAlt className="animate-spin text-gray-50" />
                        </div>
                      ) : isFarm ? (
                        <>
                          <span className="flex gap-1 flex-row items-center">
                            <img
                              alt="NewCheck"
                              src={NewCheck}
                              className="w-4 h-4 mt-[2px] rounded-[12px]"
                            />
                            Approved
                          </span>
                        </>
                      ) : (
                        `Approve`
                      )}
                    </div>
                  </button>
                  <button
                    className={`w-1/2 h-[47px] py-3 px-6 z-10 rounded-[12px] text-base font-semibold leading-6 text-white bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300`}
                    disabled={!isFarm}
                    onClick={() => setIsConfirmModalOpen(true)}
                  >
                    <div
                      className={`relative font-paragraph-p1-regular text-ft15 font-semibold flex justify-center`}
                    >
                      {isDepositLoading ? (
                        <div>
                          <BiLoaderAlt className="animate-spin text-gray-50" />
                        </div>
                      ) : (
                        `Deposit`
                      )}
                    </div>
                  </button>
                </>
              )}
            </div>
          ) : (
            <NavLink
              to="/connect-wallet"
              className="w-full h-[47px] min-h-[47px] rounded-[12px] flex items-center justify-center bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300  text-white cursor-pointer z-10"
            >
              Connect Wallet
            </NavLink>
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center gap-4">
          <div
            className={`rounded-2.5 mobile:rounded-2.5 items-center py-12 px-4 z-20 w-full h-[210px] flex flex-col justify-center gap-2  ${
              theme.palette.mode === "light"
                ? "bg-white text-[#69696F]"
                : "bg-[#02042C] text-[#97979B]"
            }`}
          >
            <NoLiquidity className="w-10 h-10" />
            <p
              className={`text-[20px] font-semibold leading-[150%] ${
                theme.palette.mode === "light" ? "text-[#34343C]" : "text-white"
              }`}
            >
              No liquidity share
            </p>
            <p
              className={`text-base leading-[150%] ${
                theme.palette.mode === "light"
                  ? "text-[#69696F]"
                  : "text-[#97979B]"
              }`}
            >
              You haven’t any LP tokens to farm
            </p>
          </div>
          {connectedAccount ? (
            <button
              className={`w-full py-3 px-6 z-10 rounded-[12px] text-base font-semibold leading-6 text-white bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300`}
              onClick={() => setMainActiveSection(2)}
            >
              Add Liquidity
            </button>
          ) : (
            <NavLink
              to="/connect-wallet"
              className="w-full h-[47px] min-h-[47px] rounded-[12px] flex items-center justify-center bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300  text-white cursor-pointer z-10"
            >
              Connect Wallet
            </NavLink>
          )}
        </div>
      )}

      {isConfirmModalOpen && (
        <DepositConfirmationModal
          handleFarmDeposit={handleFarmDeposit}
          onClose={() => setIsConfirmModalOpen(false)}
          isDepositLoading={isDepositLoading}
          position = {selectedPosition}
          amount={farmInputValue}
        />
      )}
      {isDepositConfirmed && (
        <LPFarmSuccessModal onClose={() => setIsDepositConfirmed(false)} type={`success`} message={`Deposit Successful`} amount={farmInputValue}/>
      )}
      {isDepositFailed && (
        <LPFarmSuccessModal onClose={() => setIsDepositFailed(false)} type={`failed`} message={`Deposit Failed`} amount={farmInputValue}/>
      )}
    </>
  );
};

export default DepositLiquidity;
