import { createSlice } from "@reduxjs/toolkit";

const redirectSlice = createSlice({
  name: "locations",
  initialState: {
    lastLocation: "node",
  },
  reducers: {
    saveLocation: (state, action) => {
      state.lastLocation = action.payload;
    },
  },
});

export const { saveLocation } = redirectSlice.actions;
export default redirectSlice.reducer;
