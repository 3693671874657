import { useTheme } from "@mui/material";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NftDetailsShimmer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isLightMode = theme.palette.mode === "light";
  return (
    <div className="max-w-[1280px] mx-auto pt-6 sm:pt-10 pb-16 animate-pulse">
      <div
        className={`flex gap-[10px] text-base leading-6 items-center ${
          isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
        }`}
      >
        <FaArrowLeft />
        <button onClick={() => navigate("/swap")}>Back to NFTs</button>
      </div>

      <div className="flex flex-col lg:flex-row lg:justify-between gap-3 lg:items-center mt-4 sm:mt-8 p-2 lg:p-0">
        <div className="flex gap-3 md:gap-4 md:items-center flex-col-reverse md:flex-row">
          <div className="flex gap-3 md:gap-4 md:items-center flex-col md:flex-row">
            {" "}
            <div
              className={`flex gap-3 md:gap-4 items-center w-[192px] h-6 rounded-[12px] ${
                isLightMode ? " bg-[#e4e5f7]" : " bg-[#121345]"
              }`}
            ></div>
            <div className="flex gap-4 items-center">
              <div
                className={`w-[120px] h-10 rounded-[12px] ${
                  isLightMode ? " bg-[#e4e5f7]" : " bg-[#121345]"
                }`}
              ></div>
              <div
                className={`w-[100px] h-10 rounded-[12px] ${
                  isLightMode ? " bg-[#e4e5f7]" : " bg-[#121345]"
                }`}
              ></div>
            </div>
          </div>

          <div
            className={`w-[100px] h-3 rounded-[12px] ${
              isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
            }`}
          ></div>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-6 mt-6 sm:mt-12 justify-between">
        <div
          className={`p-4 sm:p-6 rounded-[32px]  w-full lg:w-1/2 flex flex-col gap-5 z-10`}
        >
          <div className="flex flex-col gap-3 lg:gap-0 lg:flex-row w-full justify-between items-end lg:items-center">
            <div
              className={`flex gap-6 w-full justify-between lg:justify-normal`}
            >
              <span
                className={`w-[126px] h-3 ${
                  isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
                } rounded-[12px]`}
              ></span>{" "}
              <div className="flex gap-[6px] items-center"></div>
            </div>
            <div
              className={`flex gap-4 p-2 rounded-[24px] w-[191px] h-12 ${
                isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
              }`}
            ></div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4">
            <div
              className={`h-[164px] w-[282px] p-8 flex flex-col gap-2 justify-center items-center rounded-[16px] ${
                isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
              }`}
            ></div>
            <div
              className={`h-[164px] w-[282px] p-8 flex flex-col gap-2 justify-center items-center rounded-[16px] ${
                isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
              }`}
            ></div>
          </div>
          <div
            className={`max-w-[580px] h-[164px] p-8 flex flex-col justify-center items-center gap-2 rounded-[16px] ${
              isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
            }`}
          ></div>
        </div>
        <div
          className={`w-full lg:w-1/2
       flex flex-col gap-6`}
        >
          <div
            className={`p-4 md:p-6 w-full flex flex-col gap-4 rounded-[32px] z-10`}
          >
            <div className={`flex flex-col gap-2`}>
              <span
                className={`w-[126px] h-3 ${
                  isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
                } rounded-[12px]`}
              ></span>

              <span
                className={`w-[200px] h-3 ${
                  isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
                } rounded-[12px]`}
              ></span>
            </div>
            <div
              className={`w-full h-[112px]
          gap-3 p-6 rounded-[16px] ${
            isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
          }`}
            >
              <div className="w-full flex justify-between">
                <div
                  className={`text-base leading-6 ${
                    isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                  }`}
                ></div>
              </div>
              <div className="w-full flex justify-between">
                <div className="flex gap-2 items-center"></div>
                <div
                  className={`text-base leading-6 ${
                    isLightMode ? "text-[#181821]" : "text-[#DCDCDE]"
                  }`}
                ></div>
              </div>
            </div>
          </div>
          <div
            className={`p-4 md:p-6 w-full flex flex-col gap-4 rounded-[32px]`}
          >
            <div className="flex justify-between items-center">
              <div className={`flex flex-col gap-2`}>
                <span
                  className={`w-[126px] h-3 ${
                    isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
                  } rounded-[12px]`}
                ></span>

                <span
                  className={`w-[200px] h-3 ${
                    isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
                  } rounded-[12px]`}
                ></span>
              </div>
              <button
                className={`w-[120px] h-10 rounded-[12px] ${
                  isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
                }`}
              ></button>
            </div>

            <div className="w-full flex justify-between">
              <div
                className={`w-full h-[112px]
          gap-3 p-6 rounded-[16px] ${
            isLightMode ? "bg-[#e4e5f7]" : "bg-[#121345]"
          }`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftDetailsShimmer;
