import React from "react";
import TransactionFailedIcon from "../assets/transaction-failed-icon.svg";
import { IoClose } from "react-icons/io5";
import { useTheme } from "@mui/material";

const FailedTransaction = ({ onClose, error }) => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 mobile:rounded-xl rounded-3xl">
      <div
        className={`h-[220px] sm:h-[270px] min-w-[250px] w-90per max-w-[400px] p-[10px] sm:p-[24px] box-border rounded-[24px] flex flex-col justify-center items-center gap-[4px] relative ${
          isLightMode ? "bg-white border-[#D6DAFC]" : "bg-[#02042C] border-[#21246A]"
        }`}
      >
        <IoClose
          className={`absolute top-[15px] sm:top-[24px] right-[15px] sm:right-[24px] h-[24px] w-[24px] cursor-pointer hover:opacity-90 ${
            isLightMode ? "text-[#181821]" : "text-white"
          }`}
          onClick={onClose}
        />
        <img
          src={TransactionFailedIcon}
          alt="Transaction-Failed Icon"
          className="h-[64px] w-[64px]"
        />
        <h3
          className={`text-[20px] ${
            isLightMode ? "text-[#181821]" : "text-white"
          }`}
        >
          Transaction Failed
        </h3>
        <p
          className={`text-[16px] ${
            isLightMode ? "text-[#69696F]" : "text-[#97979B]"
          }`}
        >
          Process forbidden in your wallet
        </p>
      </div>
    </div>
  );
};

export default FailedTransaction;
