import {
  BrowserProvider,
  formatUnits,
  Contract,
  JsonRpcProvider,
} from "ethers";
import {
  NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
  NONFUNGIBLE_POSITION_MANAGER_ABI,
} from "../web3Utils";
import { erc20Abi } from "../uniswap/utils";
const farmAbi = [
  "function setup() external",
  "function stake(uint256 tokenId, uint256 _pid) external",
  "function unstake(uint256 tokenId, uint256 _pid) external",
  "function getLPTokens(uint256 tokenId, address stakingPoolAddress) external view returns(uint256)",
  "function getUserStakes(address user) external view returns (uint256[] memory)",
  "function lp() external view returns(address)",
  "function stakeDetails(uint256 tokenId) external view returns(address userAddr, uint256 tokenId, uint256 mintedLP, uint256 blockNumber, bool isMinted)",
  "function farmV2() external view returns(address)"
];


const farmv2Abi = [
  // "function erc20() external",
  // "function paidOut() external",
  "function rewardPerBlock() external",
  "function poolInfo(uint256) external",
  "function userInfo(uint256, address) external",
  "function totalAllocPoint() external",
  // "function startBlock() external",
  // "function endBlock() external",
  // "function pause() external",
  "function unpause() external",
  "function poolLength() external",
  "function set(uint256 _pid, uint256 _allocPoint, bool _withUpdate) external",
  "function deposited(uint256 _pid, address _user) external view returns(uint256)",
  "function pending(uint256 _pid, address _user) external view returns(uint256)",
  "function totalPending() external",
  "function pendingRewards(uint256 _pid, address _user) external view returns(uint256 currentBlock, uint256 reward)"
];

let providers = [];
let isSubscribed = false;
let pendingResolves = [];

export function subscribeToProviders() {
  return new Promise((resolve) => {
    function onAnnouncement(event) {
      if (providers.map((p) => p.info.uuid).includes(event.detail.info.uuid))
        return;
      providers = [...providers, event.detail];
      console.log("subscribeToProviders", providers);

      // Resolve all pending promises
      pendingResolves.forEach((res) => res(providers));
      pendingResolves = [];
    }

    if (!isSubscribed) {
      window.addEventListener("eip6963:announceProvider", onAnnouncement);
      window.dispatchEvent(new Event("eip6963:requestProvider"));
      isSubscribed = true;
    }

    if (providers.length > 0) {
      resolve(providers);
    } else {
      pendingResolves.push(resolve);
    }
  });
}

export function getProviders() {
  return providers;
}

export async function connectWallet() {
  try {
    await subscribeToProviders();
    const providers = getProviders(); // Retrieve the providers from the global store
    const wallet = localStorage.getItem("walletName");

    if (!wallet) {
      console.warn("No wallet found in localStorage");
      return; // Early return without throwing an error
    }

    // Find the provider with the specified wallet name
    const providerWithInfo = providers.find(
      (provider) => provider.info?.name === wallet
    );

    if (!providerWithInfo || !providerWithInfo.provider) {
      // Handle case when provider is not found
      // localStorage.removeItem("walletName"); // Clear the stored wallet if it's invalid
      return; // Return without throwing an error
    }

    const provider = new BrowserProvider(providerWithInfo.provider);
    const signer = await provider.getSigner();
    const address = await signer.getAddress();

    return {
      signer,
      address,
    };
  } catch (error) {
    console.error("Error connecting wallet:", error.message || error);
    throw error; // Re-throw the error to handle it where connectWallet is called
  }
}


export async function getApprove(walletProvider, tokenId) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");

      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const nonfungiblePositionManagerContract = new Contract(
      NONFUNGIBLE_POSITION_MANAGER_CONTRACT_ADDRESS,
      NONFUNGIBLE_POSITION_MANAGER_ABI,
      signer
    );

    const gasLimit =
      await nonfungiblePositionManagerContract.approve.estimateGas(
        process.env.REACT_APP_FARM_CONTRACT_ADDRESS,
        tokenId
      );

    const feeData = await provider?.getFeeData();
    const gasPrice = parseInt(feeData.gasPrice) * 2;

    const approveTx = await nonfungiblePositionManagerContract.approve(
      process.env.REACT_APP_FARM_CONTRACT_ADDRESS,
      tokenId,
      {
        gasPrice: gasPrice.toString(), // Adjust multiplier as needed
        gasLimit: gasLimit.toString(), // Adjust multiplier as needed
      }
    );

    const receipt = await approveTx.wait();
    return receipt.status !== null ? approveTx.hash : null;
  } catch (error) {
    console.error(error);
  }
}

export async function stake(walletProvider, tokenId, _pid) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");

      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const farmContract = new Contract(
      process.env.REACT_APP_FARM_CONTRACT_ADDRESS,
      farmAbi,
      signer
    );

    const gasLimit = await farmContract.stake.estimateGas(tokenId, _pid);

    const feeData = await provider?.getFeeData();
    const gasPrice = parseInt(feeData.gasPrice) * 2;

    const stakeTx = await farmContract.stake(tokenId, _pid, {
      gasPrice: gasPrice.toString(), // Adjust multiplier as needed
      gasLimit: gasLimit.toString(), // Adjust multiplier as needed
    });

    const receipt = await stakeTx.wait();

    if (receipt.status === 1) {
      console.log("Staked", stakeTx);
      return stakeTx.hash;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function unStake(walletProvider, tokenId, _pid) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");

      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const farmContract = new Contract(
      process.env.REACT_APP_FARM_CONTRACT_ADDRESS,
      farmAbi,
      signer
    );

    const gasLimit = await farmContract.unstake.estimateGas(tokenId, _pid);

    const feeData = await provider?.getFeeData();
    const gasPrice = parseInt(feeData.gasPrice) * 2;

    const unStakeTx = await farmContract.unstake(tokenId, _pid, {
      gasPrice: gasPrice.toString(), // Adjust multiplier as needed
      gasLimit: gasLimit.toString(), // Adjust multiplier as needed
    });

    const receipt = await unStakeTx.wait();

    if (receipt.status === 1) {
      console.log("unStakeTx", unStakeTx);
      return unStakeTx.hash;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getFarmRewards() {
  try {
    const { signer, address } = await connectWallet();

    if (!signer || !address) {
      console.log("No Provider Found");
      return "Failed At Unstake";
    }

    const farmContractV2 = new Contract(
      process.env.REACT_APP_FARMV2_CONTRACT_ADDRESS,
      farmv2Abi,
      signer
    );
    let totalRewardTokens = 0;
    const pendingRewards = await farmContractV2.pendingRewards(0,address);
    const rewards = Number(formatUnits(pendingRewards[1].toString(),"ether"));
    totalRewardTokens += parseFloat(rewards);

    return totalRewardTokens.toFixed(4);
  } catch (error) {
    console.error(error.message);
  }
}

export async function getEstimatedLPTokens(tokenId, stakingPoolAddress) {
  try {
    const provider = new JsonRpcProvider("https://polygon-rpc.com");
    console.log("tokenId", tokenId, "stakingPoolAddress", stakingPoolAddress);

    const farmContract = new Contract(
      process.env.REACT_APP_FARM_CONTRACT_ADDRESS,
      farmAbi,
      provider
    );

    const response = await farmContract.getLPTokens(
      tokenId,
      stakingPoolAddress
    );

    const format = formatUnits(response.toString(), "ether");

    console.log("getEstimatedLPTokens", format);

    return format;
  } catch (error) {
    console.error(error);
  }
}

export async function getUserStakedTokenIds(walletProvider) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");

      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const farmContract = new Contract(
      process.env.REACT_APP_FARM_CONTRACT_ADDRESS,
      farmAbi,
      provider
    );

    const response = await farmContract.getUserStakes(signer?.getAddress());

    const stakeValues = response.map((stake) => stake.toString());

    console.log("getUserStakedTokenIds", stakeValues);
    return stakeValues;
  } catch (error) {
    console.error("getUserStakedTokenIds", error);
  }
}


//farmin share
export async function farmDetails(walletProvider) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");

      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const address = signer?.getAddress();

    const farmContract = new Contract(
      process.env.REACT_APP_FARM_CONTRACT_ADDRESS,
      farmAbi,
      provider
    );

    const farmV2Address = await farmContract.farmV2();

    const farmv2Contract = new Contract(
      farmV2Address,
      farmv2Abi,
      provider
    );
    const deposit = await farmv2Contract.deposited(0, address);
    const farmedXBR = await farmv2Contract.pending(0, address);

    const depositedTokens = formatUnits(deposit.toString(), "ether");
    const farmedXBRTokens = formatUnits(farmedXBR.toString(), "ether");
    const userShare = await farmShare(address);
    console.log("farmShare", userShare);

    return { depositedTokens, farmedXBRTokens, userShare };
  } catch (error) {
    console.error("farmDetails", error);
  }
}

export async function farmShare(walletProvider) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers = await getProviders();
      const wallet = localStorage.getItem("walletName");

      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    const farmContract = new Contract(
      process.env.REACT_APP_FARM_CONTRACT_ADDRESS,
      farmAbi,
      provider
    );

    const user = signer?.getAddress();

    const lpAddress = await farmContract.lp();
    const lpContract = new Contract(lpAddress, erc20Abi, provider);
    const totalSupply = Number(formatUnits(await lpContract.totalSupply(), "ether"));
    let userStakedTokens = await farmContract.getUserStakes(user);
    userStakedTokens = userStakedTokens.map((stake) => stake.toString());

    console.log('userStakedTokens', userStakedTokens);

    let totalFarmedLP = 0;  
    if (userStakedTokens.length > 0) {
      const stakesPromises = userStakedTokens.map(async (tokenId) => {
        const stakes = await farmContract.stakeDetails(tokenId);
        totalFarmedLP += Number(formatUnits(await stakes.mintedLP.toString(), "ether"));
      });

      await Promise.all(stakesPromises);
      const percentageFarmedLP = (totalFarmedLP / totalSupply) * 100;

      console.log('totalFarmedLP', totalFarmedLP);
      console.log('percentageFarmedLP', percentageFarmedLP);

      return percentageFarmedLP;
    } else {
      console.log('No staked tokens found for user');
      return [];
    }
  } catch (error) {
    console.error("farmShare", error);
  }
}

