import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import ReactPlayer from "react-player";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Toaster, toast } from "react-hot-toast";
import {
  approveUSDTForXBRNODE,
  buyNode,
  registerNode,
} from "../../uniswap/utils";
import { BiLoaderAlt } from "react-icons/bi";
import { NewCheck, node } from "../../assets";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { useSyncProviders } from "../../hooks/useSyncProvider";
import {
  storeUSDTTransactionDetails,
  proceedMintAndClaim,
  USDT_TOKEN,
  ERC20_ABI
} from "../../web3Utils";
import { isAddress, BrowserProvider, Contract, formatUnits } from "ethers";
import { subscribeToProviders, getProviders } from './../../uniswap/farm';
const ConfirmNodeComponent = (props) => {
  const providers = useSyncProviders();
  console.log("allProviders", providers);
  // const { address: connectedAddress } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { address: recipient } = useWeb3ModalAccount();
  console.log("walletProvider", walletProvider);
  // console.log("walletProviderType", walletProviderType);
  const { account } = useSelector((state) => state.wallet);
  console.log("connectedAccount", account);
  const navigate = useNavigate();
  const location = useLocation();
  const { quantity, address } = location.state || {};
  const theme = useTheme();
  const alt = theme.palette.background.alt;
  const [isLoading, setIsLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const [blockchainResult, setBlockchainResult] = useState({});
  const [transactions, setTransactions] = useState({});
  const validQuantity =
    isNaN(quantity) || quantity === undefined ? 0 : quantity;
    const [isProceeding, setIsProceeding] = useState(false);
    const [usdtBalance, setUsdtBalance] = useState("0.00");

const handleProceed = async () => {
    setIsProceeding(true);
    try {
      console.log("referralAddress", address);
      const result = await buyNode(validQuantity, address, walletProvider);
      console.log("result", result);
      if (result) {
        //  await setBlockchainResult(result);
        await handleProceedButton(result);
        const tokenIds = await result.logs
          .map((log) => parseInt(log.topics[3], 16))
          .filter((id) => !isNaN(id) && id < Number.MAX_SAFE_INTEGER);
        await registerNode(tokenIds, account);
         setIsProceeding(false);
        navigate("/node"); 
      }
       setIsProceeding(false);
    toast.error("Error please try again:", { duration: 1000 });

    } catch (error) {
       setIsProceeding(false);
             console.error("Error in handleProceed:", error);
        toast.error(error.toString(), { duration: 2000 });

    }
  };

  
  const fetchbalance = async () => {
    try {
      if (!window.ethereum) {
        throw new Error("Please install MetaMask");
      }

      let signer;
      let provider;
      if (walletProvider) {
        console.log("Connected to Wallet Connect", "Provider", walletProvider);
        provider = new BrowserProvider(walletProvider);
        signer = await provider?.getSigner();
      } else {
        console.log("connecting without walletConnect!");
        await subscribeToProviders();
        const providers = await getProviders();
        const wallet = localStorage.getItem("walletName");

        const providerWithInfo = await providers.find(
          (provider) => provider.info.name === wallet
        );
        console.log("removeLiquidity-connectWallet", providerWithInfo);
        provider = new BrowserProvider(providerWithInfo?.provider);
        signer = await provider?.getSigner();
      }
      const address = await signer.getAddress();

      const usdtContract = new Contract(USDT_TOKEN.address, ERC20_ABI, signer);
      const usdtBalance = formatUnits(await usdtContract.balanceOf(address), 6);

      setUsdtBalance(usdtBalance);
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  }

  useEffect(()=>{
    fetchbalance();
  },[walletProvider])

  const handleApprove = async () => {
    try {
      const totalCost = validQuantity * Number(process.env.REACT_APP_NODE_PRICE); 
      if (Number(usdtBalance) < totalCost) {
        toast.error("Insufficient balance", { duration: 2000 });
        setIsLoading(false); 
        return;
      }
      const amount = validQuantity * Number(process.env.REACT_APP_NODE_PRICE);
      console.log("amount", amount); 
      if (!approve && !isLoading) {
        setIsLoading(true);
        const approval = await approveUSDTForXBRNODE(amount, walletProvider);
        console.log("approval", approval);
        setIsLoading(false);
        if (approval) {
          const transactionDetails = {
            fromAddress: account,
            toAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
            txHash: approval,
            nodesQuantity: validQuantity,
            totalAmount: amount,
            referralWalletAddres: address,
            amountToOwner: amount * 0.8,
            amountToReferralWallet: amount * 0.2
          };
          const result = await storeUSDTTransactionDetails(transactionDetails);
          if (result) {
            setTransactions(transactionDetails);
            setApprove(true);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
            toast.error("Error please try again:", { duration: 1000 });
      console.error("Error handleApprove:", error);
    }
  };
  const handleProceedButton = async (result) => {
    try {
      const transactionHash = transactions.txHash;
      console.log("transactionHash:", transactionHash);
      const tokenIds = result.logs
        .map((log) => parseInt(log.topics[3], 16))
        .filter((id) => !isNaN(id) && id < Number.MAX_SAFE_INTEGER);
      console.log("tokenIds:", tokenIds);
      await proceedMintAndClaim(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        account,
        validQuantity,
        transactionHash,
        tokenIds
      );
    } catch (error) {
      setIsLoading(false);
          toast.error("Error please try again:", { duration: 2000 });
      console.error("Error in handleProceed:", error);
    }
  };

  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.substring(0, 6)}...${address.substring(
      address.length - 4
    )}`;
  };

  return (
    <div style={{ background: alt }} className="min-h-screen h-full">
      <div className="flex-col flex items-center gap-4 font-inter py-13">
        {account ? (
          <div
            className={` font-normal font-inter text-ft5 ${
              theme.palette.mode === "light"
                ? " text-black "
                : "text-[#C5C5D1] "
            } `}
          >
            Connected to {shortenAddress(account)}
          </div>
        ) : (
          <div
            className={` font-normal font-inter text-ft5 ${
              theme.palette.mode === "light"
                ? " text-black "
                : "text-[#C5C5D1] "
            } `}
          >
            Not Connected
          </div>
        )}

        <div
          className={`p-6 ${
            theme.palette.mode === "light"
              ? "bg-white border border-[#D6D7E3]"
              : "bg-[#070A47]"
          } rounded-3xl max-w-[438px] w-full `}
        >
          <div
            className={`rounded-xl h-[298px] flex justify-center items-center ${
              theme.palette.mode === "light" ? "bg-[#E6E7F0]" : "bg-[#070629]"
            }`}
          >
            {theme.palette.mode === "light" ? (
              <img src={node} alt="Node Image" />
            ) : (
              <ReactPlayer
                url={`${process.env.PUBLIC_URL}/node-animated-video.mp4`}
                playing
                loop
                muted
                width="234px"
                height="244px"
                controls={false}
                playsinline
                style={{ pointerEvents: "none" }}
              />
            )}
          </div>

          <div className="font-inter flex justify-between mt-6 pb-4 border-b-1 border-b-slate-100 font-bold text-ft22">
            <span className="flex-1">BUY FOUNDER'S NODES</span>
            <span>x{validQuantity}</span>
          </div>

          <div
            className={`flex justify-between mt-4 ${
              theme.palette.mode === "light"
                ? "text-[#3E4157]"
                : "text-[#ACADBB]"
            } font-normal font-inter text-ft5`}
          >
            <div>Total price</div>
            <div className="font-semibold">
              {validQuantity * Number(process.env.REACT_APP_NODE_PRICE)} USDT
            </div>
          </div>
          <div className="flex gap-2 mt-4 items-center">
            <button
              className={`flex w-[187px] py-3 rounded-2.5  flex-row ${
                theme.palette.mode === "light"
                  ? "text-mediumslateblue-300"
                  : "text-mediumslateblue-500"
              } items-center justify-center border-[2px] border-solid border-mediumslateblue-300 ${
                approve
                  ? "border-transparent text-green-500 cursor-default"
                  : `border-mediumslateblue-300 ${
                      !isLoading
                        ? "hover:bg-[#5763F3] hover:rounded-lg hover:text-white cursor-pointer"
                        : ""
                    }`
              }`}
              onClick={handleApprove}
              disabled={approve || isLoading}
            >
              <div className="relative font-paragraph-p1-regular text-ft15 font-semibold flex">
                {approve ? (
                  <span className="flex gap-1 flex-row items-center">
                    <img
                      alt="NewCheck"
                      src={NewCheck}
                      className="w-4 h-4 mr-2 fill-current mt-[2px] rounded-lg"
                    />
                    Approved
                  </span>
                ) : isLoading ? (
                  <div className="flex justify-center">
                    <BiLoaderAlt
                      className={`animate-spin w-5 h-5 ${
                        theme.palette.mode === "light"
                          ? "text-[#5663F2]"
                          : "text-white"
                      }`}
                    />
                  </div>
                ) : (
                  "Approve"
                )}
              </div>
            </button>
            <button
              onClick={handleProceed}
              disabled={!approve || isLoading}
              className={`w-[187px] rounded-xl text-ft5 font-semibold whitespace-normal ${
                theme.palette.mode === "light"
                  ? "text-[#5663F2]"
                  : "text-[#B4B8F9]"
              } border-2 border-[#5663F2] py-3 ${
                !approve || isLoading
                  ? "opacity-50 cursor-default"
                  : "hover:bg-[#5663F2] hover:text-white"
              } flex items-center justify-center`}
            >
              Proceed
              {/* <div className="relative font-paragraph-p1-regular text-ft15 font-semibold flex">
                {isProceeding ? (
                  <BiLoaderAlt
                    className={`animate-spin w-5 h-5 ${
                      theme.palette.mode === "light"
                        ? "text-[#5663F2]"
                        : "text-white"
                    }`}
                  />
                ) : (
                  "Proceed"
                )}
              </div> */}
            </button>
          </div>
        </div>

        <button
          className="text-ft5 font-semibold font-inter text-[#5B5C71] mt-4 px-4 py-2 rounded-xl hover:border hover:border-[#5B5C71] "
          onClick={() => navigate("/buy-node")}
        >
          Back
        </button>
      </div>
      {isProceeding && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className={`p-6 rounded-lg text-center`}
            style={{ background: alt }}
          >
            <BiLoaderAlt className="animate-spin w-10 h-10 mx-auto mb-4 text-gray-800" />
            <p className="text-lg font-semibold mb-2">
              Processing... Please wait
            </p>
            <p className="text-sm">Do not refresh or close the page</p>
          </div>
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default ConfirmNodeComponent;
