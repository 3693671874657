import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-hot-toast";
import { parseUnits, formatUnits, Contract, BrowserProvider } from "ethers";
import { ERC20_ABI } from "../web3Utils";
import { useTheme } from "@mui/material";
import { BiLoaderAlt } from "react-icons/bi";
import {
  getEstimatedValueForLiquidity,
  getPositionIds,
  modifyPositionnew,
  getEstimatedValueForLiquiditynew,
} from "../uniswap/position";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { getTokenTransferApproval } from "../uniswap/position";
import { XBRimg, USDTimg, NewCheck, tba } from "../assets/index.js";
import { USDT_TOKEN, XBR_TOKEN, TBA_TOKEN } from "../web3Utils.js";
import { ReactComponent as AddNewImg } from "../assets/addNew.svg";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { subscribeToProviders, getProviders } from "../uniswap/farm.js";
import { fetchTokenAddress } from "../uniswap/utils";
import axios from "axios";
import { findPoolAddress } from "../uniswap/pool.js";
import { getQuotes } from "../uniswap/utils";
import { mintPositionTBA, mintPosition } from "../uniswap/position";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { getLiquidity } from "../uniswap/liquidity.js";
import LiquidityConfirmationModal from "./LiquidityConfirmationModal.jsx";
import LiquidityConfirmedModal from "./LiquidityConfirmedModal.jsx";
import { collectfees } from "../uniswap/position";
import tabBgVideo from "../assets/SwapbgVideo.mp4";
import { MdClose } from "react-icons/md";
import FailedTransaction from "./FailedTransaction.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshNftId } from "../redux/fetchData/refreshNFTId.js";

const IncreaseLiquidity = (props) => {
  const theme = useTheme();
  const { walletProvider } = useWeb3ModalProvider();
  const connectedAccount = localStorage.getItem("connectedAccount");
  const { setMainActiveSection, nftId, onClose, setIsLiquidityChanged } = props;

  const [sendValue, setSendValue] = useState("");
  const [receiveValue, setReceiveValue] = useState("");
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [xbrBalance, setXbrBalance] = useState(0);
  const [tbaBalance, setTbaBalance] = useState(0);
  const [isToken1Approved, setIsToken1Approved] = useState(false);
  const [isToken2Approved, setIsToken2Approved] = useState(false);
  const [isUSDTLoader, setIsUSDTLoader] = useState(false);
  const [isXBRLoader, setIsXBRLoader] = useState(false);
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);
  const [isInsufficientBalance1, setIsInsufficientBalance1] = useState(false);
  const [isInsufficientBalance2, setIsInsufficientBalance2] = useState(false);
  const [selectedPriceXBR, setSelectedPriceXBR] = useState("");
  const [selectedPriceTBA, setSelectedPriceTBA] = useState("");
  const [usdtPrice, setUsdtPrice] = useState("");
  const [positionIds, setPositionIds] = useState([]);
  const [liquidityData, setLiquidityData] = useState({});
  const tokens = [
    { id: "XBR", name: "XBR", image: XBRimg, decimals: 18 },
    { id: "USDT", name: "USDT", image: USDTimg, decimals: 6 },
    { id: "TBA", name: "TBA", image: tba, decimals: 18 },
  ];

  const [selectedToken1, setSelectedToken1] = useState(tokens[1]);
  const [selectedToken2, setSelectedToken2] = useState(tokens[0]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(nftId);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);
  const [positionSelectedOption, setPositionSelectedOption] = useState(null);
  const [token0LiquidityAmount, setToken0LiquidityAmount] = useState("0.00");
  const [token1LiquidityAmount, setToken1LiquidityAmount] = useState("0.00");
  const [token0Name, setToken0Name] = useState("USDT");
  const [token1Name, setToken1Name] = useState("XBR");
  const [outputpool, setOutputpool] = useState();
  const [token0Image, setToken0Image] = useState(USDTimg);
  const [token1Image, setToken1Image] = useState(XBRimg);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isIncreaseConfirmed, setIsIncreaseConfirmed] = useState(false);
  const [isFetchingPositions, setIsFetchingPositions] = useState(false);
  const [isTransactionFailed, setIsTransactionFailed] = useState(false);
  const [error, setError] = useState(false);
  const [hashdata, setHashdata] = useState("");
  const [activeSection, setActiveSection] = useState(
    parseInt(localStorage.getItem("activeTab")) || 1
  );
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({
    sendValue: 0,
    receiveValue: 0,
    token1Name: "",
    token2Name: "",
    hashdata: "",
  });
  const refreshNFTId = useSelector((state) => state.refreshNftId.refreshNftId);

  const tokenImageMap = {
    XBR: XBRimg,
    USDT: USDTimg,
    TBA: tba,
  };

  const cardStyle =
    theme.palette.mode === "light"
      ? {
          backgroundColor: "white",
          color: theme.palette.text.primary,
        }
      : {
          backgroundColor: "#02042C",
          color: theme.palette.text.primary,
        };

  const fetchBalances = async () => {
    try {
      let signer;
      let provider;
      if (walletProvider) {
        provider = new BrowserProvider(walletProvider);
        signer = await provider?.getSigner();
      } else {
        await subscribeToProviders();
        const providers = await getProviders();
        const wallet = localStorage.getItem("walletName");

        const providerWithInfo = await providers.find(
          (provider) => provider.info.name === wallet
        );
        provider = new BrowserProvider(providerWithInfo?.provider);
        signer = await provider?.getSigner();
      }

      const address = await signer.getAddress();

      const usdcContract = new Contract(USDT_TOKEN.address, ERC20_ABI, signer);
      const xbrContract = new Contract(XBR_TOKEN.address, ERC20_ABI, signer);
      const tbaContract = new Contract(TBA_TOKEN.address, ERC20_ABI, signer);

      const usdcBalance = formatUnits(await usdcContract.balanceOf(address), 6);
      const xbrBalance = formatUnits(await xbrContract.balanceOf(address), 18);
      const tbaBalance = formatUnits(await tbaContract.balanceOf(address), 18);

      setUsdtBalance(usdcBalance);
      setXbrBalance(xbrBalance);
      setTbaBalance(tbaBalance);
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  useEffect(() => {
    fetchBalances();
  }, []);
  useEffect(() => {
    if (isIncreaseConfirmed) {
      setModalData({
        sendValue,
        receiveValue,
        token1Name: selectedToken1.name,
        token2Name: selectedToken2.name,
        hashdata,
      });

      fetchBalances();
    }
  }, [isIncreaseConfirmed]);
  const formatBalance = (balance) => {
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return num.toFixed(4).replace(/\.00$/, "");
    }
  };

  const fetchMarketData = async () => {
    try {
      const XBR = await fetchTokenAddress("XBR", walletProvider);
      const USDT = await fetchTokenAddress("USDT", walletProvider);
      const TBA = await fetchTokenAddress("TBA", walletProvider);

      const poolAddressXBR = await findPoolAddress(
        USDT.address,
        XBR.address,
        walletProvider
      );

      const poolAddressTBA = await findPoolAddress(
        USDT.address,
        TBA.address,
        walletProvider
      );

      const URL = process.env.REACT_APP_API;
      const responsesXBR = await axios.get(`${URL}/v1/xbr/coin-price?`, {
        params: {
          address: poolAddressXBR.poolAddress,
          count: 157860000,
        },
      });
      const coinMarketCapDataXBR = await responsesXBR.data.response
        .coinMarketCapResponseData.data;
      const priceXBR = await coinMarketCapDataXBR?.priceUsd;
      setSelectedPriceXBR(priceXBR);
      const responsesTBA = await axios.get(`${URL}/v1/xbr/coin-price?`, {
        params: {
          address: poolAddressTBA.poolAddress,
          count: 157860000,
        },
      });
      const coinMarketCapDataTBA = await responsesTBA.data.response
        .coinMarketCapResponseData.data;
      const priceTBA = await coinMarketCapDataTBA?.priceUsd;
      setSelectedPriceTBA(priceTBA);
    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  };

  const pricemap = {
    XBR: selectedPriceXBR,
    TBA: selectedPriceTBA,
    USDT: usdtPrice,
  };

  const fetchUsdtPrice = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/v1/xbr/usdt-price`
      );
      return response.data?.usd;
    } catch (error) {
      console.error("Error in fetching USDT Price", error);
    }
  };

  const fetchUSDTPrice = async () => {
    const price = await fetchUsdtPrice();
    console.log("PRICE", await price);
    setUsdtPrice(await price);
  };

  useEffect(() => {
    fetchMarketData();
    fetchUSDTPrice();
  }, [selectedToken1, selectedToken2]);

  const handleApprove = async (type) => {
    try {
      let tokenAddress, tokenAmount;

      if (type === selectedToken1.name) {
        tokenAmount = sendValue;

        setIsUSDTLoader(true);

        tokenAddress =
          selectedToken1.id === "TBA"
            ? TBA_TOKEN.address
            : selectedToken1.id === "XBR"
            ? XBR_TOKEN.address
            : USDT_TOKEN.address;
      } else if (type === selectedToken2.name) {
        tokenAmount = receiveValue;

        setIsXBRLoader(true);

        tokenAddress =
          selectedToken2.id === "TBA"
            ? TBA_TOKEN.address
            : selectedToken2.id === "XBR"
            ? XBR_TOKEN.address
            : USDT_TOKEN.address;
      }

      if (
        Number(tokenAmount) === 0 ||
        isNaN(Number(tokenAmount)) ||
        Number(tokenAmount) === ""
      ) {
        toast.error("Please enter a valid token amount");
        return;
      }
      const weiAmount = parseUnits(
        tokenAmount.toString(),
        tokens?.find((token) => token.id === type)?.decimals
      );
      const approval = await getTokenTransferApproval(
        { address: tokenAddress },
        weiAmount,
        walletProvider
      );

      if (
        approval !== null &&
        approval !== "" &&
        approval !== undefined &&
        approval !== "Failed"
      ) {
        if (type === selectedToken1.name) {
          setIsToken1Approved(true);
        } else {
          setIsToken2Approved(true);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      if (type === selectedToken1.name) {
        setIsUSDTLoader(false);
      } else if (type === selectedToken2.name) {
        setIsXBRLoader(false);
      }
    }
  };

  const formatBalanceForPrice = (balance) => {
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return num.toFixed(6).replace(/\.?0+$/, "");
    }
  };

  const getFormattedBalance = (name) => {
    const balanceMap = {
      XBR: `${formatBalanceForPrice(xbrBalance)}`,
      USDT: `${formatBalanceForPrice(usdtBalance)}`,
      TBA: `${formatBalanceForPrice(tbaBalance)}`,
    };
    return balanceMap[name] || "0.00";
  };

  const newMax = (type, inputType) => {
    try {
      const balanceMap = {
        XBR: xbrBalance,
        USDT: usdtBalance,
        TBA: tbaBalance,
      };

      const balance = balanceMap[type] || "0.00";

      newOnChangeSwapInputs(
        balance,
        type,
        inputType,
        type === selectedToken1.name ? selectedToken2.name : selectedToken1.name
      );
    } catch (error) {
      console.error("Error getting max balances", error);
    }
  };

  // const newOnChangeSwapInputs = async (value, type, data, type2) => {
  //   if (!value || value === undefined || value === null || value === "") {
  //     setSendValue("");
  //     setReceiveValue("");
  //     setIsInsufficientBalance1(false);
  //     setIsInsufficientBalance2(false);
  //     return;
  //   }

  //   const regex = /^(?!-)[0-9]*[.]?[0-9]*$/;
  //   if (!regex.test(value)) {
  //     return; // Prevent invalid input
  //   }

  //   try {
  //     const balanceMap = {
  //       XBR: parseFloat(xbrBalance),
  //       USDT: parseFloat(usdtBalance),
  //       TBA: parseFloat(tbaBalance),
  //     };

  //     const balance = balanceMap[type] || 0;
  //     const parsedValue = parseFloat(value) || 0;

  //     if (data === 1) {
  //       setSendValue(value);
  //       const result =
  //         parsedValue > 0
  //           ? await getQuotes(type, type2, value, walletProvider)
  //           : "";
  //       setReceiveValue(result);
  //       setIsInsufficientBalance1(parsedValue > balance);
  //       setIsInsufficientBalance2(false);
  //     } else {
  //       setReceiveValue(value);
  //       const result =
  //         parsedValue > 0
  //           ? await getQuotes(type, type2, value, walletProvider)
  //           : "";
  //       setSendValue(result);
  //       setIsInsufficientBalance2(parsedValue > balance);
  //       setIsInsufficientBalance1(false); // Reset the first box's error
  //     }
  //     checkInsufficientBalance();
  //     setIsToken1Approved(false);
  //     setIsToken2Approved(false);
  //   } catch (error) {
  //     console.error("Error in swap input:", error);
  //   }
  // };

  const newOnChangeSwapInputs = async (value, type, data, type2) => {
    if (!value || value === undefined || value === null || value === "") {
      setSendValue("");
      setReceiveValue("");
      setIsInsufficientBalance1(false);
      setIsInsufficientBalance2(false);
      return;
    }

    const regex = /^(?!-)[0-9]*[.]?[0-9]*$/;
    if (!regex.test(value)) {
      return; // Prevent invalid input
    }

    try {
      const balanceMap = {
        XBR: parseFloat(xbrBalance),
        USDT: parseFloat(usdtBalance),
        TBA: parseFloat(tbaBalance),
      };

      const balance = balanceMap[type] || 0;
      const parsedValue = parseFloat(value) || 0;

      if (data === 1) {
        setSendValue(value);
        const result =
          parsedValue > 0
            ? await getEstimatedValueForLiquiditynew(
                type,
                type2,
                value,
                walletProvider
              )
            : "";
        setReceiveValue(result);
        setIsInsufficientBalance1(parsedValue > balance);
        setIsInsufficientBalance2(false);
      } else {
        setReceiveValue(value);
        const result =
          parsedValue > 0
            ? await getEstimatedValueForLiquiditynew(
                type,
                type2,
                value,
                walletProvider
              )
            : "";
        setSendValue(result);
        setIsInsufficientBalance2(parsedValue > balance);
        setIsInsufficientBalance1(false); // Reset the first box's error
      }
      checkInsufficientBalance();
      setIsToken1Approved(false);
      setIsToken2Approved(false);
    } catch (error) {
      console.error("Error in swap input:", error);
    }
  };

  const checkInsufficientBalance = () => {
    const parsedSendValue = parseFloat(sendValue) || 0;
    const parsedReceiveValue = parseFloat(receiveValue) || 0;

    const isInsufficientToken1Balance =
      (selectedToken1.name === "USDT" &&
        parsedSendValue > parseFloat(usdtBalance)) ||
      (selectedToken1.name === "XBR" &&
        parsedSendValue > parseFloat(xbrBalance)) ||
      (selectedToken1.name === "TBA" &&
        parsedSendValue > parseFloat(tbaBalance));

    const isInsufficientToken2Balance =
      (selectedToken2.name === "USDT" &&
        parsedReceiveValue > parseFloat(usdtBalance)) ||
      (selectedToken2.name === "XBR" &&
        parsedReceiveValue > parseFloat(xbrBalance)) ||
      (selectedToken2.name === "TBA" &&
        parsedReceiveValue > parseFloat(tbaBalance));

    setIsInsufficientBalance1(isInsufficientToken1Balance);
    setIsInsufficientBalance2(isInsufficientToken2Balance);

    setIsInsufficientBalance(
      isInsufficientToken1Balance || isInsufficientToken2Balance
    );
  };
  useEffect(() => {
    checkInsufficientBalance();
  }, [sendValue, receiveValue, selectedToken1, selectedToken2]);

  useEffect(() => {
    checkInsufficientBalance();
  }, [sendValue, receiveValue, selectedToken1, selectedToken2]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setIsOpen1(false);
      }
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setIsOpen2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function safeParseNumber(value) {
    const number = Number(value);
    return isNaN(number) ? 0 : number;
  }
  useEffect(() => {
    const fetchPositionIds = async () => {
      setIsFetchingPositions(true);
      try {
        const isConnected = localStorage.getItem("connectedAccount") !== null;
        if (!isConnected) {
          setPositionIds([]);
          return;
        }
        const positions = await getPositionIds(walletProvider);
        setPositionIds(positions);
        console.log("positionids", positions);
      } catch (error) {
        console.error("Error fetching positionIds:", error);
      } finally {
        setIsFetchingPositions(false);
      }
    };
    fetchPositionIds();
  }, []);
  const handleDropdownToggle = (event) => {
    event.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };
  // const handleOptionSelect = () => {

  //   const liquidity = liquidityData[nftId];
  //   if (liquidity) {

  //     // Set selected tokens based on token names
  //     setSelectedToken1(tokenMap[liquidity.token0Name]);
  //     setSelectedToken2(tokenMap[liquidity.token1Name]);
  //     setToken0Name(liquidity.token0Name);
  //     setToken1Name(liquidity.token1Name);
  //     setToken0Image(tokenImageMap[liquidity.token0Name]);
  //     setToken1Image(tokenImageMap[liquidity.token1Name]);
  //   }
  // };

  const getLiquidityData = async () => {
    const { token0, token1, fee } = await getLiquidity(
      Number(nftId),
      walletProvider
    );

    const token0LiquidityAmount = token0.amount;
    const token1LiquidityAmount = token1.amount;

    const token0Name = token0.symbol;
    const token1Name = token1.symbol;

    const amount1 = parseFloat(token0LiquidityAmount);
    const amount2 = parseFloat(token1LiquidityAmount);

    const tokenMap = {
      XBR: tokens[0],
      USDT: tokens[1],
      TBA: tokens[2],
    };

    setSelectedToken1(tokenMap[token0Name]);
    setSelectedToken2(tokenMap[token1Name]);
    setToken0Name(token0Name);
    setToken1Name(token1Name);
    setToken0Image(tokenImageMap[token0Name]);
    setToken1Image(tokenImageMap[token1Name]);
  };

  useEffect(() => {
    getLiquidityData();
  }, [nftId]);
  useEffect(() => {
    if (refreshNFTId) {
      getLiquidityData();
      dispatch(setRefreshNftId(false));
    }
  }, [refreshNFTId]);

  // useEffect(() => {
  //   if (positionIds?.length === 1) {
  //
  //   }
  // }, [positionIds]);

  const handleincreaseLiquidity = async () => {
    setIsConfirmModalOpen(true);
    try {
      let adjustedSendValue = Number(sendValue);
      let adjustedReceiveValue = Number(receiveValue);

      const tokenPairAdjustments = {
        "XBR-USDT": () => {
          [adjustedSendValue, adjustedReceiveValue] = [
            adjustedReceiveValue,
            adjustedSendValue,
          ];
        },
        "USDT-TBA": () => {
          [adjustedSendValue, adjustedReceiveValue] = [
            adjustedReceiveValue,
            adjustedSendValue,
          ];
        },
        "XBR-TBA": () => {
          [adjustedSendValue, adjustedReceiveValue] = [
            adjustedReceiveValue,
            adjustedSendValue,
          ];
        },
      };

      const tokenPairKey = `${selectedToken1.name}-${selectedToken2.name}`;

      if (tokenPairAdjustments[tokenPairKey]) {
        tokenPairAdjustments[tokenPairKey]();
      }

      const mint = await modifyPositionnew(
        nftId,
        selectedToken1.name,
        selectedToken2.name,
        adjustedSendValue,
        adjustedReceiveValue,
        walletProvider
      );
      setHashdata(mint);
      if (
        mint !== "Failed" &&
        mint !== null &&
        mint !== "" &&
        mint !== undefined
      ) {
        dispatch(setRefreshNftId(true));
        setIsConfirmModalOpen(false);
        setIsIncreaseConfirmed(true);
        setIsLiquidityChanged(true);
        setTimeout(() => {
          setSendValue(0);
          setReceiveValue(0);
          setIsToken1Approved(false);
          setIsToken2Approved(false);
        }, 2000);
      } else {
        // Handle failure case
        toast.error("Liquidity Increase failed");
      }
      if (
        mint === "Failed" ||
        mint === null ||
        mint === "" ||
        mint === undefined
      ) {
        setIsConfirmModalOpen(false);
        setIsTransactionFailed(true);
        // setOpenError(true);
        // setTimeout(() => setOpenError(false), 15000);
      }
    } catch (error) {
    } finally {
      setIsConfirmModalOpen(false);
    }
  };

  // const collectfees = async () => {
  //   try {
  //     const collect = collectfees(
  //       positionSelectedOption,
  //       selectedToken1.name,
  //       selectedToken2.name,
  //       walletProvider
  //     );
  //     console.log(collect);
  //   } catch (error) {
  //     console.error("Error at claiming Fees", error);
  //   }
  // };

  const handleXbrInputChangeInCard = (event) => {
    let inputValue = event.target.value;

    // Remove any non-numeric characters except dot (.)
    inputValue = inputValue.replace(/[^0-9.]/g, "");

    // Ensure only one dot (.) is allowed
    const dotCount = (inputValue.match(/\./g) || []).length;
    if (dotCount > 1) {
      inputValue = inputValue.slice(0, -1);
    }

    // Prevent negative values
    if (inputValue.startsWith("-")) {
      inputValue = "";
    }

    // Update state with the sanitized input value
    setSendValue(inputValue);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the clicked target is outside the dropdown and the button
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    if (isDropdownOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);
  return (
    <div
      className={`flex flex-col justify-center items-center gap-4 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[96%] xs:w-[28.5rem] p-2 xs:p-4 z-30 mobile:rounded-xl rounded-3xl ${
        theme.palette.mode === "light" ? "bg-white" : "bg-[#02042C]"
      }`}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover mobile:rounded-xl rounded-3xl z-0 opacity-30"
        src={tabBgVideo}
      >
        Your browser does not support the video tag.
      </video>
      <div
        className={`text-[20px] font-bold leading-[150%] ${
          theme.palette.mode === "light" ? "text-[#181821]" : "text-[#DCDCDE]"
        }}`}
      >
        Increase Liquidity
      </div>
      <MdClose
        className="w-6 h-6 absolute top-3 right-2 xs:top-5 xs:right-4 font-bold cursor-pointer"
        onClick={onClose}
      />

      <div className="flex flex-col items-start justify-start gap-[8px] w-full relative">
        {/* card 1 */}
        <div
          style={cardStyle}
          className={`flex justify-between rounded-2.5 mobile:rounded-2.5 flex-row items-center p-4 z-20 w-full`}
        >
          <div className="flex flex-col w-full">
            <div className="flex justify-end">
              <div
                className={`text-[#CE4954] text-[16px] ${
                  isInsufficientBalance1 && connectedAccount
                    ? "visible opacity-100"
                    : "invisible opacity-0"
                }`}
              >
                Insufficient Balance{" "}
                <span
                  className="underline cursor-pointer text-[#F25663]"
                  onClick={() => {
                    localStorage.setItem("activeTab", 1);
                    setActiveSection(1);
                    navigate("/swap");
                  }}
                >
                  Swap Now
                </span>
              </div>
            </div>

            <div className="flex flex-row justify-between items-center w-full">
              <div className="flex flex-col items-start justify-center gap-1 text-base mt-2">
                <div className="relative">
                  <div
                    className={`flex items-center justify-between px-4 py-3 w-32 ${
                      theme.palette.mode === "light"
                        ? "bg-lightgray-400"
                        : "bg-gray-400"
                    } rounded-xl border border-gray-300 gap-2`}
                  >
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src={token0Image}
                        alt={token0Name}
                        className="w-6 h-6 object-cover"
                      />
                      <span>{token0Name}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    connectedAccount
                      ? "visible opacity-100"
                      : "invisible opacity-0"
                  } ${
                    theme.palette.mode === "light"
                      ? "text-[#69696F]"
                      : "text-[#97979B]"
                  } whitespace-nowrap text-ft5 flex gap-1`}
                >
                  <span>
                    Balance: {getFormattedBalance(selectedToken1.name)}
                  </span>
                  <div
                    className="text-ft5 font-bold text-[#747FF4]"
                    style={{ cursor: "pointer" }}
                    onClick={() => newMax(selectedToken1.name, 1)}
                  >
                    Max
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-end justify-center gap-1 text-right mt-2">
                <input
                  className={`
            relative bg-[#02042C] text-right ${
              theme.palette.mode === "light"
                ? "text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
                : "text-white"
            } 
            outline-none font-inter text-[31px] w-30 md:w-80per font-bold placeholder-white 
          `}
                  placeholder="0"
                  type="text"
                  onWheel={(e) => e.preventDefault()}
                  onChange={(e) => {
                    let inputValue = e.target.value.replace(/[^0-9.]/g, "");
                    newOnChangeSwapInputs(
                      inputValue,
                      selectedToken1.name,
                      1,
                      selectedToken2.name
                    );
                    checkInsufficientBalance();
                    setIsToken1Approved(false);
                  }}
                  value={sendValue}
                />
                {connectedAccount && (
                  <div
                    className={`text-base ${
                      theme.palette.mode === "light"
                        ? "text-gray-10"
                        : "text-gray-50"
                    } text-ft5`}
                  >
                    ≈ $
                    {formatBalanceForPrice(
                      safeParseNumber(sendValue) *
                        safeParseNumber(pricemap[selectedToken1.name])
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Add Image */}
        <div
          className={`absolute top-[calc(50%-1.5rem)] left-1/2 transform -translate-x-1/2 flex items-center justify-center z-30`}
        >
          <div
            className={`w-[3rem] h-[3rem] rounded-full flex items-center justify-center ${
              theme.palette.mode === "light" ? "bg-[#D6DAFC]" : "bg-[#14174F]"
            }`}
          >
            <div
              className={`w-[2rem] h-[2rem] rounded-full flex items-center justify-center ${
                theme.palette.mode === "light" ? "bg-white" : "bg-[#02042C]"
              }`}
              // onClick={swapTokens}
            >
              <AddNewImg
                className={`${
                  theme.palette.mode === "light"
                    ? "text-[#34343C]"
                    : "text-white"
                }`}
              />
            </div>
          </div>
        </div>

        {/* card 2 */}
        <div
          style={cardStyle}
          className={`flex justify-between rounded-2.5 mobile:rounded-2.5 flex-row items-center p-4 z-10 w-full relative`}
        >
          <div className="flex flex-col w-full">
            <div className="flex justify-end">
              <div
                className={`text-[#CE4954] text-[16px] ${
                  isInsufficientBalance2 && connectedAccount
                    ? "visible opacity-100"
                    : "invisible opacity-0"
                }`}
              >
                Insufficient Balance{" "}
                <span
                  className="underline cursor-pointer text-[#F25663]"
                  onClick={() => {
                    localStorage.setItem("activeTab", 1);
                    setActiveSection(1);
                    navigate("/swap");
                  }}
                >
                  Swap Now
                </span>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center w-full">
              <div className="flex flex-col items-start justify-center gap-1 text-base mt-2">
                <div className="relative">
                  <div
                    className={`flex items-center justify-between px-4 py-3 w-32 ${
                      theme.palette.mode === "light"
                        ? "bg-lightgray-400"
                        : "bg-gray-400"
                    } rounded-xl border border-gray-300 gap-2`}
                  >
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src={token1Image}
                        alt={token1Name}
                        className="w-6 h-6 object-cover"
                      />
                      <span>{token1Name}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    connectedAccount
                      ? "visible opacity-100"
                      : "invisible opacity-0"
                  } ${
                    theme.palette.mode === "light"
                      ? "text-[#69696F]"
                      : "text-[#97979B]"
                  } whitespace-nowrap text-ft5 flex gap-1`}
                >
                  <span>
                    Balance: {getFormattedBalance(selectedToken2.name)}
                  </span>
                  <div
                    className="text-ft5 font-bold text-[#747FF4]"
                    style={{ cursor: "pointer" }}
                    onClick={() => newMax(selectedToken2.name, 2)}
                  >
                    Max
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-end justify-center gap-1 text-right mt-2">
                <input
                  className={`
        relative bg-[#02042C] text-right ${
          theme.palette.mode === "light"
            ? "text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
            : "text-white"
        } 
        outline-none font-inter text-[31px] w-30 md:w-80per font-bold placeholder-white 
      `}
                  placeholder="0"
                  type="text"
                  onWheel={(e) => e.preventDefault()}
                  onChange={(e) => {
                    let inputValue = e.target.value.replace(/[^0-9.]/g, "");
                    newOnChangeSwapInputs(
                      inputValue,
                      selectedToken2.name,
                      2,
                      selectedToken1.name
                    );
                    checkInsufficientBalance();
                    setIsToken2Approved(false);
                  }}
                  value={receiveValue}
                />
                {connectedAccount && (
                  <div
                    className={`text-base ${
                      theme.palette.mode === "light"
                        ? "text-gray-10"
                        : "text-gray-50"
                    } text-ft5`}
                  >
                    ≈ $
                    {formatBalanceForPrice(
                      safeParseNumber(receiveValue) *
                        safeParseNumber(pricemap[selectedToken2.name])
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-5 items-center justify-between text-base font-inter z-0 w-full">
        <button
          className={`flex-1 h-[47px] min-h-[47px] rounded-[12px] flex items-center justify-center py-3 px-3  ${
            theme.palette.mode === "light" ? "text-white" : "text-white"
          } bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300 `}
          onClick={() => {
            if (!isToken1Approved && !isUSDTLoader) {
              handleApprove(selectedToken1.name);
            }
          }}
          disabled={isUSDTLoader || !sendValue || isInsufficientBalance1}
        >
          <div
            className={`relative font-paragraph-p1-regular text-ft15 font-semibold flex ${
              isToken1Approved ? "" : "opacity-70"
            }`}
          >
            {isUSDTLoader ? (
              <div>
                <BiLoaderAlt className="animate-spin text-gray-50" />
              </div>
            ) : isToken1Approved ? (
              <>
                <span className="flex gap-1 flex-row items-center">
                  <img
                    alt="NewCheck"
                    src={NewCheck}
                    className="w-4 h-4 mt-[2px] rounded-[12px]"
                  />
                  Approved {selectedToken1.name}
                </span>
              </>
            ) : (
              `Approve ${selectedToken1.name}`
            )}
          </div>
        </button>

        {/* Approve Button for second token */}
        <button
          className={`flex-1 h-[47px] min-h-[47px] rounded-[12px] flex items-center justify-center py-3 px-3 text-white bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300 `}
          onClick={() => {
            if (!isToken2Approved && !isXBRLoader) {
              handleApprove(selectedToken2.name);
            }
          }}
          disabled={isXBRLoader || !receiveValue || isInsufficientBalance2}
        >
          <div
            className={`relative font-paragraph-p1-regular text-ft15 font-semibold text-white ${
              isToken2Approved ? "" : "opacity-70"
            }`}
          >
            {isXBRLoader ? (
              <div>
                <BiLoaderAlt className="animate-spin text-gray-50" />
              </div>
            ) : isToken2Approved ? (
              <>
                <span className="flex gap-1 flex-row items-center">
                  <img
                    alt="NewCheck"
                    src={NewCheck}
                    className="w-4 h-4 mt-[2px] rounded-[12px]"
                  />
                  Approved {selectedToken2.name}
                </span>
              </>
            ) : (
              `Approve ${selectedToken2.name}`
            )}
          </div>
        </button>
      </div>

      <div className="w-full z-10">
        {connectedAccount ? (
          <button
            className={`w-full py-3 px-6 z-10 rounded-[12px] text-base font-semibold leading-6 text-white bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300`}
            onClick={() => handleincreaseLiquidity()}
            disabled={
              isInsufficientBalance ||
              !isToken1Approved ||
              !isToken2Approved ||
              !selectedOption
            }
          >
            {!selectedOption
              ? "Select NFT ID"
              : isInsufficientBalance
              ? "Insufficient balance"
              : sendValue && receiveValue
              ? "Increase Liquidity"
              : "Enter Amount"}
          </button>
        ) : (
          <NavLink
            to="/connect-wallet"
            className="w-full h-[47px] min-h-[47px] rounded-[12px] flex items-center justify-center bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300  text-white cursor-pointer z-10"
          >
            Connect Wallet
          </NavLink>
        )}
      </div>
      {isConfirmModalOpen && (
        <LiquidityConfirmationModal
          onClose={() => setIsConfirmModalOpen(false)}
          text={`Increasing ${formatBalanceForPrice(sendValue)} ${
            selectedToken1.name
          } and ${formatBalanceForPrice(receiveValue)} ${selectedToken2.name}`}
        />
      )}
      {isIncreaseConfirmed && (
        <LiquidityConfirmedModal
          onClose={() => {
            setIsIncreaseConfirmed(false);
          }}
          text={`Increased Liquidity`}
          paragraph={`${modalData.sendValue} ${modalData.token1Name} and ${modalData.receiveValue} ${modalData.token2Name}`}
          token0Name={modalData.token1Name}
          token1Name={modalData.token2Name}
          hashdata={modalData.hashdata}
        />
      )}
      {isTransactionFailed && (
        <FailedTransaction onClose={() => setIsTransactionFailed(false)} />
      )}
    </div>
  );
};

export default IncreaseLiquidity;
