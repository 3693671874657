import React, { useEffect, useRef, useState } from "react";
import {
  Logo,
  polygon_icon,
  notification_icon,
  star_icon,
  portfolio,
} from "../assets/";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";
import { DarkMode, KeyboardArrowDown, LightMode } from "@mui/icons-material";
import { IconButton, useTheme, SwipeableDrawer, Tooltip } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccount,
  setProvider,
  setMode,
  selectSelectedWallet,
  setSelectedWallet,
  setIsWalletConnect,
  selectIsWalletConnect,
  setCurrentDisplay,
  setIsMetaMaskConnected,
  selectUserBalance,
  setCurrentChain,
} from "../redux/wallet/walletSlice";
import { useWeb3Modal, useDisconnect } from "@web3modal/ethers/react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  formatUnits,
  Contract,
  BrowserProvider,
  getAddress,
  formatEther,
} from "ethers";
// import { setDummy } from "../redux/data/dataSlice";
import WalletItemComponent from "../components/WalletItemComponent";
import { toast } from "react-hot-toast";
import { USDT_ABI } from "../web3Utils";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useWeb3React } from "@web3-react/core";
import { fetchMarketData } from "../uniswap/utils";
import { clearLocalStorage } from "../utils/walletUtils";
import { setThemeMode, selectThemeMode } from "../redux/theme/themeSlice";
import { saveLocation } from "../redux/locations/redirectSlice";

const Navbar = () => {
  const [xbrPrice, setXbrPrice] = useState(0);
  const [tbaPrice, setTbaPrice] = useState(0);
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [usdcPrice, setusdcPrice] = useState(0);
  const [maticPrice, setMaticPrice] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const { disconnect, open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const [selectedOption, setSelectedOption] = useState("Pool Participants");
  const [arrowRotation, setArrowRotation] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState("Polygon");
  const [selectedOption2Image, setSelectedOption2Image] =
    useState(polygon_icon);
  // const [currentChain, setCurrentChain] = useState("");
  const currentChain = useSelector((state) => state.wallet.currentChain);
  const storedWalletName = localStorage.getItem("walletName");
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [isDropdownOpen2, setDropdownOpen2] = useState(false);
  const [arrowRotation2, setArrowRotation2] = useState(0);
  const [options2, setOptions2] = useState([
    { name: "Polygon", chainId: 137, image: polygon_icon },
    // { name: "Polygon TestNet", chainId: 80001, image: polygon_icon },
  ]);

  const [drawerState, setDrawerState] = useState({
    left: false,
  });

  // Wallet selection for mobile screen
  const [drawerState1, setDrawerState1] = useState({
    right: false,
  });

  function DropdownItem({ label, onClick, style }) {
    return (
      <div
        className="p-3 cursor-pointer transition-all hover:bg-gray-100"
        onClick={onClick}
        style={{ color: style.color }}
      >
        {label}
      </div>
    );
  }

  function Dropdown({
    isMobile,
    isDropdownOpen,
    alt,
    dark,
    dropdownRef,
    handleDropdownChange,
    selectedOption,
    arrowRotation,
    options,
  }) {
    return (
      <div
        ref={dropdownRef}
        style={{ backgroundColor: alt }}
        className={`relative ${
          isMobile ? "hidden" : "block md:flex equal-width-dropdown"
        }`}
      >
        <div
          className="flex items-center justify-between gap-[4px] cursor-pointer"
          onClick={handleDropdownChange}
          style={{
            backgroundColor: "transparent",
            padding: "6px",
            borderRadius: "10px",
            border: `1px solid ${isDropdownOpen ? "#1890ff" : "#808080"}`,
            boxShadow: isDropdownOpen
              ? "0px 4px 8px rgba(0, 0, 0, 0.1)"
              : "none",
          }}
        >
          <div
            style={{ color: dark }}
            className="dropdown-style text-white cursor-pointer"
          >
            {selectedOption}
          </div>
          <KeyboardArrowDown
            style={{
              transform: `rotate(${arrowRotation}deg)`,
              transition: "transform 0.3s ease",
              color: isDropdownOpen ? "#1890ff" : "#808080",
            }}
          />
        </div>

        {isDropdownOpen && (
          <div className="absolute top-full left-0 z-10 border text-white border-gray-300 shadow-md max-h-36 overflow-y-auto mt-2 rounded-md">
            {options.map((option, index) => (
              <DropdownItem
                key={index}
                label={option}
                onClick={() => handleOptionClick(option)}
                style={{ color: dark }}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentMode = useSelector(selectThemeMode);
  const { account, provider, mode } = useSelector((state) => state.wallet);

  // console.log(account);

  const handleConnectWalletPage = () => {
    navigate("/connect-wallet");
  };

  const handleNavigate = (path) => {
    dispatch(saveLocation(path))
    navigate(`/${path}`);
  };
  const isWalletConnected = localStorage.getItem("isWallet");
  const isWallet = localStorage.getItem("isWallet");

  const isWalletConnect = useSelector(selectIsWalletConnect);
  // console.log(isWalletConnect);

  const selectedWallet = useSelector(selectSelectedWallet);
  // console.log(selectedWallet);

  const currentDisplay = useSelector((state) => state.wallet.currentDisplay);
  // console.log(currentDisplay);
  const isMetaMaskConnected = useSelector(
    (state) => state.wallet.isMetaMaskConnected
  );

  const userBalance = useSelector(selectUserBalance);
  // console.log(userBalance);

  const Modal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div
          className="fixed inset-0"
          style={{
            background: `${modalColor}80`,
            backdropFilter: "blur(20px)",
          }}
        ></div>
        <div
          className="bg-white rounded-lg relative m-12 md:m-0 w-96"
          style={{ background: alt }}
        >
          <div className="flex items-center justify-between text-center mx-auto p-3">
            <div className="flex items-center justify-between text-center mx-auto">
              <h2 className="text-xl font-bold text-center w-full text-[#5763F3]">
                Chain Selection
              </h2>
            </div>
          </div>

          <div className="border-t border-grey-500"></div>
          <div className="overflow-y-auto max-h-60 mb-2 mt-4 px-8 py-4">
            <p
              className="mb-6 text-gray-700 text-center"
              style={{ color: dark }}
            >
              Please switch to the Matic (Polygon) network to continue.
            </p>
            <div className="flex justify-center">
              <button
                className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none w-32"
                onClick={onConfirm}
              >
                Switch Now
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const alt = theme.palette.background.alt;
  const background = theme.palette.background.default;
  const line = theme.palette.primary.main;
  const primaryLight = theme.palette.primary.light;
  const cardColor = theme.palette.cardColor.cardColors;
  const modalColor = theme.palette.bgColor.modalBg;
  // const [isPopupOpen, setPopupOpen] = useState(false);

  // const closePopup = () => {
  //   setPopupOpen(false);
  // };

  // useEffect(() => {
  //   handleWalletConnection();
  // }, []);

  const handleWalletConnection = () => {
    if (selectedWallet === "Connect Wallet") {
      localStorage.setItem("isWalletConnected", "true");
      dispatch(setSelectedWallet(localStorage.getItem("walletName")));
      // dispatch(setDummy(true));
      dispatch(setIsWalletConnect(true));
      localStorage.setItem("isWallet", "true");
    } else {
      openPopup();
    }
  };

  // const [currentDisplay, setCurrentDisplay] = useState('Connect Wallet');
  // const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [walletDisconnected, setWalletDisconnected] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const [isDrawerOpen1, setIsDrawerOpen1] = useState(false);

  const handleConnectWalletClick = () => {
    if (isMetaMaskConnected && !localStorage.getItem("walletName")) {
      setIsDrawerOpen(true);
    }
  };
  // const handleConnectWalletClick1 = () => {
  //   if (isMetaMaskConnected && !localStorage.getItem('walletName')) {
  //     setIsDrawerOpen1(true);
  //   }
  // };

  // useEffect(() => {
  //   handleConnectWalletClick()
  // }, [isMetaMaskConnected]);

  const [updatedMockDataToken, setUpdatedMockDataToken] = useState([]);

  // console.log(updatedMockDataToken);

  const mockDataToken = [
    {
      id: 1,
      token: "XBR",
      value: 0.0,
    },
    {
      id: 2,
      token: "MATIC",
      value: 0.0,
    },
    {
      id: 3,
      token: "USDT",
      value: 0.0,
    },
  ];

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum) {
      checkMetaMaskConnection();
      ethereum.on("accountsChanged", handleAccountsChanged);
    }
  }, []);

  const checkMetaMaskConnection = async () => {
    const { ethereum } = window;

    try {
      const accounts = await ethereum.request({ method: "eth_accounts" });
      const newProvider = new BrowserProvider(window.ethereum);

      const providerData = {
        network: Number((await newProvider?.getNetwork()).chainId),
      };

      dispatch(setProvider(providerData));

      if (accounts.length > 0) {
        // console.log("asha2");

        dispatch(setIsMetaMaskConnected(true));

        const storedWalletAddress = localStorage.getItem("connectedAccount");
        // if (storedWalletName && localStorage.getItem('isWallet') === 'true') {
        if (
          storedWalletAddress &&
          localStorage.getItem("isWallet") === "true"
        ) {
          dispatch(setCurrentDisplay(storedWalletAddress));
          // checkBalance(newProvider);
        } else {
          dispatch(setCurrentDisplay("Connect Wallet"));
        }
      } else {
        localStorage.removeItem("isWallet");
        localStorage.removeItem("walletName");
        localStorage.removeItem("connectedAccount");
        localStorage.removeItem("referralAddress");

        // console.log("asha2.0");

        dispatch(setCurrentDisplay("Connect Wallet"));
        dispatch(setIsMetaMaskConnected(false));
        dispatch(setAccount(""));
      }
    } catch (error) {
      console.error("Error checking MetaMask connection:", error);
    }
  };

  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      dispatch(setCurrentDisplay("Connect Wallet"));
      dispatch(setIsMetaMaskConnected(false));
      console.log("MetaMask is not connected.");
      handleMetaMaskDisconnect();
      setUpdatedMockDataToken(
        mockDataToken.map((token) => ({ ...token, value: 0.0 }))
      );
      clearLocalStorage();
    } else {
      const selectedAccount = getAddress(accounts[0]);
      dispatch(setIsMetaMaskConnected(true));
      dispatch(setAccount(selectedAccount));
      console.log("MetaMask is connected.");
      localStorage.setItem("connectedAccount", selectedAccount);

      dispatch(setCurrentDisplay(localStorage.getItem("connectedAccount")));
      updateTokenBalances();
    }
  };

  const handleMetaMaskDisconnect = () => {
    localStorage.clear();
    dispatch(setAccount(""));
    localStorage.removeItem("connectedAccount");
    localStorage.removeItem("referralAddress");
    localStorage.removeItem("isWallet");
    localStorage.removeItem("walletName");
  };

  const openMetaMaskExtension = () => {
    const { ethereum } = window;
    if (ethereum) {
      handleConnectWallet(); // Connect wallet if MetaMask is not connected
    } else {
      // MetaMask not installed, handle this case (e.g., show a message)
      console.log("MetaMask is not installed");
    }
  };

  const handleDisconnect = async () => {
    if (storedWalletName === "Wallet Connect") {
      console.log("wallet is connected");
      await disconnect();
      localStorage.clear();
      dispatch(setCurrentDisplay("Connect Wallet"));
      dispatch(setAccount(""));
      dispatch(setIsWalletConnect(false));
      setWalletDisconnected(true);
      setIsPopupOpen(false);
      navigate("/connect-wallet", { replace: true });
      toast.success("Wallet Disconnected", { duration: 1000 });
    } else {
      console.log("else =======wallet is connected", storedWalletName);
      dispatch(setCurrentDisplay("Connect Wallet"));
      dispatch(setAccount(""));
      dispatch(setIsWalletConnect(false));
      localStorage.removeItem("isWallet");
      localStorage.removeItem("walletName");
      localStorage.removeItem("connectedAccount");
      localStorage.removeItem("referralAddress");
      localStorage.clear();
      setWalletDisconnected(true);
      setIsPopupOpen(false);
      navigate("/connect-wallet", { replace: true });
      toast.success("Wallet Disconnected", { duration: 1000 });
    }
  };

  const handleConnectClick = () => {
    const { ethereum } = window;

    if (ethereum) {
      if (!isMetaMaskConnected) {
        openMetaMaskExtension(); // Open MetaMask extension if not connected
      } else {
        openPopup(); // Open modal if walletName is available
      }
    } else {
      console.log("MetaMask is not installed");
    }
  };

  const handleWalletSelection = (walletName) => {
    if (isMetaMaskConnected) {
      localStorage.setItem("isWallet", "true");
      localStorage.setItem("walletName", walletName);
      dispatch(setCurrentDisplay(localStorage.getItem("connectedAccount")));
      dispatch(setAccount(localStorage.getItem("connectedAccount")));
      setIsDrawerOpen(false); // Close the drawer// Reload the page when connected
    }
    // else {
    //   // Connect the wallet
    //   localStorage.setItem('isWallet', 'true');
    //   localStorage.setItem('walletName', walletName);
    //   setCurrentDisplay(walletName); // Update currentDisplay immediately
    //   setIsDrawerOpen(false); // Close the drawer
    // }
  };

  const handleTrustWalletSelection = async (walletName) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { activate } = useWeb3React();
    const walletConnect = new WalletConnectConnector({
      rpc: { 1: "https://mainnet.infura.io/v3/YOUR_INFURA_ID" },
      bridge: "https://bridge.walletconnect.org",
      qrcode: true,
      pollingInterval: 15000,
    });

    try {
      await activate(walletConnect);
      const connectedAddress = walletConnect.account;
      localStorage.setItem("isWallet", "true");
      localStorage.setItem("walletName", walletName);
      dispatch(setCurrentDisplay(connectedAddress));
      dispatch(setAccount(connectedAddress));
      setIsDrawerOpen(false);
    } catch (error) {
      console.log("Failed to connect to Trust Wallet", error);
    }
  };

  const handleConnectWallet = async () => {
    const { ethereum } = window;
    if (!ethereum) return;

    try {
      const chainId = await ethereum.request({ method: "eth_chainId" });
      const currentChainId = parseInt(chainId, 16);

      // Check if the connected chain is Polygon mainnet (137) or testnet (80001)
      if (currentChainId !== 137 && currentChainId !== 80001) {
        // Open the modal to prompt the user to switch the network
        setIsModalOpen(true); // Open the modal

        return; // Exit the function without displaying toast
      }

      if (!isMetaMaskConnected) {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        if (!accounts || accounts.length === 0) {
          return;
        }

        const selectedAccount = getAddress(accounts[0]);
        const newProvider = new BrowserProvider(window.ethereum);

        const providerData = {
          network: Number((await newProvider?.getNetwork()).chainId),
        };

        localStorage.setItem("isWallet", "true");
        localStorage.setItem("walletName", "metamask");
        localStorage.setItem("connectedAccount", selectedAccount);

        dispatch(setAccount(selectedAccount));
        dispatch(setCurrentDisplay(selectedAccount));
        dispatch(setIsMetaMaskConnected(true));
      } else {
        // Disconnect the wallet
        // console.log("gfdbdn");
        dispatch(setCurrentDisplay("Connect Wallet"));
        dispatch(setIsMetaMaskConnected(false));
        dispatch(setAccount("")); // Dispatch 'Not Connected' to indicate disconnection

        localStorage.setItem("isWallet", "false");
        setIsDrawerOpen(false);
        dispatch(setIsWalletConnect(false)); // Set isWalletConnect to false when disconnecting
      }
    } catch (error) {
      console.error("Error connecting to MetaMask:", error);
    }
  };

  const closePopup = () => {
    console.log("open popup false...........");
    setIsPopupOpen(false);
  };

  const openPopup = () => {
    console.log("open popup true...........");
    setIsPopupOpen(true);
  };

  const handleWalletDisconnection = () => {
    setCurrentDisplay("Connect Wallet");
    setIsMetaMaskConnected(false);
    localStorage.setItem("isWallet", "false");
    localStorage.removeItem("walletName");
    setWalletDisconnected(true); // Set flag to indicate wallet is disconnected
    setIsPopupOpen(false); // Close the disconnect modal

    dispatch(setAccount(""));
  };

  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  // useEffect(() => {
  //   // Check for connected wallet in local storage when component mounts
  //   const storedAccount = localStorage.getItem("connectedAccount");
  //   if (storedAccount) {
  //     dispatch(setAccount(storedAccount));
  //   }
  // }, [dispatch]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const updateWindowSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    const handleResize = () => {
      updateWindowSize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const isMobileDevice = windowSize.width <= 768;
    setIsMobile(isMobileDevice);
  }, [windowSize]);

  const [dropdownContentWidth, setDropdownContentWidth] = useState(170);

  const calculateDropdownContentWidth = () => {
    const contentWidth = dropdownRef.current.offsetWidth; // Adjust this based on your content
    setDropdownContentWidth(contentWidth);
  };

  useEffect(() => {
    calculateDropdownContentWidth();
  }, [selectedOption]); // Up

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setDropdownOpen(false);
        setArrowRotation(0);

        setDropdownOpen2(false);
        setArrowRotation2(0);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen, isDropdownOpen2]);

  const handleDropdownChange = () => {
    setDropdownOpen(!isDropdownOpen);
    setArrowRotation(isDropdownOpen ? 0 : 180);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    setArrowRotation(0);
  };

  // const handleDropdownChange2 = () => {
  //   setDropdownOpen2(!isDropdownOpen2);
  //   setArrowRotation2(isDropdownOpen2 ? 0 : 180);
  // };

  const handleDisconnetMob = async () => {
    if (storedWalletName === "Wallet Connect") {
      console.log("wallet is connected");
      await disconnect();
      localStorage.clear();
      dispatch(setCurrentDisplay("Connect Wallet"));
      dispatch(setAccount(""));
      dispatch(setIsWalletConnect(false));
      setWalletDisconnected(true);
      setIsPopupOpen(false);
      navigate("/connect-wallet", { replace: true });
      toast.success("Wallet Disconnected", { duration: 1000 });
    } else {
      console.log("else =======wallet is connected");
      localStorage.clear();
      navigate("/connect-wallet", { replace: true });
    }
  };

  const handleOptionClick2 = async (option) => {
    // console.log(option);
    try {
      if (window.ethereum) {
        const chainIdHex = `0x${option.chainId.toString(16)}`;
        // console.log(chainIdHex);
        const chainParams = {
          chainId: chainIdHex,
        };

        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [chainParams],
        });
      }
      setSelectedOption2(option.name);
      setSelectedOption2Image(option.image);
      setDropdownOpen2(false);
      setArrowRotation2(isDropdownOpen2 ? 0 : 180);
    } catch (error) {
      console.error("Error while switching chain:", error);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const handleModeChange = () => {
    const newMode = currentMode === "dark" ? "light" : "dark";
    dispatch(setThemeMode(newMode));
  };

  const toggleDrawer1 = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState1({ ...drawerState1, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className="bg-gray-900 min-h-screen flex flex-col"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* Close Button */}

      {/* Menu Content */}
      <div
        className="w-[100vw] max-w-[300px] flex-1"
        style={{ background: alt }}
      >
        <div className="flex m-2 items-end justify-end cursor-pointer">
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>

        {/* Logo */}
        <div className="flex justify-center items-center p-4">
          <div className="flex items-center cursor-pointer justify-center mr-20">
            <img src={Logo} alt="Logo" className="w-11 h-10 relative" />
            <span className="text-sm font-medium text-[#3840CD] absolute top-[68px] left-[125px]">
              BULL RUN
            </span>
          </div>
        </div>

        <div className="border-t border-gray-600 my-4"></div>

        {/* Token List: USDT, MATIC, XBR */}
        <ul className="flex flex-col gap-4 cursor-pointer items-center space-y-4 p-4">
          <li className="flex gap-2 items-center text-sm text-gray-500">
            <span>TBA</span>
            <span className="text-blue-500">
              ${tbaPrice ? tbaPrice : "Loading..."}
            </span>
          </li>
          <li className="flex gap-2 items-center text-sm text-gray-500">
            <span>XBR</span>
            <span className="text-blue-500">
              ${xbrPrice ? xbrPrice : "Loading..."}
            </span>
          </li>
          <li className="flex gap-2 items-center text-sm text-gray-500">
            <span>MATIC</span>
            <span className="text-blue-500">
              $
              {maticPrice
                ? maticPrice < 1000
                  ? Number(maticPrice).toFixed(4)
                  : Number(maticPrice).toFixed(0)
                : "Loading..."}
            </span>
          </li>
          <li className="flex gap-2 items-center text-sm text-gray-500">
            <span>USDT</span>
            <span className="text-blue-500">
              ${usdtPrice ? usdtPrice : "Loading..."}
            </span>
          </li>
          <li className="flex gap-2 items-center text-sm text-gray-500">
            <span>USDC</span>
            <span className="text-blue-500">
              ${usdcPrice ? usdcPrice : "Loading..."}
            </span>
          </li>
        </ul>

        <div className="border-t border-gray-600 my-4"></div>

        {/* Additional Options: Whitelist, Portfolio, Notifications */}
        <div className="flex flex-col gap-4 items-center justify-center space-y-4 p-4 z-50">
          <div
            className="flex items-center justify-between w-full"
            style={{
              backgroundColor: "transparent",
              padding: "6px",
              width: "160px",
              borderRadius: "10px",
              border: `1px solid ${isDropdownOpen ? "#1890ff" : "#808080"}`,
              boxShadow: isDropdownOpen
                ? "0px 4px 8px rgba(0, 0, 0, 0.1)"
                : "none",
              opacity: 0.5,
              cursor: "not-allowed",
            }}
          >
            <div
              style={{
                color: dark,
                cursor: "not-allowed",
              }}
              className="dropdown-style text-white"
            >
              {selectedOption}
            </div>
            <KeyboardArrowDown
              style={{
                transform: `rotate(${arrowRotation}deg)`,
                transition: "transform 0.3s ease",
                color: isDropdownOpen ? "#1890ff" : "#808080",
              }}
            />
          </div>

          <button
            // disabled
            className="text-white text-center cursor-default bg-blue-600 py-2 px-4 rounded-md"
            style={{
              backgroundColor: "transparent",
              padding: "6px",
              width: "70px",
              borderRadius: "10px",
              border: `1px solid ${isDropdownOpen ? "#1890ff" : "#808080"}`,
              boxShadow: isDropdownOpen
                ? "0px 4px 8px rgba(0, 0, 0, 0.1)"
                : "none",
              opacity: 0.5,
              color: dark,
              cursor: "not-allowed",
            }}
            onClick={() => handleNavigate("swap")}
          >
            Swap
          </button>

          <button
            className="text-white text-center cursor-pointer bg-blue-600 py-2 px-4 rounded-md"
            style={{
              width: "fit-content",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            onClick={() => handleNavigate("node")}
          >
            Node
          </button>

          <button
            className="text-white text-center cursor-pointer bg-blue-600 py-2 px-4 rounded-md"
            style={{
              backgroundColor: "transparent",
              padding: "6px",
              width: "70px",
              borderRadius: "10px",
              border: `1px solid ${isDropdownOpen ? "#1890ff" : "#808080"}`,
              boxShadow: isDropdownOpen
                ? "0px 4px 8px rgba(0, 0, 0, 0.1)"
                : "none",
              opacity: 0.5,
              color: dark,
              cursor: "not-allowed",
            }}
          >
            App
          </button>
        </div>
      </div>
    </div>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  // used for manually change chain from application
  useEffect(() => {
    const handleChainChange = async (chainIdHex) => {
      try {
        // console.log("Chain changed:", chainIdHex);
        const selectedChain = options2.find(
          (option) => option.chainId === parseInt(chainIdHex, 16)
        );
        // console.log(selectedChain);
        if (selectedChain) {
          setSelectedOption2(selectedChain.name);
          setSelectedOption2Image(selectedChain.image);
        }
      } catch (error) {
        console.error("Error while handling chain change:", error);
      }
    };

    if (window.ethereum) {
      window.ethereum.on("chainChanged", handleChainChange);

      return () => {
        window.ethereum.removeListener("chainChanged", handleChainChange);
      };
    }
  }, [options2, setSelectedOption2, setSelectedOption2Image]);

  useEffect(() => {
    const handleChainChanged = async (chainId) => {
      // console.log(chainId);
      dispatch(setCurrentChain(chainId));
      if (chainId !== process.env.REACT_APP_CHAIN_ID) {
        setIsModalOpen(true);
      }

      updateTokenBalances();
    };

    if (window.ethereum) {
      window.ethereum.on("chainChanged", handleChainChanged);
      window.ethereum
        .request({ method: "eth_chainId" })
        .then((chainId) => dispatch(setCurrentChain(chainId)));
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, [currentChain]);

  const handleConfirmSwitch = async () => {
    setIsModalOpen(false);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: process.env.REACT_APP_CHAIN_ID }],
      });
      toast.success("Successfully switched to the Polygon network!");
    } catch (error) {
      console.log("Error while switching the Ethereum chain:", error);
      alert(
        "Failed to switch to the Matic (Polygon) network. Please switch manually."
      );
    }
  };

  const handleDropdownChange2 = async () => {
    try {
      const { ethereum } = window;
      if (!ethereum) {
        alert("Please install MetaMask to connect to the wallet.");
        return;
      }

      // Check if MetaMask is connected
      const chainId = await ethereum.request({ method: "eth_chainId" });
      const networkId = parseInt(chainId, 16);

      // Check if MetaMask is connected to the Polygon network (chainId 137 for mainnet, 80001 for testnet)
      if (networkId !== 137 && networkId !== 80001) {
        try {
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: process.env.REACT_APP_CHAIN_ID }],
          }); // Switching to mainnet
        } catch (error) {
          console.log("Error while switching the Ethereum chain:", error);
          alert(
            "Failed to switch to the Matic (Polygon) network. Please switch manually."
          );
        }
        return;
      }

      // Your existing logic when the chain is Matic (Polygon) mainnet or testnet
      // For example:
      // const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      // Handle wallet connection and provider setup...
    } catch (error) {
      console.log("Error while handling dropdown change:", error);
    }
  };

  useEffect(() => {
    // console.log("Inside useEffect");
    updateTokenBalances();
  }, [currentChain, isMetaMaskConnected, !localStorage.getItem("walletName")]);
  const tokenAddresses = [
    "0x253b1eCc08207bAA021f52e7BA4f602389fa41A5",
    "0x7e1AE068Ac4e0ffD7a84750c2FB093A0EAE9aEa2",
    "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    // Add more accounts as needed
  ];
  const updateTokenBalances = async () => {
    try {
      if (
        !localStorage.getItem("connectedAccount") ||
        !localStorage.getItem("walletName")
      ) {
        console.log("Wallet is not connected");
        setUpdatedMockDataToken(
          mockDataToken.map((token) => ({ ...token, value: 0.0 }))
        );
        return;
      }

      const URL = process.env.REACT_APP_API;

      const account = localStorage.getItem("connectedAccount");

      // Fetch token prices using the API
      const response = await axios.get(
        `${URL}/v1/xbr/coin-price?&address=${tokenAddresses}&count=157860000`
      );
      const { xbrPrice, maticPrice, usdtPrice } = response.data; // Assuming the API returns prices with these keys

      setUpdatedMockDataToken([
        { id: 1, token: "XBR", value: parseFloat(xbrPrice) },
        { id: 2, token: "MATIC", value: parseFloat(maticPrice) },
        { id: 3, token: "USDT", value: parseFloat(usdtPrice) },
      ]);
    } catch (error) {
      console.error("Error fetching token prices:", error);
      setUpdatedMockDataToken(
        mockDataToken.map((token) => ({ ...token, value: 0.0 }))
      );
    }
  };
  const [activeRoute, setActiveRoute] = useState("/");

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);
  const handleRouteChange = (route) => {
    setActiveRoute(route);
  };

  useEffect(() => {
    if (location.pathname === '/swap') {
      console.log("pathnamee",location.pathname)
      dispatch(saveLocation(`swap`));
    }
  }, []);

  const redirectToXbrApp = () => {
    try {
      window.open(process.env.REACT_APP_XBR_APP, "_blank");
    } catch (error) {
      console.error("Error in redirectToXbrApp", error);
    }
  };

  const formatBalanceForPrice = (balance) => {
    // Ensure the balance is treated as a number
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    } else {
      return num.toFixed(4).replace(/\.00$/, ""); // Output as a string with two decimal places
    }
  };

  function formatTbaPrice(price) {
    // Ensure the price is a number
    if (typeof price !== "number" || isNaN(price)) return "NaN";

    // Convert the price to a string in fixed-point notation to ensure precision
    let priceStr = price.toFixed(25); // Use enough precision to capture all significant digits

    // Find the index of the decimal point
    let decimalIndex = priceStr.indexOf(".") + 1;

    // Find the index of the first non-zero digit after the decimal point
    let firstNonZeroIndex = decimalIndex;
    while (
      priceStr[firstNonZeroIndex] === "0" &&
      firstNonZeroIndex < priceStr.length
    ) {
      firstNonZeroIndex++;
    }

    // Count the number of leading zeroes
    let zeroCount = firstNonZeroIndex - decimalIndex;

    // Get the significant digits part (adjust the length as needed)
    let significantPart = priceStr.substring(
      firstNonZeroIndex,
      firstNonZeroIndex + 3
    );

    // Construct the formatted string
    return `0.0(${zeroCount})${significantPart}`;
  }

  const fetchPrice = async (address) => {
    const URL = `${process.env.REACT_APP_API}/v1/xbr/token-prices?address=${address}`;
    const response = await axios.get(URL);
    // console.log("response", response.data.data.attributes.price_usd);

    return response?.data?.data?.attributes?.price_usd;
  };

  const getXBRAndTBA = async () => {
    const XBR = await fetchPrice("0xD44CaEBbF5C5a3848357eC05e24220BEBEF41d40");
    const TBA = await fetchPrice("0x7e1AE068Ac4e0ffD7a84750c2FB093A0EAE9aEa2");
    setXbrPrice(formatBalanceForPrice(XBR));
    setTbaPrice(formatTbaPrice(Number(TBA)));
  };

  const getusdtusdcPrice = async () => {
    const USDT = await fetchPrice("0xc2132D05D31c914a87C6611C10748AEb04B58e8F");
    const USDC = await fetchPrice("0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359");
    setUsdtPrice(formatBalanceForPrice(USDT));
    setusdcPrice(formatBalanceForPrice(USDC));
  };

  const getMaticPrice = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/v1/xbr/matic-price`
    );

    // Access the property using square brackets
    const usdPrice = await response.data.usd;
    console.log("Matic", usdPrice);
    setMaticPrice(formatBalanceForPrice(usdPrice));
  };

  useEffect(() => {
    getXBRAndTBA();
    getMaticPrice();
    getusdtusdcPrice();
  }, [setXbrPrice, setTbaPrice, setMaticPrice]);

  return (
    <>
      <header
        className={`border-b font-sans ${
          theme.palette.mode === "light"
            ? "border-[#69696F] bg-[#F7F7FE]"
            : "border-[#838A9A7A] bg-[#080628]"
        } top-0 z-0`}
        // style={{ backgroundColor: alt, borderBottomColor: line }}
      >
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleConfirmSwitch}
        />

        <div className="flex justify-between m-auto items-center py-[14px] px-6">
          {theme.palette.mode === "light" && (
            <div
              className="fixed top-0 right-0 h-[80px] w-[30vw] opacity-30 z-0"
              style={{
                background:
                  "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
                filter: "blur(190.72238159179688px)",
              }}
            ></div>
          )}
          {theme.palette.mode === "light" && (
            <div
              className="fixed top-0 left-0 h-[100px] w-[30vw] opacity-30 z-0"
              style={{
                background:
                  "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
                filter: "blur(190.72238159179688px)",
              }}
            ></div>
          )}
          {isMobile ? (
            <div className="flex items-center">
              <div className="flex gap-3 items-center cursor-pointer relative">
                <img src={Logo} alt="" className="w-11 h-8 relative" />
                <div className="flex">
                  {/* <span className="text-[10px] font-medium text-[#3840CD]">
                    BULL
                  </span>
                  <span className="text-[10px] font-medium text-[#3840CD]">
                    RUN
                  </span> */}
                  {isMobile && (
                    <div
                      ref={dropdownRef2}
                      style={{ backgroundColor: alt }}
                      className={`relative ${
                        isMobile
                          ? "block"
                          : "hidden lg:flex equal-width-dropdown"
                      }`}
                    >
                      {isMetaMaskConnected &&
                        localStorage.getItem("isWallet") && (
                          <div
                            className="flex items-center justify-between gap-[2px] rounded-md px-[3px] py-[5px]"
                            onClick={handleDropdownChange2}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid  #1890ff",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                              cursor:
                                currentChain !== process.env.REACT_APP_CHAIN_ID
                                  ? "pointer"
                                  : "default", // Apply pointer cursor if not Polygon mainnet or testnet chain
                            }}
                          >
                            <div
                              style={{ color: dark }}
                              className="flex items-center justify-center gap-2 px-[6px] dropdown-style text-white"
                            >
                              {currentChain ===
                              process.env.REACT_APP_CHAIN_ID ? (
                                <img
                                  src={selectedOption2Image}
                                  alt="Ethereum"
                                  className="w-5 h-5"
                                />
                              ) : (
                                <Tooltip
                                  title="Please select chain"
                                  placement="bottom"
                                >
                                  <WarningRoundedIcon className="warning-icon" />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center">
              {isWallet ? (
                <Link to="/">
                  <div className="flex flex-col items-center relative">
                    <img src={Logo} alt="" className="w-10 h-10 relative" />
                    <div className="flex items-center absolute top-[24px] left-[35px]">
                      <span className="text-[10px] font-medium text-[#3840CD]">
                        BULL
                      </span>
                      <span className="text-[10px] font-medium text-[#3840CD]">
                        RUN
                      </span>
                    </div>
                  </div>
                </Link>
              ) : (
                <Link to="/">
                  <div className="flex items-end relative">
                    <img src={Logo} alt="" className="w-11 h-8 relative" />
                    <div className="flex items-center">
                      <span className="text-[14px] leading-[10px] -translate-x-[10px] uppercase font-medium text-[#5763f3]">
                        BULL RUN
                      </span>
                    </div>
                  </div>
                </Link>
              )}
            </div>
          )}

          <div
            className={`flex items-center space-x-2 z-50 ${
              isMobile ? "justify-end" : ""
            }`}
          >
            {!isMobile && (
              <ul className="flex items-center space-x-4 mr-2">
                <li className="flex gap-2 items-center text-sm text-gray-500">
                  <span>TBA</span>
                  <span className="text-blue-500">
                    ${tbaPrice ? tbaPrice : "Loading..."}
                  </span>
                </li>
                <li className="flex gap-2 items-center text-sm text-gray-500">
                  <span>XBR</span>
                  <span className="text-blue-500">
                    ${xbrPrice ? xbrPrice : "Loading..."}
                  </span>
                </li>
                <li className="flex gap-2 items-center text-sm text-gray-500">
                  <span>MATIC</span>
                  <span className="text-blue-500">
                    $
                    {maticPrice
                      ? maticPrice < 1000
                        ? Number(maticPrice).toFixed(4)
                        : Number(maticPrice).toFixed(0)
                      : "Loading..."}
                  </span>
                </li>
                <li className="flex gap-2 items-center text-sm text-gray-500">
                  <span>USDT</span>
                  <span className="text-blue-500">
                    ${usdtPrice ? usdtPrice : "Loading..."}
                  </span>
                </li>
                <li className="flex gap-2 items-center text-sm text-gray-500">
                  <span>USDC</span>
                  <span className="text-blue-500">
                    ${usdcPrice ? usdcPrice : "Loading..."}
                  </span>
                </li>
                <style jsx>{`
                  ul {
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                  }
                `}</style>
              </ul>
            )}

            <div
              className={`flex items-center space-x-2 ${
                isMobile ? "justify-end" : ""
              }`}
            >
              {!isMobile && (
                <button
                  // disabled
                  className="flex items-center justify-center text-ft2 leading-[24.5px] w-16 h-[36.5px] px-3 py-[6px] border border-[#5763F3] rounded-lg  cursor-pointers font-medium tracking-wide"
                  style={{
                    backgroundColor: activeRoute === "/swap" ? "#5763F3" : "",
                    color: activeRoute === "/swap" ? "white" : "",
                  }}
                  onClick={() => handleNavigate("swap")}
                >
                  Swap
                </button>
              )}

              {!isMobile && (
                <button
                  className="flex items-center justify-center text-ft2 leading-[24.5px] w-16 h-[36.5px] px-3 py-[6px] border border-[#5763F3] rounded-lg  cursor-pointer font-medium tracking-wide"
                  style={{
                    backgroundColor: activeRoute === "/node" || activeRoute === "/" ? "#5763F3" : "",
                    color: activeRoute === "/node" || activeRoute === "/" ? "white" : "",
                  }}
                  onClick={() => handleNavigate("node")}
                >
                  Node
                </button>
              )}

              {!isMobile && (
                <a
                  href="https://pool.xbullrun.org"
                  className="flex items-center justify-center text-ft2 leading-[24.5px] w-16 h-[36.5px] px-3 py-[6px] border border-[#5763F3] rounded-lg  cursor-pointers font-medium tracking-wide"
                  // onClick={() => redirectToXbrApp()}
                  onMouseEnter={() => setIsHovered(true)} // Set isHovered to true on mouse enter
                  onMouseLeave={() => setIsHovered(false)} // Set isHovered to false on mouse leave
                >
                  Pool
                </a>
              )}

              {/* Dropdown 1 */}
              <div
                ref={dropdownRef}
                style={{ backgroundColor: alt }}
                className={`relative ${
                  isMobile ? "hidden" : "hidden equal-width-dropdown"
                }`}
              ></div>

              {/* Dropdown 2 */}
              <div
                ref={dropdownRef2}
                style={{ backgroundColor: alt }}
                className={`relative ${
                  isMobile ? "hidden" : "hidden equal-width-dropdown"
                }`}
              ></div>

              <div className="flex items-center">
                {isMobile && (
                  <button
                    className={`flex items-center gap-1 justify-center px-4 py-2 rounded-5-5xl transition ${
                      theme.palette.mode === "dark"
                        ? "text-white bg-[#5663F2]"
                        : "text-white bg-[#5663F2]"
                    }    md:border-2 text-ft1_5  md:px-4 md:py-1  `}
                    // onClick={handleWalletConnection}
                  >
                    {" "}
                    {isMetaMaskConnected &&
                    !localStorage.getItem("walletName") ? (
                      <Link to="/connect-wallet">
                        <span
                          className={`block md:hidden`}
                          // onClick={toggleDrawer1("right", true)}
                        >
                          Connect
                        </span>
                      </Link>
                    ) : (
                      <span
                        className={`block md:hidden text-white`}
                        // onClick={() => {
                        //   if (isMetaMaskConnected) {
                        //     openPopup();
                        //   } else {
                        //     handleConnectClick();
                        //   }
                        // }}
                        onClick={() => {
                          if (isWallet) {
                            console.log("disconnect wallet call");
                            openPopup();
                          } else {
                            handleConnectWalletPage();
                          }
                        }}
                      >
                        {storedWalletName === "Metamask" ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 83.625 77.599"
                          >
                            <g
                              id="MetaMask_Fox"
                              transform="translate(-31.3 -35)"
                            >
                              <path
                                id="Path_2444"
                                data-name="Path 2444"
                                d="M206.84,35.5,174.6,59.445l5.962-14.127Z"
                                transform="translate(-96.53 0)"
                                fill="#e2761b"
                                stroke="#e2761b"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2445"
                                data-name="Path 2445"
                                d="M37.3,35.5,69.283,59.672l-5.67-14.354ZM100.13,91,91.543,104.16l18.372,5.055L115.2,91.3ZM33.9,91.3l5.249,17.918,18.372-5.055L48.935,91Z"
                                transform="translate(-1.42 0)"
                                fill="#e4761b"
                                stroke="#e4761b"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2446"
                                data-name="Path 2446"
                                d="M92.92,114.676,87.8,122.42l18.242.81-.648-19.6Zm36.064,0L116.346,103.4l-.421,19.83,18.21-.81ZM93.956,150.059l10.952-5.346-9.461-7.388Zm23.038-5.346,10.984,5.346-1.523-12.734Z"
                                transform="translate(-37.855 -45.899)"
                                fill="#e4761b"
                                stroke="#e4761b"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2447"
                                data-name="Path 2447"
                                d="M140.822,236.246,129.838,230.9l.875,7.161-.1,3.013Zm-34.022,0,10.207,4.828-.065-3.013.81-7.161Z"
                                transform="translate(-50.698 -132.086)"
                                fill="#d7c1b3"
                                stroke="#d7c1b3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2448"
                                data-name="Path 2448"
                                d="M119.737,181.738l-9.137-2.689,6.448-2.949Zm13.252,0,2.689-5.638,6.48,2.949Z"
                                transform="translate(-53.267 -95.043)"
                                fill="#233447"
                                stroke="#233447"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2449"
                                data-name="Path 2449"
                                d="M88.887,189.739l1.555-13.155-10.142.292Zm32.467-13.155,1.555,13.155,8.587-12.864Zm7.712-14.484-18.21.81,1.685,9.364,2.689-5.638,6.48,2.949Zm-38.947,7.485,6.48-2.949,2.657,5.638,1.717-9.364L82.73,162.1Z"
                                transform="translate(-32.785 -85.579)"
                                fill="#cd6116"
                                stroke="#cd6116"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2450"
                                data-name="Path 2450"
                                d="M87.8,162.1,95.447,177l-.259-7.42Zm38.98,7.485-.324,7.42,7.679-14.9Zm-20.737-6.675-1.717,9.364,2.139,11.049.486-14.549Zm9.883,0-.875,5.832.389,14.581,2.171-11.049Z"
                                transform="translate(-37.855 -85.579)"
                                fill="#e4751f"
                                stroke="#e4751f"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2451"
                                data-name="Path 2451"
                                d="M133.022,187.889l-2.171,11.049,1.555,1.069,9.461-7.388.324-7.42ZM110.6,185.2l.259,7.42,9.461,7.388,1.555-1.069-2.139-11.049Z"
                                transform="translate(-53.267 -101.194)"
                                fill="#f6851b"
                                stroke="#f6851b"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2452"
                                data-name="Path 2452"
                                d="M130.616,252.228l.1-3.013-.81-.713H117.687l-.745.713.065,3.013L106.8,247.4l3.564,2.916,7.226,5.022H130l7.258-5.022,3.564-2.916Z"
                                transform="translate(-50.698 -143.24)"
                                fill="#c0ad9e"
                                stroke="#c0ad9e"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2453"
                                data-name="Path 2453"
                                d="M151,228.669l-1.555-1.069h-8.975l-1.555,1.069-.81,7.161.745-.713h12.216l.81.713Z"
                                transform="translate(-71.857 -129.856)"
                                fill="#161616"
                                stroke="#161616"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2454"
                                data-name="Path 2454"
                                d="M111.671,61l2.754-13.22L110.31,35.5,79.139,58.635,91.128,68.777l16.946,4.958,3.759-4.374-1.62-1.166,2.592-2.365L110.8,64.273l2.592-1.977ZM31.8,47.78,34.554,61,32.8,62.3,35.4,64.273,33.42,65.828l2.592,2.365-1.62,1.166,3.726,4.374,16.946-4.958L67.053,58.635,35.883,35.5Z"
                                transform="translate(0 0)"
                                fill="#763d16"
                                stroke="#763d16"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_2455"
                                data-name="Path 2455"
                                d="M109.494,94.217,92.548,89.259,97.7,97l-7.679,14.9,10.109-.13H115.2Zm-53.01-4.958L39.538,94.217,33.9,111.779H48.935l10.077.13L51.365,97ZM79.49,97.813l1.069-18.7L85.484,65.8H63.613l4.86,13.317,1.134,18.7.389,5.9.032,14.516H79l.065-14.516Z"
                                transform="translate(-1.42 -20.482)"
                                fill="#f6851b"
                                stroke="#f6851b"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                            </g>
                          </svg>
                        ) : storedWalletName === "Trust Wallet" ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 73.187 84.273"
                          >
                            <g
                              id="trust-wallet-token-twt-seeklogo"
                              transform="translate(-29.008 -23.711)"
                            >
                              <path
                                id="Path_2456"
                                data-name="Path 2456"
                                d="M513.1,369.641a4.286,4.286,0,0,1,4.28,4.341c-.205,12.266-.679,21.648-1.564,29.075-.862,7.427-2.16,12.944-4.115,17.408a27.668,27.668,0,0,1-4.852,7.552,37.787,37.787,0,0,1-8.724,6.709c-1.372.82-2.8,1.631-4.293,2.481-3.193,1.813-6.7,3.807-10.686,6.472a4.257,4.257,0,0,1-4.752,0c-4.043-2.694-7.6-4.714-10.818-6.538q-1.074-.609-2.1-1.2a45.69,45.69,0,0,1-10.12-7.262,25.9,25.9,0,0,1-5.043-7.242,50.637,50.637,0,0,1-4.029-14.445c-1.246-8.012-1.859-18.487-2.089-33.011a4.288,4.288,0,0,1,1.215-3.062,4.362,4.362,0,0,1,3.065-1.276h1.77c5.451.02,17.487-.514,27.9-8.618a4.294,4.294,0,0,1,5.247,0c10.409,8.1,22.445,8.645,27.923,8.618m-7.776,48.083a42.758,42.758,0,0,0,3.292-12.036c1.008-6.544,1.626-15.433,1.916-27.528-6.419-.186-17.448-1.42-27.962-8.5-10.514,7.057-21.542,8.288-27.942,8.5.227,10,.679,17.777,1.4,23.887.823,6.953,1.995,11.667,3.457,14.979A18.382,18.382,0,0,0,461,422.216a29.539,29.539,0,0,0,6.808,5.188c1.221.731,2.568,1.494,4.029,2.324,2.61,1.477,5.6,3.167,8.928,5.287,3.273-2.087,6.215-3.759,8.8-5.228.778-.442,1.521-.862,2.231-1.275a38.533,38.533,0,0,0,8.289-5.82,18.112,18.112,0,0,0,3.447-4.966"
                                transform="translate(-415.184 -336.42)"
                                fill={dark} // Pass the fill color dynamically
                                fillRule="evenodd"
                              />
                            </g>
                          </svg>
                        ) : storedWalletName === "Wallet Connect" ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 86.926 53.099"
                          >
                            <g
                              id="Layer_x0020_1"
                              transform="translate(-22.852 -38.824)"
                            >
                              <g
                                id="_2806050136896"
                                transform="translate(0.31 0.001)"
                              >
                                <path
                                  id="Path_2457"
                                  data-name="Path 2457"
                                  d="M446.051,744.629a36.98,36.98,0,0,1,51.376,0l1.7,1.644a1.76,1.76,0,0,1,0,2.492l-5.832,5.673a.866.866,0,0,1-1.272,0l-2.333-2.28a25.806,25.806,0,0,0-35.841,0l-2.492,2.439a.866.866,0,0,1-1.272,0l-5.832-5.673a1.76,1.76,0,0,1,0-2.492l1.856-1.8Zm63.412,11.77,5.2,5.09a1.76,1.76,0,0,1,0,2.492l-23.435,22.852a1.825,1.825,0,0,1-2.545,0l-16.648-16.224a.484.484,0,0,0-.636,0l-16.648,16.224a1.825,1.825,0,0,1-2.545,0l-23.435-22.852a1.76,1.76,0,0,1,0-2.492l5.2-5.09a1.824,1.824,0,0,1,2.545,0l16.648,16.224a.484.484,0,0,0,.636,0L470.441,756.4a1.824,1.824,0,0,1,2.545,0l16.648,16.224a.484.484,0,0,0,.636,0L506.918,756.4a1.824,1.824,0,0,1,2.545,0Z"
                                  transform="translate(-405.708 -695.426)"
                                  fill={dark}
                                />
                              </g>
                            </g>
                          </svg>
                        ) : (
                          <div className=" ">
                            {currentDisplay !== "Connect Wallet"
                              ? `${currentDisplay.slice(
                                  0,
                                  5
                                )}...${currentDisplay.slice(-5)}`
                              : currentDisplay}
                          </div> // If neither, display the current display
                        )}
                      </span>
                    )}
                  </button>
                )}

                {/* {isMobile && (
                  <SwipeableDrawer
                    anchor={"right"}
                    open={drawerState1["right"]}
                    onClose={toggleDrawer1("right", false)}
                    onOpen={toggleDrawer1("right", true)}
                  >
                    {list1("right")}
                  </SwipeableDrawer>
                )} */}

                {!isMobile && (
                  <button
                    className={`flex items-center justify-center text-ft2 leading-[24.5px] w-[132.5px] h-[36.5px] px-3 py-[6px] rounded-lg  cursor-pointers font-medium tracking-wide ${
                      theme.palette.mode === "dark"
                        ? "text-white bg-[#5663F2]"
                        : "text-white bg-[#5663F2]"
                    }`}
                    // onClick={handleWalletConnection}
                  >
                    <div>
                      {!localStorage.getItem("walletName") ? (
                        <span
                          className="hidden md:inline  text-ft2 font-semibold"
                          // onClick={handleConnectWalletClick}
                          onClick={handleConnectWalletPage}
                        >
                          Connect Wallet
                        </span>
                      ) : (
                        <span
                          className="hidden md:inline "
                          // onClick={() => {
                          //   if (isMetaMaskConnected) {
                          //     openPopup();
                          //   } else {
                          //     handleConnectClick();
                          //   }
                          // }}
                          onClick={() => {
                            if (isWallet) {
                              console.log("disconnect wallet call");
                              openPopup();
                            } else {
                              handleConnectWalletPage();
                            }
                          }}
                        >
                          {currentDisplay !== "Connect Wallet"
                            ? `${currentDisplay.slice(
                                0,
                                5
                              )}...${currentDisplay.slice(-5)}`
                            : currentDisplay}
                        </span>
                      )}

                      <SwipeableDrawer
                        anchor="right"
                        open={isDrawerOpen}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        BackdropProps={{
                          invisible: true,
                        }}
                        PaperProps={{
                          style: {
                            background: alt,
                            height: "100vh",
                          },
                        }}
                      >
                        <div
                          className="w-full md:w-[350px] flex flex-col gap-5 items-center md:mx-auto min-h-screen"
                          style={{ background: alt, minHeight: "100vh" }}
                        >
                          <div
                            className="bg-primary rounded-t-lg py-2 px-4 text-white text-lg mt-16 font-semibold"
                            style={{ color: dark }}
                          >
                            Connect a Wallet
                          </div>

                          {/* Render wallet items inside the drawer */}
                          <div className="flex flex-col gap-4 md:gap-10 mt-2">
                            {/* Render wallet items inside the drawer */}
                            <WalletItemComponent
                              icon={
                                <div className=" rounded-lg p-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="45"
                                    height="45"
                                    viewBox="0 0 83.625 77.599"
                                  >
                                    <g
                                      id="MetaMask_Fox"
                                      transform="translate(-31.3 -35)"
                                    >
                                      <path
                                        id="Path_2444"
                                        data-name="Path 2444"
                                        d="M206.84,35.5,174.6,59.445l5.962-14.127Z"
                                        transform="translate(-96.53 0)"
                                        fill="#e2761b"
                                        stroke="#e2761b"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2445"
                                        data-name="Path 2445"
                                        d="M37.3,35.5,69.283,59.672l-5.67-14.354ZM100.13,91,91.543,104.16l18.372,5.055L115.2,91.3ZM33.9,91.3l5.249,17.918,18.372-5.055L48.935,91Z"
                                        transform="translate(-1.42 0)"
                                        fill="#e4761b"
                                        stroke="#e4761b"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2446"
                                        data-name="Path 2446"
                                        d="M92.92,114.676,87.8,122.42l18.242.81-.648-19.6Zm36.064,0L116.346,103.4l-.421,19.83,18.21-.81ZM93.956,150.059l10.952-5.346-9.461-7.388Zm23.038-5.346,10.984,5.346-1.523-12.734Z"
                                        transform="translate(-37.855 -45.899)"
                                        fill="#e4761b"
                                        stroke="#e4761b"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2447"
                                        data-name="Path 2447"
                                        d="M140.822,236.246,129.838,230.9l.875,7.161-.1,3.013Zm-34.022,0,10.207,4.828-.065-3.013.81-7.161Z"
                                        transform="translate(-50.698 -132.086)"
                                        fill="#d7c1b3"
                                        stroke="#d7c1b3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2448"
                                        data-name="Path 2448"
                                        d="M119.737,181.738l-9.137-2.689,6.448-2.949Zm13.252,0,2.689-5.638,6.48,2.949Z"
                                        transform="translate(-53.267 -95.043)"
                                        fill="#233447"
                                        stroke="#233447"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2449"
                                        data-name="Path 2449"
                                        d="M88.887,189.739l1.555-13.155-10.142.292Zm32.467-13.155,1.555,13.155,8.587-12.864Zm7.712-14.484-18.21.81,1.685,9.364,2.689-5.638,6.48,2.949Zm-38.947,7.485,6.48-2.949,2.657,5.638,1.717-9.364L82.73,162.1Z"
                                        transform="translate(-32.785 -85.579)"
                                        fill="#cd6116"
                                        stroke="#cd6116"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2450"
                                        data-name="Path 2450"
                                        d="M87.8,162.1,95.447,177l-.259-7.42Zm38.98,7.485-.324,7.42,7.679-14.9Zm-20.737-6.675-1.717,9.364,2.139,11.049.486-14.549Zm9.883,0-.875,5.832.389,14.581,2.171-11.049Z"
                                        transform="translate(-37.855 -85.579)"
                                        fill="#e4751f"
                                        stroke="#e4751f"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2451"
                                        data-name="Path 2451"
                                        d="M133.022,187.889l-2.171,11.049,1.555,1.069,9.461-7.388.324-7.42ZM110.6,185.2l.259,7.42,9.461,7.388,1.555-1.069-2.139-11.049Z"
                                        transform="translate(-53.267 -101.194)"
                                        fill="#f6851b"
                                        stroke="#f6851b"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2452"
                                        data-name="Path 2452"
                                        d="M130.616,252.228l.1-3.013-.81-.713H117.687l-.745.713.065,3.013L106.8,247.4l3.564,2.916,7.226,5.022H130l7.258-5.022,3.564-2.916Z"
                                        transform="translate(-50.698 -143.24)"
                                        fill="#c0ad9e"
                                        stroke="#c0ad9e"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2453"
                                        data-name="Path 2453"
                                        d="M151,228.669l-1.555-1.069h-8.975l-1.555,1.069-.81,7.161.745-.713h12.216l.81.713Z"
                                        transform="translate(-71.857 -129.856)"
                                        fill="#161616"
                                        stroke="#161616"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2454"
                                        data-name="Path 2454"
                                        d="M111.671,61l2.754-13.22L110.31,35.5,79.139,58.635,91.128,68.777l16.946,4.958,3.759-4.374-1.62-1.166,2.592-2.365L110.8,64.273l2.592-1.977ZM31.8,47.78,34.554,61,32.8,62.3,35.4,64.273,33.42,65.828l2.592,2.365-1.62,1.166,3.726,4.374,16.946-4.958L67.053,58.635,35.883,35.5Z"
                                        transform="translate(0 0)"
                                        fill="#763d16"
                                        stroke="#763d16"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_2455"
                                        data-name="Path 2455"
                                        d="M109.494,94.217,92.548,89.259,97.7,97l-7.679,14.9,10.109-.13H115.2Zm-53.01-4.958L39.538,94.217,33.9,111.779H48.935l10.077.13L51.365,97ZM79.49,97.813l1.069-18.7L85.484,65.8H63.613l4.86,13.317,1.134,18.7.389,5.9.032,14.516H79l.065-14.516Z"
                                        transform="translate(-1.42 -20.482)"
                                        fill="#f6851b"
                                        stroke="#f6851b"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              }
                              text="Metamask"
                              onClick={handleWalletSelection}
                              walletName="Metamask"
                            />
                            <WalletItemComponent
                              icon={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="45"
                                  height="45"
                                  viewBox="0 0 73.187 84.273"
                                >
                                  <g
                                    id="trust-wallet-token-twt-seeklogo"
                                    transform="translate(-29.008 -23.711)"
                                  >
                                    <path
                                      id="Path_2456"
                                      data-name="Path 2456"
                                      d="M513.1,369.641a4.286,4.286,0,0,1,4.28,4.341c-.205,12.266-.679,21.648-1.564,29.075-.862,7.427-2.16,12.944-4.115,17.408a27.668,27.668,0,0,1-4.852,7.552,37.787,37.787,0,0,1-8.724,6.709c-1.372.82-2.8,1.631-4.293,2.481-3.193,1.813-6.7,3.807-10.686,6.472a4.257,4.257,0,0,1-4.752,0c-4.043-2.694-7.6-4.714-10.818-6.538q-1.074-.609-2.1-1.2a45.69,45.69,0,0,1-10.12-7.262,25.9,25.9,0,0,1-5.043-7.242,50.637,50.637,0,0,1-4.029-14.445c-1.246-8.012-1.859-18.487-2.089-33.011a4.288,4.288,0,0,1,1.215-3.062,4.362,4.362,0,0,1,3.065-1.276h1.77c5.451.02,17.487-.514,27.9-8.618a4.294,4.294,0,0,1,5.247,0c10.409,8.1,22.445,8.645,27.923,8.618m-7.776,48.083a42.758,42.758,0,0,0,3.292-12.036c1.008-6.544,1.626-15.433,1.916-27.528-6.419-.186-17.448-1.42-27.962-8.5-10.514,7.057-21.542,8.288-27.942,8.5.227,10,.679,17.777,1.4,23.887.823,6.953,1.995,11.667,3.457,14.979A18.382,18.382,0,0,0,461,422.216a29.539,29.539,0,0,0,6.808,5.188c1.221.731,2.568,1.494,4.029,2.324,2.61,1.477,5.6,3.167,8.928,5.287,3.273-2.087,6.215-3.759,8.8-5.228.778-.442,1.521-.862,2.231-1.275a38.533,38.533,0,0,0,8.289-5.82,18.112,18.112,0,0,0,3.447-4.966"
                                      transform="translate(-415.184 -336.42)"
                                      fill={dark}
                                      fillRule="evenodd"
                                    />
                                  </g>
                                </svg>
                              }
                              text="Trust Wallet"
                              onClick={handleWalletSelection}
                              // disabled={true}
                              walletName="TrustWallet"
                              // fillColor={dark} // Define the fill color dynamically
                            />

                            <WalletItemComponent
                              icon={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="45"
                                  height="45"
                                  viewBox="0 0 86.926 53.099"
                                >
                                  <g
                                    id="Layer_x0020_1"
                                    transform="translate(-22.852 -38.824)"
                                  >
                                    <g
                                      id="_2806050136896"
                                      transform="translate(0.31 0.001)"
                                    >
                                      <path
                                        id="Path_2457"
                                        data-name="Path 2457"
                                        d="M446.051,744.629a36.98,36.98,0,0,1,51.376,0l1.7,1.644a1.76,1.76,0,0,1,0,2.492l-5.832,5.673a.866.866,0,0,1-1.272,0l-2.333-2.28a25.806,25.806,0,0,0-35.841,0l-2.492,2.439a.866.866,0,0,1-1.272,0l-5.832-5.673a1.76,1.76,0,0,1,0-2.492l1.856-1.8Zm63.412,11.77,5.2,5.09a1.76,1.76,0,0,1,0,2.492l-23.435,22.852a1.825,1.825,0,0,1-2.545,0l-16.648-16.224a.484.484,0,0,0-.636,0l-16.648,16.224a1.825,1.825,0,0,1-2.545,0l-23.435-22.852a1.76,1.76,0,0,1,0-2.492l5.2-5.09a1.824,1.824,0,0,1,2.545,0l16.648,16.224a.484.484,0,0,0,.636,0L470.441,756.4a1.824,1.824,0,0,1,2.545,0l16.648,16.224a.484.484,0,0,0,.636,0L506.918,756.4a1.824,1.824,0,0,1,2.545,0Z"
                                        transform="translate(-405.708 -695.426)"
                                        fill={dark}
                                      />
                                    </g>
                                  </g>
                                </svg>
                              }
                              text="Connect Wallet"
                              onClick={handleWalletSelection}
                              walletName="Connect Wallet"
                              // disabled={true}
                            />
                            <WalletItemComponent
                              icon={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="45"
                                  height="45"
                                  viewBox="0 0 76.661 67.853"
                                >
                                  <g
                                    id="Group_1546"
                                    data-name="Group 1546"
                                    transform="translate(-2003.985 -1075.61)"
                                  >
                                    <path
                                      id="Path_2458"
                                      data-name="Path 2458"
                                      d="M94.189,86.783H35.182a4.081,4.081,0,0,1-4.077-4.077V39.573a1.689,1.689,0,0,1,3.378,0V82.706a.708.708,0,0,0,.7.7H94.189a.708.708,0,0,0,.7-.7V46.521a.708.708,0,0,0-.7-.7h-29.5a1.689,1.689,0,1,1,0-3.378h29.5a4.082,4.082,0,0,1,4.077,4.075V82.706a4.081,4.081,0,0,1-4.077,4.077"
                                      transform="translate(1981.38 1049.623)"
                                      fill={dark}
                                      stroke="#fff"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Path_2459"
                                      data-name="Path 2459"
                                      d="M87.826,46.564H37.354a6.249,6.249,0,0,1,0-12.5H63.331a1.689,1.689,0,0,1,0,3.378H37.354a2.871,2.871,0,1,0,0,5.742H87.826a2.871,2.871,0,0,0,0-5.742H78.658a1.689,1.689,0,1,1,0-3.378h9.168a6.249,6.249,0,0,1,0,12.5"
                                      transform="translate(1981.38 1048.883)"
                                      fill={dark}
                                      stroke="#fff"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Path_2460"
                                      data-name="Path 2460"
                                      d="M87.514,66.914h-6.47a6.249,6.249,0,0,1,0-12.5h6.47a1.689,1.689,0,0,1,0,3.378h-6.47a2.871,2.871,0,0,0,0,5.742h6.47a1.689,1.689,0,0,1,0,3.378"
                                      transform="translate(1989.845 1052.826)"
                                      fill={dark}
                                      stroke="#fff"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Path_2461"
                                      data-name="Path 2461"
                                      d="M42.1,47.592a1.689,1.689,0,0,1-.719-3.218L74.687,28.756,82.972,45.14a1.69,1.69,0,1,1-3.015,1.526l-6.8-13.46L42.812,47.432a1.685,1.685,0,0,1-.716.16"
                                      transform="translate(1983.182 1047.854)"
                                      fill={dark}
                                      stroke="#fff"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                    />
                                    <path
                                      id="Path_2462"
                                      data-name="Path 2462"
                                      d="M93.253,91.451l-11.2-19.4a5.286,5.286,0,0,0-9.157,0l-11.2,19.4a5.287,5.287,0,0,0,4.578,7.93h22.4a5.287,5.287,0,0,0,4.578-7.93"
                                      transform="translate(1943 1044.083)"
                                      fill="#ee7ae6"
                                    />
                                    <path
                                      id="Path_2463"
                                      data-name="Path 2463"
                                      d="M75.074,86.228a1.69,1.69,0,0,1-1.689-1.689V78.594a1.689,1.689,0,1,1,3.378,0v5.945a1.69,1.69,0,0,1-1.689,1.689"
                                      transform="translate(1945.403 1045.535)"
                                      fill="#fff"
                                    />
                                    <path
                                      id="Path_2464"
                                      data-name="Path 2464"
                                      d="M75.074,90.575a1.69,1.69,0,0,1-1.689-1.689v-.381a1.689,1.689,0,1,1,3.378,0v.381a1.69,1.69,0,0,1-1.689,1.689"
                                      transform="translate(1945.403 1047.455)"
                                      fill="#fff"
                                    />
                                  </g>
                                </svg>
                              }
                              text="Other Wallet"
                              // onClick={handleWalletSelection}
                              walletName="Other Wallet"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </SwipeableDrawer>
                    </div>
                    {/* <span className="md:hidden">M</span> */}
                  </button>
                )}

                {/* Mode Switch and Hamburger Icon */}
                <div className="flex items-center">
                  <IconButton onClick={handleModeChange}>
                    {currentMode === "dark" ? (
                      <LightMode
                        sx={{
                          color: theme.palette.background.dark,
                          fontSize: "25px",
                        }}
                      />
                    ) : (
                      <DarkMode sx={{ fontSize: "25px" }} />
                    )}
                  </IconButton>
                  {/* Hamburger Icon for Mobile */}
                  {isMobile && (
                    <MenuIcon
                      sx={{ cursor: "pointer" }}
                      onClick={toggleDrawer("left", true)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Disconnect Wallet Popup/Modal */}
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Background overlay */}
          <div
            className="fixed inset-0"
            style={{
              background: `${modalColor}80`,
              backdropFilter: "blur(5px)",
            }}
          ></div>
          {/* Modal container */}
          <div
            className="bg-white py-3 px-4 rounded-xl relative w-80 m-4"
            style={{ background: alt }}
          >
            {/* Header */}
            <div
              className="flex items-center justify-between bg-gray-100 rounded-t-lg p-2"
              style={{ background: alt }}
            >
              <h2
                className={`text-xl font-bold font-paragraph-p1-regular text-center w-full ${
                  theme.palette.mode === "light" ? "text-black" : "text-white"
                } `}
              >
                ACCOUNT
              </h2>
              <button
                className="text-[#5763F3] hover:text-red-700 focus:outline-none"
                onClick={() => setIsPopupOpen(false)}
                style={{ color: dark }}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div
              className={`border-t ${
                theme.palette.mode === "light"
                  ? "border-slate-500"
                  : "border-midnightblue-200"
              }`}
            ></div>
            {/* Contents */}
            <div className="p-8 flex flex-col gap-5 justify-center">
              {/* Connected wallet info */}
              <div className="flex items-center justify-center flex-col gap-2">
                <p
                  className="text-sm font-medium text-gray-600"
                  style={{ color: dark }}
                >
                  Connected Wallet:
                </p>
                <div
                  className={`${
                    theme.palette.mode === "light"
                      ? "bg-black"
                      : "bg-midnightblue-200"
                  } text-white px-4 w-full mx-auto text-center py-2 rounded-md relative`}
                >
                  <p className="text-[white] font-paragraph-p1-regular font-medium text-ft3">
                    {localStorage.getItem("walletName")
                      ? localStorage
                          .getItem("walletName")
                          .charAt(0)
                          .toUpperCase() +
                        localStorage.getItem("walletName").slice(1)
                      : "No wallet"}
                  </p>
                  <div className="absolute left-0 top-full w-0 h-0 border-t-6 border-l-6 border-transparent border-solid"></div>
                </div>
              </div>
              {/* Disconnect button */}
              {windowWidth < 768 ? (
                <button
                  className={`${
                    theme.palette.mode === "light"
                      ? "bg-red-800 hover:bg-opacity-70"
                      : "bg-red-800 hover:bg-opacity-70"
                  } w-50per mx-auto  rounded-xl  text-white px-4 py-2  `}
                  onClick={handleDisconnetMob}
                >
                  Disconnect
                </button>
              ) : (
                <button
                  className={`${
                    theme.palette.mode === "light"
                      ? "bg-red-800 hover:bg-opacity-70"
                      : "bg-red-800 hover:bg-opacity-70"
                  } w-50per  rounded-xl mx-auto  text-white px-2 py-2  `}
                  onClick={handleDisconnect}
                >
                  Disconnect
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <SwipeableDrawer
        anchor={"left"}
        open={drawerState["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {list("left")}
      </SwipeableDrawer>
    </>
  );
};

export default Navbar;
