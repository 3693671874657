import React, { forwardRef, useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { MdAdd } from "react-icons/md";
import {
  InRange,
  USDTBorder,
  XBRBorder,
  OutofRange,
  TBABorder,
} from "../assets";
import { useNavigate } from "react-router-dom";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { getPositionIds } from "../uniswap/position";
import { getLiquidity } from "../uniswap/liquidity";
import { XBRimg, USDTimg, tba } from "../assets";
import MyNftShimmer from "../components/LoadingShimmer/MyNftShimmer";
import NoNFT from "../assets/inbox.svg";
import { setRefreshNftId } from "../redux/fetchData/refreshNFTId";
import { useDispatch, useSelector } from "react-redux";

import { isAddress } from "ethers";
const MyNFTs = forwardRef((props, ref) => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";
  const navigate = useNavigate();
  const handleNFTClick = (nftId) => {
    navigate(`/nft-details/${nftId}`);
  };
  const { walletProvider } = useWeb3ModalProvider();
  const connectedAccount = localStorage.getItem("connectedAccount");
  const [positionIds, setPositionIds] = useState([]);
  const [liquidityData, setLiquidityData] = useState({});
  const [fetchingPositions, setIsFetchingPositions] = useState(true);
  const dispatch = useDispatch();
  const refreshNFTId = useSelector((state) => state.refreshNftId.refreshNftId);
  const tokenImageMap = {
    XBR: XBRBorder,
    USDT: USDTBorder,
    TBA: TBABorder,
  };
  const fetchPositionIds = async () => {
    try {
      const isConnected = localStorage.getItem("connectedAccount") !== null;
      if (!isConnected) {
        setPositionIds([]);
        return;
      }
      const positions = await getPositionIds(walletProvider);
      const reversedPositions = positions.reverse();
      setPositionIds(reversedPositions);
      console.log("positionids", positions);
    } catch (error) {
      console.error("Error fetching positionIds:", error);
    }
    setIsFetchingPositions(false);
  };
  useEffect(() => {
    fetchPositionIds();
  }, [connectedAccount, walletProvider]);

  useEffect(() => {
    if (refreshNFTId) {
      fetchPositionIds();
      dispatch(setRefreshNftId(false));
    }
  }, [refreshNFTId]);

  const formatBalanceForPrice = (balance) => {
    const epsilon = 1e-6;
    const infinityThreshold = 1e20;

    if (balance < epsilon) {
      return "0";
    }
    if (balance > infinityThreshold) {
      return "∞";
    }
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return num.toFixed(4).replace(/\.?0+$/, "");
    }
  };

  const getLiquidityData = async (option) => {
    
    const { token0, token1, fee, tickLower, tickUpper, currentTick } =
      await getLiquidity(Number(option), walletProvider);

    const token0LiquidityAmount = token0.amount;
    const token1LiquidityAmount = token1.amount;

    const token0Name = token0.symbol;
    const token1Name = token1.symbol;

    const currentfee = fee / 10000;

    const tickToPrice = (tick) => {
      return Math.pow(1.0001, tick);
    };
    const decimalAdjustmentFactor = Math.pow(
      10,
      token1.decimals - token0.decimals
    );
    const minPrice = tickToPrice(tickLower) / decimalAdjustmentFactor;
    const maxPrice = tickToPrice(tickUpper) / decimalAdjustmentFactor;
    const minPrice1 = 1 / maxPrice;
    const maxPrice1 = 1 / minPrice;
    setLiquidityData((prevData) => ({
      ...prevData,
      [option]: {
        token0LiquidityAmount,
        token1LiquidityAmount,
        token0Name,
        token1Name,
        currentfee,
        minPrice,
        maxPrice,
        minPrice1,
        maxPrice1,
      },
    }));
  };

  useEffect(() => {
    if (!positionIds.length) return;
    positionIds.forEach(async (option) => {
      await getLiquidityData(option);
    });
  }, [positionIds]);

  const handleAddLiquidityClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      ref={ref}
      className={`max-w-[1280px] py-6 px-2 sm:p-16 mt-16 rounded-[32px] flex flex-col gap-6 sm:gap-16 relative overflow-hidden`}
    >
      {isLightMode ? (
        <>
          {/* First Gradient */}
          <div
            className="absolute hidden sm:block -left-[146px] -top-[218px] w-[564px] h-[782px] opacity-20 blur-[190px] z-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
            }}
          />

          {/* Second Gradient */}
          <div
            className="absolute hidden sm:block -right-[298.438px] -bottom-[240.232px] w-[564px] h-[782px] opacity-20 blur-[190px] z-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
            }}
          />

          <div
            className="absolute sm:hidden -right-[340.438px] -bottom-[125.231px] w-[564px] h-[782px] opacity-20 blur-[190px] z-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
            }}
          />
        </>
      ) : (
        <>
          {/* First Gradient */}
          <div
            className="absolute hidden sm:block -left-[146px] -top-[218px] w-[564px] h-[782px] opacity-20 blur-[190px] z-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
            }}
          />

          {/* Second Gradient */}
          <div
            className="absolute hidden sm:block -right-[298.438px] -bottom-[240.232px] w-[564px] h-[782px] opacity-20 blur-[190px] z-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
            }}
          />
          <div
            className="absolute sm:hidden -right-[340.438px] -bottom-[125.231px] w-[564px] h-[782px] opacity-20 blur-[190px] z-0"
            style={{
              background:
                "linear-gradient(179deg, #5397FF 19.36%, #3A3FCF 37.58%, #8419FE 59.38%, #FF81F2 76.99%)",
            }}
          />
        </>
      )}
      <div className="flex justify-between items-center w-full z-10 px-4">
        <div
          className={`font-bold text-[20px] sm:text-[25px] leading-[150%] ${
            isLightMode ? "text-[#56565D]" : "text-white"
          }`}
        >
          My NFTs {positionIds ? `(${positionIds.length})` : null}
        </div>
        <button
          className={`px-6 py-3 flex justify-center items-center gap-1 text-base text-white leading-6 font-semibold rounded-[12px] bg-[#5663F2]`}
          onClick={handleAddLiquidityClick}
        >
          <MdAdd className="w-6 h-6" /> <span>Add liquidity</span>
        </button>
      </div>
      {connectedAccount && (
        <div
          className={`flex flex-col gap-6 sm:gap-2 max-h-[548px] z-10 overflow-y-auto custom-scrollbar ${
            isLightMode ? "light-mode" : ""
          }`}
        >
          {fetchingPositions ? (
            Array.from({ length: 3 }).map((_, index) => (
              <MyNftShimmer key={index} />
            ))
          ) : (
            <>
              {positionIds.length > 0 ? (
                <>
                  {" "}
                  {positionIds.map(
                    (option, index) =>
                      liquidityData[option] && (
                        <div
                          key={index}
                          onClick={() => handleNFTClick(option)}
                          className={`flex flex-col gap-4 p-4 sm:p-6 mr-2 rounded-[16px] hover:transition-colors duration-300 cursor-pointer ${
                            isLightMode
                              ? "hover:bg-[#FFFFFF]"
                              : "hover:bg-[#02042C]"
                          }`}
                        >
                          <div className="flex flex-col-reverse sm:flex-row justify-between sm:items-center gap-3">
                            <div className="flex justify-between sm:justify-normal gap-2 items-center">
                              <div
                                className={`pl-2 pr-4 py-2 rounded-[8px] ${
                                  isLightMode ? "bg-[#EEEFFE]" : "bg-[#14174F]"
                                }`}
                              >
                                <div className="flex items-center">
                                  <img
                                    src={
                                      tokenImageMap[
                                        liquidityData[option].token0Name
                                      ]
                                    }
                                    alt="USDT"
                                    className="w-6 h-6"
                                  />
                                  <img
                                    src={
                                      tokenImageMap[
                                        liquidityData[option].token1Name
                                      ]
                                    }
                                    alt="XBR"
                                    className="-ml-2 w-6 h-6"
                                  />
                                  <span className="text-[18px] font-semibold leading-[150%]">
                                    {liquidityData[option].token0Name} /{" "}
                                    {liquidityData[option].token1Name}
                                  </span>
                                </div>
                              </div>
                              <div
                                className={`text-base leading-6 ${
                                  isLightMode
                                    ? "text-[#34343C]"
                                    : "text-[#97979B]"
                                }`}
                              >
                                {liquidityData[option].currentfee}%
                              </div>
                            </div>
                            <div className="flex gap-[6px] items-center">
                              <img
                                src={
                                  liquidityData[option].token0LiquidityAmount >
                                    0 &&
                                  liquidityData[option].token1LiquidityAmount >
                                    0
                                    ? InRange
                                    : OutofRange
                                }
                                alt={
                                  liquidityData[option].token0LiquidityAmount >
                                    0 &&
                                  liquidityData[option].token1LiquidityAmount >
                                    0
                                    ? "In Range"
                                    : "Out of Range"
                                }
                                className="w-4 h-4"
                              />
                              <span
                                className={`text-base leading-6 ${
                                  liquidityData[option].token0LiquidityAmount >
                                    0 &&
                                  liquidityData[option].token1LiquidityAmount >
                                    0
                                    ? "text-[#57D24C]"
                                    : "text-red-500"
                                }`}
                              >
                                {liquidityData[option].token0LiquidityAmount >
                                  0 &&
                                liquidityData[option].token1LiquidityAmount > 0
                                  ? "In Range"
                                  : "Out of Range"}
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-4 text-[14px] sm:text-base leading-6">
                            <div>
                              <span className="text-[#97979B]">Min:</span>
                              <span
                                className={
                                  isLightMode
                                    ? "text-[#56565D]"
                                    : "text-[#F1F1F2]"
                                }
                              >
                                {formatBalanceForPrice(
                                  liquidityData[option].minPrice1
                                )}{" "}
                                {liquidityData[option].token0Name} per{" "}
                                {liquidityData[option].token1Name}
                              </span>
                            </div>
                            <div>
                              <span
                                className={
                                  isLightMode
                                    ? "text-[#69696F]"
                                    : "text-[#97979B]"
                                }
                              >
                                Max:
                              </span>
                              <span
                                className={isLightMode ? "" : "text-[#F1F1F2]"}
                              >
                                {formatBalanceForPrice(
                                  liquidityData[option].maxPrice1
                                )}{" "}
                                {liquidityData[option].token0Name} per{" "}
                                {liquidityData[option].token1Name}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </>
              ) : (
                <>
                  <div className="flex flex-col gap-4 justify-center items-center">
                    <img src={NoNFT} alt="noNFT" className="h-12 w-12" />
                    <p
                      className={`text-base leading-6 ${
                        isLightMode ? "text-[#56565D]" : "text-[#DCDCDE]"
                      }`}
                    >
                      Your active NFTs will appear here.
                    </p>
                  </div>
                </>
              )}{" "}
            </>
          )}
        </div>
      )}
    </div>
  );
});

export default MyNFTs;
