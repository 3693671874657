import { useTheme } from "@mui/material";
import { BiLoaderAlt } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import Success from "../assets/Success.svg";
import Failed from "../assets/Failed.svg";

const LPFarmSuccessModal = ({ onClose, type, message, amount, position }) => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";
  return (
    <div className="fixed flex items-center justify-center z-50 font-inter inset-0 bg-black bg-opacity-40">
      <div
        className={`w-[400px] m-2 p-6 rounded-[24px] relative ${
          isLightMode ? "bg-white" : "bg-[#02042C]"
        }`}
      >
        <MdClose
          className="absolute right-6 top-6 flex items-center w-6 h-6 cursor-pointer"
          onClick={onClose}
        />
        <div className="py-12 flex flex-col justify-center items-center gap-1">
          <img
            src={`${type === "success" ? Success : Failed}`}
            className="mx-auto h-10 w-10 mb-1"
            alt={`${type === "success" ? "Success" : "Failed"}`}
          />
          <h3
            className={`text-[20px] font-bold leading-[150%] ${
              isLightMode ? "text-[#181821]" : "text-[#FFFFFF]"
            }`}
          >
            {message}
          </h3>
          <p
            className={`text-base leading-6 ${
              isLightMode ? "text-[#69696F]" : "text-[#97979B]"
            }`}
          >
            {amount[position]} LPT
          </p>
        </div>
      </div>
    </div>
  );
};

export default LPFarmSuccessModal;
