import { useTheme } from "@mui/material";
import { BiLoaderAlt } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { useState } from "react";

const LiquidityConfirmationModal = ({ onClose,text }) => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div
        className={`relative w-[95vw] sm:w-[450px] border ${
          isLightMode
            ? "bg-white border-[#959DF7]"
            : "bg-[#02042C] border-[#21246A]"
        } pt-16 px-6 pb-6 rounded-[24px] flex flex-col justify-center items-center gap-8`}
      >
        
        <IoClose
          className="absolute top-4 right-4 w-6 h-6 cursor-pointer"
          onClick={onClose}
        />
        {true && (
            <BiLoaderAlt className="animate-spin text-[#5763F3] h-20 w-20" />
          )}
        <div className="flex flex-col gap-4 justify-center items-center">
          
          <h3
            className={`text-[24px] leading-[30px] font-semibold ${
              isLightMode ? "text-[#34343C]" : "text-[#DCDCDE]"
            }`}
          >
            Waiting for Confirmation
          </h3>
          <h4 className={`text-[18px] leading-[30px] font-semibold ${
              isLightMode ? "text-[#34343C]" : "text-[#DCDCDE]"
            }`}>{text}</h4>
            <span
            className={`text-base leading-6 ${
              isLightMode ? "text-[#69696F]" : "text-[#97979B]"
            }`}
          >
            Confirm this transaction in your wallet
          </span>
        </div>
      </div>
    </div>
  );
};

export default LiquidityConfirmationModal;
