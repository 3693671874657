import { BrowserProvider, Contract } from "ethers";
import IUniswapV3PoolABI from "@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json";
import { connectWallet, subscribeToProviders , getProviders} from "./farm";

// Uniswap V3 Factory ABI
const factoryAbi = [
  "function getPool(address tokenA, address tokenB, uint24 fee) external view returns (address)",
];

const feeTiers = [100, 500, 3000, 10000];

export async function getPoolInfo(poolAddress, walletProvider) {
  let signer;
  if (walletProvider) {
    const ethersProvider = new BrowserProvider(walletProvider);
    signer = await ethersProvider?.getSigner();
  } else {
    console.log("connecting without walletConnect!");
    signer = (await connectWallet()).signer;
  }

  if (!signer) {
    console.warn("No signer available");
  }

  const poolContract = new Contract(
    poolAddress,
    IUniswapV3PoolABI.abi,
    signer
  );

  const [token0, token1, fee, tickSpacing, liquidity, slot0] =
    await Promise.allSettled([
      poolContract.token0(),
      poolContract.token1(),
      poolContract.fee(),
      poolContract.tickSpacing(),
      poolContract.liquidity(),
      poolContract.slot0(),
    ]);

  // let data = {
  //   token0: token0.value,
  //   token1: token1.value,
  //   fee: fee.value,
  //   tickSpacing: tickSpacing.value,
  //   liquidity: liquidity.value,
  //   tick: slot0.value[1],
  //   sqrtPriceX96: slot0.value[0],
  // };
  // console.log("Pool info:", data);

  return {
    // token0,
    // token1,
    // fee,
    // tickSpacing,
    // liquidity,
    // sqrtPriceX96: slot0.value[0],
    // tick: slot0.value[1],
    token0: token0.value,
    token1: token1.value,
    fee: parseInt(fee.value),
    tickSpacing: parseInt(tickSpacing.value),
    liquidity: liquidity.value,
    tick: parseInt(slot0.value[1]),
    sqrtPriceX96: slot0.value[0],
  };
}

export async function findPoolAddress(token0, token1, walletProvider) {
  try {
    // Uniswap V3 Factory address
    const factoryAddress = "0x1F98431c8aD98523631AE4a59f267346ea31F984";

    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      signer = (await connectWallet()).signer;
      await subscribeToProviders();
      const providers =  await getProviders();
      const wallet = localStorage.getItem("walletName");
  
      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("executeTrade-connectWallet", providerWithInfo);

      provider = new BrowserProvider(providerWithInfo?.provider);
    }

    const factoryContract = new Contract(
      factoryAddress,
      factoryAbi,
      signer
    );
    // Make sure token0 is lexicographically smaller than token1
    const sortedTokens = [token0, token1].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );

    // Loop through all fee tiers and find the pool
    for (const fee of feeTiers) {
      const poolAddress = await factoryContract.getPool(
        sortedTokens[0],
        sortedTokens[1],
        fee
      );
      if (poolAddress !== "0x0000000000000000000000000000000000000000") {
        return { poolAddress, fee };
      }
    }

    return { poolAddress: null, fee: null };
  } catch (error) {
    console.log("Error at finding pool address:", error);
  }
}
