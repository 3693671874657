import { useTheme } from "@mui/material";
import React, { useState } from "react";
import { layer } from "../assets";
import tabBgVideo from "../assets/SwapbgVideo.mp4";
import DepositLiquidity from "./DepositLiquidity";
import ClaimFarmRewards from "./ClaimFarmRewards";
import PathToSuccess from "./PathToSuccess";
import LatestTrades from "./LatestTrades";
import FarmingDetails from "./FarmingDetails";
import FarmingTrades from "./FarmingTrades";

const NewLPFarmComponent = (props) => {
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState(1);
  const { setMainActiveSection } = props;

  const handleSectionClick = (sectionNumber) => {
    setActiveSection(sectionNumber);
  };

  return (
    <div className="w-full">
      <div
        className="relative max-w-[30.5rem] mx-auto px-2 py-4 sm:p-4"
        style={{
          background:
            theme.palette.mode === "light"
              ? "linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)"
              : "linear-gradient(180deg, #050840 0%, rgba(5, 8, 64, 0.00) 100%)",
        }}
      >
        <div className={`relative w-full`}>
          <div
            className={`relative w-full ${
              theme.palette.mode === "light" ? "bg-white" : ""
            } mobile:rounded-xl  rounded-3xl flex flex-col  mobile:p-2 p-4 gap-4`}
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              className="absolute top-0 left-0 w-full h-full object-cover mobile:rounded-xl rounded-3xl z-0 opacity-30"
              src={tabBgVideo}
            >
              Your browser does not support the video tag.
            </video>

            <div className="flex flex-col font-inter mx-auto z-10">
              {/* Tab Section */}
              <div
                className={`flex flex-row items-center justify-center box-border text-white p-2 rounded-r-full rounded-l-full ${
                  theme.palette.mode === "light" ? "bg-white" : "bg-[#050840]"
                }`}
              >
                {/* Tab buttons */}
                <div
                  className={`rounded-lg text-[16px] flex flex-row items-center justify-between box-border text-white  m-auto gap-3`}
                >
                  <button
                    className={`flex-1 rounded-[24px] flex flex-row items-center justify-center py-1 px-4 cursor-pointer ${
                      activeSection === 1
                        ? theme.palette.mode === "light"
                          ? "bg-[#EEEFFE] text-[#4954CE] font-semibold"
                          : "bg-[#181B56]"
                        : theme.palette.mode === "light"
                        ? "text-mediumslateblue-600 font-normal"
                        : ""
                    }`}
                    onClick={() => handleSectionClick(1)}
                  >
                    Deposit
                  </button>

                  <button
                    className={` rounded-[24px] flex flex-row items-center justify-center py-1 px-4 cursor-pointer ${
                      activeSection === 2
                        ? theme.palette.mode === "light"
                          ? "bg-[#EEEFFE] text-[#4954CE] font-semibold"
                          : "bg-[#181B56]"
                        : theme.palette.mode === "light"
                        ? "text-mediumslateblue-600 font-normal"
                        : ""
                    }`}
                    onClick={() => handleSectionClick(2)}
                  >
                    Claim rewards
                  </button>
                </div>
              </div>

              {/* {activeSection === 2 && (
            <NewLiquidityComponentContent activeSection={2} />
          )} */}
            </div>
            <div className="w-full">
              {activeSection === 1 && (
                <DepositLiquidity
                  activeSection={1}
                  setMainActiveSection={setMainActiveSection}
                />
              )}
              {activeSection === 2 && (
                <ClaimFarmRewards
                  activeSection={2}
                  setActiveSection={setActiveSection}
                />
              )}
            </div>
          </div>
          <div className="flex-1 flex flex-row items-baseline justify-center gap-[0.25rem] mt-6 text-lightslategray-300">
            <div className="w-[1.5rem] h-[1.5rem] flex flex-row items-center justify-center">
              <img
                className="w-[1.063rem] !m-[0] top-[0rem] left-[0.188rem] h-[1.25rem] overflow-hidden shrink-0 z-[0]"
                alt="layer"
                src={layer}
              />
            </div>
            <div className="w-[5.625rem] text-ft5 inline-block h-[1.5rem] shrink-0">{`Powered by `}</div>
            <b className="w-[4.813rem] text-ft5 uppercase inline-block h-[1.5rem] shrink-0">
              Uniswap
            </b>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col lg:flex-row justify-center items-center gap-6 mt-20">
        <FarmingDetails
          fdv={`1.8M`}
          liquidity={`21.3k`}
          holdersCount={`285.000`}
          totalSupply={`9.9k`}
          selectedToken="ETH"
          selectedToken2="BTC"
        />

        <FarmingTrades
          activeTab={Math.floor(Math.random() * 5)}
          connectedAccount={`0x${Math.random()
            .toString(16)
            .substr(2, 8)}...${Math.random().toString(16).substr(2, 4)}`}
          selectedToken="USDT"
          selectedToken2="XRP"
          swapConfirmModal={Boolean(Math.round(Math.random()))}
        />
      </div>
    </div>
  );
};

export default NewLPFarmComponent;
