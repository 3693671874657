import { findPoolAddress } from "./pool";
import { Pool, Position } from "@uniswap/v3-sdk";
import { Token } from "@uniswap/sdk-core";
import { getProviders, subscribeToProviders } from "./farm";
import {BrowserProvider, Contract } from "ethers";

const positionManagerAddress = "0xC36442b4a4522E871399CD717aBDD847Ab11FE88";

// NonfungiblePositionManager ABI
const positionManagerAbi = [
  "function positions(uint256 tokenId) view returns (" +
    "uint96 nonce, address operator, address token0, address token1, uint24 fee, " +
    "int24 tickLower, int24 tickUpper, uint128 liquidity, " +
    "uint256 feeGrowthInside0LastX128, uint256 feeGrowthInside1LastX128, " +
    "uint128 tokensOwed0, uint128 tokensOwed1)",
];

const erc20Abi = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function decimals() view returns (uint8)",
];

// Uniswap V3 Pool ABI
const poolAbi = [
  "function slot0() external view returns (uint160 sqrtPriceX96, int24 tick, uint16 observationIndex, uint16 observationCardinality, uint16 observationCardinalityNext, uint8 feeProtocol, bool unlocked)",
  "function liquidity() external view returns (uint128)",
];

export async function fetchTokenObject(tokenAddress, signer) {
  const tokenContract = new Contract(tokenAddress, erc20Abi, signer);

  // Fetch token details
  const [name, symbol, decimals] = await Promise.all([
    tokenContract.name(),
    tokenContract.symbol(),
    tokenContract.decimals(),
  ]);

  // Create a Token object
  return new Token(137, tokenAddress, parseInt(decimals), symbol, name);
}

export async function getLiquidity(tokenId, walletProvider) {
  try {
    let signer;
    let provider;
    if (walletProvider) {
      console.log("Connected to Wallet Connect", "Provider", walletProvider);
      provider = new BrowserProvider(walletProvider);
      signer = await provider?.getSigner();
    } else {
      console.log("connecting without walletConnect!");
      await subscribeToProviders();
      const providers =  await getProviders();
      const wallet = localStorage.getItem("walletName");
  
      const providerWithInfo = await providers.find(
        (provider) => provider.info.name === wallet
      );
      console.log("getLiquidity", providerWithInfo);
      provider = new BrowserProvider(providerWithInfo?.provider);
      signer = await provider?.getSigner();
    }

    // Fetch the position data
    const positionManagerContract = new Contract(
      positionManagerAddress,
      positionManagerAbi,
      signer
    );

    // Fetch the position
    const position = await positionManagerContract.positions(tokenId);
    const { liquidity, tickLower, tickUpper, fee, token0, token1 } = position;
    console.log(
      "liquidity",
      liquidity.toString(),
      "tickLower",
      tickLower,
      "tickUpper",
      tickUpper,
      "fee",
      fee
    );

    // find pool address
    console.log("token0", token0, "token1", token1);
    const { poolAddress } = await findPoolAddress(token0, token1,walletProvider);
    console.log("poolAddress", poolAddress);
    const poolContract = new Contract(poolAddress, poolAbi, signer);

    // Fetch pool data
    const [sqrtPriceX96, currentTick, , , , ,] = await poolContract.slot0();
    console.log(
      "sqrtPriceX96",
      sqrtPriceX96.toString(),
      "currentTick",
      currentTick
    );

    const TOKEN0 = await fetchTokenObject(token0, signer);
    const TOKEN1 = await fetchTokenObject(token1, signer);
    //console.log("TOKEN0", TOKEN0, "TOKEN1", TOKEN1);

    // Create a pool instance
    const pool = new Pool(
      TOKEN0,
      TOKEN1,
      parseInt(fee),
      sqrtPriceX96.toString(),
      liquidity.toString(),
      parseInt(currentTick)
    );

    // Create a position instance
    const positionObj = new Position({
      pool,
      liquidity: liquidity.toString(),
      tickLower: parseInt(tickLower),
      tickUpper: parseInt(tickUpper),
    });

    // Calculate amount0 and amount1 using the TickMath-adjusted sqrt ratios
    const amount0 = positionObj.amount0.toSignificant(
      positionObj.amount0.currency.decimals
    );
    const amount1 = positionObj.amount1.toSignificant(
      positionObj.amount1.currency.decimals
    );

    let liquidityData = {
      liquidity: liquidity.toString(),
      fee: fee.toString(),
      tickLower: tickLower.toString(),
      tickUpper: tickUpper.toString(),
      currentTick: currentTick.toString(),
      token0: {
        address: token0,
        name: TOKEN0.name,
        symbol: TOKEN0.symbol,
        decimals: TOKEN0.decimals,
        amount: parseFloat(amount0).toFixed(8),
      },
      token1: {
        address: token1,
        name: TOKEN1.name,
        symbol: TOKEN1.symbol,
        decimals: TOKEN1.decimals,
        amount: parseFloat(amount1).toFixed(8),
      },
    };

    return {
      liquidity: liquidity.toString(),
      fee: fee.toString(),
      tickLower: tickLower.toString(),
      tickUpper: tickUpper.toString(),
      currentTick: currentTick.toString(),
      token0: {
        address: token0,
        name: TOKEN0.name,
        symbol: TOKEN0.symbol,
        decimals: TOKEN0.decimals,
        amount: parseFloat(amount0).toFixed(8),
      },
      token1: {
        address: token1,
        name: TOKEN1.name,
        symbol: TOKEN1.symbol,
        decimals: TOKEN1.decimals,
        amount: parseFloat(amount1).toFixed(8),
      },
      poolAddress,
    };
  } catch (error) {
    console.error(error);
  }
}
