import { useTheme } from "@mui/material";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import {
  getPositionIds,
  removeLiquidity,
  removeLiquiditynew,
} from "../uniswap/position";
import { NavLink } from "react-router-dom";
import {
  XBR,
  USDT,
  LightmodeUSDT,
  LightmodeXBR,
  USDTimg,
  XBRimg,
  tba,
} from "../assets";
import { getLiquidity } from "../uniswap/liquidity.js";
import LiquidityConfirmationModal from "./LiquidityConfirmationModal";
import LiquidityConfirmedModal from "./LiquidityConfirmedModal";
import { BiLoaderAlt } from "react-icons/bi";
import { useRef } from "react";
import tabBgVideo from "../assets/SwapbgVideo.mp4";
import { MdClose } from "react-icons/md";
import FailedTransaction from "./FailedTransaction.jsx";

const RemoveLiquidity = (props) => {
  const { walletProvider } = useWeb3ModalProvider();
  const theme = useTheme();
  const connectedAccount = localStorage.getItem("connectedAccount");
  const [positionIds, setPositionIds] = useState([]);
  const { nftId, onClose, setIsLiquidityChanged, setRemoveLiquidity } = props;

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(nftId);
  const dropdownRef = useRef(null);
  const [removeLiquidityValue, setRemoveLiquidityValue] = useState(0);
  const [removeToken0Liquidity, setRemoveToken0Liquidity] = useState(0);
  const [removeToken1Liquidity, setRemoveToken1Liquidity] = useState(0);
  const [token0LiquidityAmount, setToken0LiquidityAmount] = useState("0.00");
  const [token1LiquidityAmount, setToken1LiquidityAmount] = useState("0.00");
  const [token0Address, setToken0Address] = useState("");
  const [token1Address, setToken1Address] = useState("");
  const [liquidityData, setLiquidityData] = useState({});
  const [token0Image, setToken0Image] = useState(USDTimg);
  const [token1Image, setToken1Image] = useState(XBRimg);
  const [token0Name, setToken0Name] = useState("USDT");
  const [token1Name, setToken1Name] = useState("XBR");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isRemoveConfirmed, setIsRemoveConfirmed] = useState(false);
  const [isFetchingPositions, setIsFetchingPositions] = useState(false);
  const [isTransactionFailed, setIsTransactionFailed] = useState(false);
  const [error, setError] = useState(false);
  const [hashdata, setHashdata] = useState("");
  const tokenImageMap = {
    XBR: XBRimg,
    USDT: USDTimg,
    TBA: tba,
  };
  const trackStyle =
    theme.palette.mode === "light"
      ? {
          background: `linear-gradient(to right, #5663F2 ${removeLiquidityValue}%, #EEEFFE ${removeLiquidityValue}%)`,
        }
      : {
          background: `linear-gradient(to right, #5663F2 ${removeLiquidityValue}%, #0B0E4A ${removeLiquidityValue}%)`,
        };

  const calculateRemoveLiquidity = (balance) => {
    return (balance * removeLiquidityValue) / 100;
  };

  const formatBalanceForPrice = (balance) => {
    const num = parseFloat(balance);
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T";
    } else if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
    } else {
      return num.toFixed(6).replace(/\.?0+$/, "");
    }
  };

  useEffect(() => {
    const fetchPositionIds = async () => {
      try {
        const isConnected = localStorage.getItem("connectedAccount") !== null;
        if (!isConnected) {
          setPositionIds([]);
          return;
        }
        const positions = await getPositionIds(walletProvider);
        setPositionIds(positions);
        console.log("positionids", positionIds);
      } catch (error) {
        console.error("Error fetching positionIds:", error);
      }
    };
    fetchPositionIds();
  }, []);

  useEffect(() => {
    setRemoveToken0Liquidity(calculateRemoveLiquidity(token0LiquidityAmount));
    setRemoveToken1Liquidity(calculateRemoveLiquidity(token1LiquidityAmount));
  }, [removeLiquidityValue, positionIds]);

  const handleDropdownToggle = (event) => {
    event.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };
  useEffect(() => {
    const getLiquidityDataforid = async () => {
      const { token0, token1, fee } = await getLiquidity(
        Number(nftId),
        walletProvider
      );
      setToken0LiquidityAmount(token0?.amount);
      setToken1LiquidityAmount(token1?.amount);
      setToken0Name(token0?.symbol);
      setToken1Name(token1?.symbol);
      setToken0Address(token0?.address);
      setToken1Address(token1?.address);
      console.log("Symbol", token0?.symbol, token1?.symbol);
    };
    getLiquidityDataforid();
  }, []);

  // const handleOptionSelect = async () => {
  //   const liquidity = liquidityData[nftId];
  //   if (liquidity) {
  //     setToken0Name(liquidity.token0Name);
  //     setToken1Name(liquidity.token1Name);
  //     setToken0Image(tokenImageMap[liquidity.token0Name]);
  //     setToken1Image(tokenImageMap[liquidity.token1Name]);
  //   }
  // };

  const handlePercentageButtonClick = (percentage) => {
    setRemoveLiquidityValue(percentage);
    setRemoveToken0Liquidity(calculateRemoveLiquidity(token0LiquidityAmount));
    setRemoveToken1Liquidity(calculateRemoveLiquidity(token1LiquidityAmount));
  };

  // const getLiquidityData = async (option) => {
  //   const { token0, token1, fee } = await getLiquidity(
  //     Number(option),
  //     walletProvider
  //   );

  //   const token0LiquidityAmount = token0.amount;
  //   const token1LiquidityAmount = token1.amount;

  //   const token0Name = token0.symbol;
  //   const token1Name = token1.symbol;

  //   setLiquidityData((prevData) => ({
  //     ...prevData,
  //     [option]: {
  //       token0LiquidityAmount,
  //       token1LiquidityAmount,
  //       token0Name,
  //       token1Name,
  //     },
  //   }));
  // };

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the clicked target is outside the dropdown and the button
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    if (isDropdownOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div
      className={`flex flex-col justify-center items-center gap-4 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[96%] xs:w-[28.5rem] p-2 xs:p-4 z-30 mobile:rounded-xl rounded-3xl ${
        theme.palette.mode === "light" ? "bg-white" : "bg-[#02042C]"
      }`}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover mobile:rounded-xl rounded-3xl z-0 opacity-30"
        src={tabBgVideo}
      >
        Your browser does not support the video tag.
      </video>
      <div
        className={`text-[20px] font-bold leading-[150%] ${
          theme.palette.mode === "light" ? "text-[#181821]" : "text-[#DCDCDE]"
        }`}
      >
        Remove Liquidity
      </div>
      <MdClose
        className="w-6 h-6 absolute top-3 right-2 xs:top-5 xs:right-4 font-bold cursor-pointer"
        onClick={onClose}
      />
      {selectedOption && (
        <div
          className={`rounded-2.5 mobile:rounded-2.5 flex-row items-center p-4 z-20 w-full ${
            theme.palette.mode === "light"
              ? "bg-white text-[#69696F]"
              : "bg-[#02042C] text-[#97979B]"
          }`}
        >
          <div className={`flex justify-between  h-[24px]`}>
            <span
              className={`text-base ${
                theme.palette.mode === "light"
                  ? "text-[#181821]"
                  : " text-[#97979B]"
              }`}
            >
              {selectedOption}
            </span>
          </div>
          <div className={`flex justify-between w-full`}>
            <div
              className={`flex flex-shrink-0 p-2 pr-4 items-center rounded-[8px] ${
                theme.palette.mode === "light"
                  ? "bg-[#EEEFFE] text-[#181821]"
                  : "bg-[#0B0E4A] text-[#97979B]"
              }`}
            >
              <div className="flex justify-center items-center gap-1">
                <div className="flex items-center">
                  <img
                    src={tokenImageMap[token0Name]}
                    alt={token0Name}
                    className="w-7 h-7 border-2 border-[#21246A] rounded-[24px]"
                  />
                  <img
                    src={tokenImageMap[token1Name]}
                    alt={token1Name}
                    className="w-[29px] h-[29px] border-2 border-[#21246A] rounded-[24px] -ml-[10px]"
                  />
                </div>
                <span className={`text-base font-semibold inline-block`}>
                  {token0Name} / {token1Name}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-end justify-center gap-1 text-right w-1/2">
              <input
                className={`
            relative bg-[#02042C] text-right ${
              theme.palette.mode === "light"
                ? "text-mediumslateblue-600 bg-transparent placeholder:text-mediumslateblue-600"
                : "text-white"
            } 
            outline-none font-inter text-[31px] w-30 md:w-80per font-bold placeholder-white 
          `}
                placeholder="0.00"
                type="text"
                onWheel={(e) => e.preventDefault()}
                value={`${removeLiquidityValue}%`}
                readOnly
              />
            </div>
          </div>
          <div className={`flex justify-between invisible opacity-0`}>
            <div
              className={`text-base leading-6 mt-[2px] ${
                theme.palette.mode === "light"
                  ? "text-[#69696F]"
                  : "text-[#97979B]"
              } text-ft5`}
            >
              Balance: $2024.80
            </div>
            <div
              className={`text-base leading-6 ${
                theme.palette.mode === "light"
                  ? "text-[#69696F]"
                  : "text-[#97979B]"
              } text-ft5`}
            >
              ≈ $2024.80
            </div>
          </div>

          {selectedOption && (
            <div className="mt-4 z-10 w-full">
              <input
                type="range"
                min="0"
                max="100"
                step="5"
                value={removeLiquidityValue}
                className={`custom-slider mt-6 mb-10`}
                style={trackStyle}
                onChange={(e) =>
                  setRemoveLiquidityValue(parseFloat(e.target.value))
                }
              />
              <div className="flex gap-1 sm:gap-2 w-full items-center justify-between">
                {[25, 50, 75, 100].map((percentage) => (
                  <button
                    key={percentage}
                    className={`py-1 px-6 sm:px-7 rounded-[24px] text-center hover:cursor-pointer text-base mobile:text-[14px]  
                  ${
                    removeLiquidityValue === percentage
                      ? theme.palette.mode === "light"
                        ? "bg-[#5663F2] text-white"
                        : "bg-[#21246A] text-[#DCDCDE] border-2 border-[#21246A]"
                      : theme.palette.mode === "light"
                      ? "bg-transparent border-2 border-[#D6DAFC] text-[#181821]"
                      : "bg-transparent border-2 border-[#21246A] text-[#E6E7FD]"
                  }`}
                    onClick={() => handlePercentageButtonClick(percentage)}
                  >
                    {percentage === 100 ? "Max" : `${percentage}%`}
                  </button>
                ))}
              </div>
            </div>
          )}
          {connectedAccount && selectedOption && (
            <div className="pt-6 flex flex-col gap-2 font-medium">
              <div className="flex justify-between items-center">
                <div
                  className={`text-base ${
                    theme.palette.mode === "light"
                      ? "text-[#181821]"
                      : "text-white"
                  }`}
                >
                  Pooled {token0Name}:
                </div>
                <div className="flex justify-center items-center gap-1">
                  <span
                    className={`text-base ${
                      theme.palette.mode === "light"
                        ? "text-[#69696F]"
                        : "text-[#D6DAFC]"
                    }`}
                  >
                    {formatBalanceForPrice(removeToken0Liquidity)}
                  </span>
                  <img
                    src={tokenImageMap[token0Name]}
                    alt={token0Name}
                    className="h-5 w-5"
                  />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div
                  className={`text-base ${
                    theme.palette.mode === "light"
                      ? "text-[#181821]"
                      : "text-white"
                  }`}
                >
                  Pooled {token1Name}:
                </div>
                <div className="flex justify-center items-center gap-1">
                  <span
                    className={`text-base ${
                      theme.palette.mode === "light"
                        ? "text-[#69696F]"
                        : "text-[#D6DAFC]"
                    }`}
                  >
                    {formatBalanceForPrice(removeToken1Liquidity)}
                  </span>
                  <img
                    src={tokenImageMap[token1Name]}
                    alt={token1Name}
                    className="h-5 w-5"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {connectedAccount ? (
        <button
          className={`w-full py-3 px-6 z-10 rounded-[12px] text-base font-semibold leading-6 text-white bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300`}
          onClick={() =>
            removeLiquiditynew(
              Number(removeToken0Liquidity),
              Number(removeToken1Liquidity),
              token0Name,
              token1Name,
              Number(nftId),
              walletProvider,
              setIsConfirmModalOpen,
              setIsRemoveConfirmed,
              setIsTransactionFailed,
              setError,
              setHashdata,
              setIsLiquidityChanged,
              setRemoveLiquidity
            )
          }
          disabled={!selectedOption || !removeLiquidityValue || !removeToken0Liquidity || !removeToken1Liquidity}
        >
          {selectedOption ? "Remove Liquidity" : "Select NFT ID"}
        </button>
      ) : (
        <NavLink
          to="/connect-wallet"
          className="w-full h-[47px] min-h-[47px] rounded-[12px] flex items-center justify-center bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300  text-white cursor-pointer z-10"
        >
          Connect Wallet
        </NavLink>
      )}
      {isConfirmModalOpen && (
        <LiquidityConfirmationModal
          onClose={() => setIsConfirmModalOpen(false)}
          text={`Removing ${formatBalanceForPrice(
            removeToken0Liquidity
          )} ${token0Name} and ${formatBalanceForPrice(
            removeToken1Liquidity
          )} ${token1Name}`}
        />
      )}
      {isRemoveConfirmed && (
        <LiquidityConfirmedModal
          onClose={() => setIsRemoveConfirmed(false)}
          text={`Removed Liquidity`}
          paragraph={`${formatBalanceForPrice(
            removeToken0Liquidity
          )} ${token0Name} and ${formatBalanceForPrice(
            removeToken1Liquidity
          )} ${token1Name}`}
          token0Name={token0Name}
          token1Name={token1Name}
          hashdata={hashdata}
        />
      )}
      {isTransactionFailed && (
        <FailedTransaction
          onClose={() => setIsTransactionFailed(false)}
          error={error}
        />
      )}
    </div>
  );
};

export default RemoveLiquidity;
