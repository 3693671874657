import { useTheme } from "@mui/material";
import { BiLoaderAlt } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { XBRimg, USDTimg } from "../assets/index.js";

const UnstakeConfirmationModal = ({
  confirmUnstake,
  onClose,
  isUnstaketLoading,
  position,
  farmpercentage,
  amount,
  rewards
}) => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";
  return (
    <div className="fixed flex items-center justify-center z-50 font-inter inset-0 bg-black bg-opacity-40">
      <div
        className={`w-[400px] m-2 flex flex-col gap-6 p-6 rounded-[24px] relative ${
          isLightMode ? "bg-white" : "bg-[#02042C]"
        }`}
      >
        <div className="flex justify-center items-center w-full px-8">
          {!isUnstaketLoading && (
            <span
              className={`text-base font-semibold leading-6 ${
                isLightMode ? "text-[#69696F]" : "text-[#DCDCDE]"
              }`}
            >
              Review unstake
            </span>
          )}
          <MdClose
            className="absolute right-6 top-6 flex items-center w-6 h-6 cursor-pointer"
            onClick={onClose}
          />
        </div>
        {isUnstaketLoading ? (
          <div className="flex flex-col gap-1 justify-center items-center w-full ">
            <BiLoaderAlt
              className="animate-spin text-[#747FF4] h-10 w-10
        "
            />
            <h3
              className={`text-[20px] font-bold leading-[150%] ${
                isLightMode ? "text-[#181821]" : "text-[#FFFFFF]"
              }`}
            >
              Confirm unstake
            </h3>
            <p
              className={`text-base leading-6 ${
                isLightMode ? "text-[#69696F]" : "text-[#97979B]"
              }`}
            >
              {rewards}
            </p>
          </div>
        ) : (
          <div className="py-2 text-center">
            <h3
              className={`text-[25px] font-bold leading-[150%] ${
                isLightMode ? "text-[#181821]" : "text-[#FFFFFF]"
              }`}
            >
              {rewards}
            </h3>
            <p
              className={`text-base leading-6 ${
                isLightMode ? "text-[#69696F]" : "text-[#97979B]"
              }`}
            >
              XBR to be claimed
            </p>
          </div>
        )}
        <div className="w-full flex flex-col gap-2">
          <div className="flex justify-between w-full text-base leading-6">
            <div
              className={` ${
                isLightMode ? "text-[#69696F]" : "text-[#97979B]"
              }`}
            >
              NFT ID
            </div>
            <div
              className={`${isLightMode ? "text-[#181821]" : "text-[#FFFFFF]"}`}
            >
              {position}
            </div>
          </div>
          <div className="flex justify-between w-full text-base leading-6">
            <div
              className={` ${
                isLightMode ? "text-[#69696F]" : "text-[#97979B]"
              }`}
            >
              Liquidity Pair
            </div>
            <div
              className={`flex items-center gap-1 ${
                isLightMode ? "text-[#181821]" : "text-[#FFFFFF]"
              }`}
            >
              <div className="flex items-center">
                <img
                  src={USDTimg}
                  alt="USDTimg"
                  className="w-6 h-6 border-2 border-[#21246A] rounded-[24px]"
                />
                <img
                  src={XBRimg}
                  alt="XBRimg"
                  className="w-6 h-6 mt-[1px] border-2 border-[#21246A] rounded-[24px] -ml-[10px]"
                />
              </div>{" "}
              <span>USDT / XBR </span>
            </div>
          </div>
          <div className="flex justify-between w-full text-base leading-6">
            <div
              className={` ${
                isLightMode ? "text-[#69696F]" : "text-[#97979B]"
              }`}
            >
              Share
            </div>
            <div
              className={`${isLightMode ? "text-[#181821]" : "text-[#FFFFFF]"}`}
            >
              {farmpercentage}
            </div>
          </div>
          <div className="flex justify-between w-full text-base leading-6">
            <div
              className={` ${
                isLightMode ? "text-[#69696F]" : "text-[#97979B]"
              }`}
            >
              LPT
            </div>
            <div
              className={`${isLightMode ? "text-[#181821]" : "text-[#FFFFFF]"}`}
            >
              {amount[position]}
            </div>
          </div>
        </div>
        {isUnstaketLoading ? (
          <div
            className={`text-base leading-6 text-center ${
              isLightMode ? "text-[#69696F]" : "text-[#97979B]"
            }`}
          >
            Proceed in your wallet
          </div>
        ) : (
          <button
            className="h-[47px] py-3 px-6 z-10 rounded-[12px] text-base font-semibold leading-6 text-white bg-[#5663F2] hover:bg-[rgb(116,127,244)] transition-colors duration-300"
            onClick={confirmUnstake}
          >
            Confirm Unstake
          </button>
        )}
      </div>
    </div>
  );
};

export default UnstakeConfirmationModal;
