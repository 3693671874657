import React, { useEffect, useState, useRef } from "react";
import { Toaster, toast } from "react-hot-toast";
import tabBgVideo from "../assets/SwapbgVideo.mp4";
import { useTheme } from "@mui/material";
import AddLiquidity from "../components/AddLiquidity";
import { layer } from "../assets";
import MyNFTs from "./MyNFTs";

const NewLiquidityComponent = (props) => {
  const theme = useTheme();
  const [activeSection, setActiveSection] = useState(1);
  const { setMainActiveSection } = props;

  const myNFTsRef = useRef(null);

  const handleSectionClick = (sectionNumber) => {
    setActiveSection(sectionNumber);
  };
  const scrollToNFTs = () => {
    if (myNFTsRef.current) {
      myNFTsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="w-full">
      <div
        className="relative max-w-[30.5rem] mx-auto px-2 py-4 sm:p-4"
        style={{
          background:
            theme.palette.mode === "light"
              ? "linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)"
              : "linear-gradient(180deg, #050840 0%, rgba(5, 8, 64, 0.00) 100%)",
        }}
      >
        <div className={`relative w-full`}>
          <div
            className={`relative w-full ${
              theme.palette.mode === "light" ? "bg-white" : ""
            } mobile:rounded-xl  rounded-3xl flex flex-col  mobile:p-2 p-4 gap-4`}
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              className="absolute top-0 left-0 w-full h-full object-cover mobile:rounded-xl rounded-3xl z-0 opacity-30"
              src={tabBgVideo}
            >
              Your browser does not support the video tag.
            </video>

            <div className="flex flex-col font-inter mx-auto z-10 w-full">
              {/* Tab Section */}
              <div
                className={`w-full flex flex-row items-center justify-between box-border p-2 rounded-r-full rounded-l-full`}
              >
                <div
                  className={`font-semibold text-[18px] leading-[150%] ${
                    theme.palette.mode === "light"
                      ? "text-[#181821]"
                      : "text-white"
                  }}`}
                >
                  Add Liquidity
                </div>
                <button
                  className={`font-base leading-6 font-semibold text-[#5663F2] py-1 px-4 flex justify-center items-center gap-8 rounded-[24px] ${
                    theme.palette.mode === "light" ? "bg-[#EEEFFE]" : "bg-[#02042C]"
                  }`}
                  onClick={scrollToNFTs}
                >
                  My NFTs
                </button>
              </div>

              {/* {activeSection === 2 && (
              <NewLiquidityComponentContent activeSection={2} />
            )} */}
            </div>

            <AddLiquidity
              activeSection={1}
              setMainActiveSection={setMainActiveSection}
            />

            <Toaster />
          </div>
          <div className="flex-1 flex flex-row items-baseline justify-center gap-[0.25rem] mt-6 text-lightslategray-300">
            <div className="w-[1.5rem] h-[1.5rem] flex flex-row items-center justify-center">
              <img
                className="w-[1.063rem] !m-[0] top-[0rem] left-[0.188rem] h-[1.25rem] overflow-hidden shrink-0 z-[0]"
                alt="layer"
                src={layer}
              />
            </div>
            <div className="w-[5.625rem] text-ft5 inline-block h-[1.5rem] shrink-0">{`Powered by `}</div>
            <b className="w-[4.813rem] text-ft5 uppercase inline-block h-[1.5rem] shrink-0">
              Uniswap
            </b>
          </div>
        </div>
      </div>
      <MyNFTs ref={myNFTsRef} />
    </div>
  );
};

export default NewLiquidityComponent;
