import { useTheme } from "@mui/material";
import tabBgVideo from "../assets/SwapbgVideo.mp4";
import { TBABorder, USDTBorder, XBRBorder } from "../assets";
import { MdClose } from "react-icons/md";

const CollectFees = ({ onClose, collectfee, token0Name, token1Name, collectfee0 , collectfee1 }) => {
  const theme = useTheme();
  const tokenImageMap = {
    XBR: XBRBorder,
    USDT: USDTBorder,
    TBA: TBABorder,
  };
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 mobile:rounded-xl rounded-3xl`}
    >
      <div
        className={`relative ${
          theme.palette.mode === "light" ? "bg-white" : "bg-[#02042C]"
        } mobile:rounded-xl rounded-3xl w-[96%] xs:w-[28.5rem] p-2 xs:p-4 flex flex-col justify-center items-center gap-4`}
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover mobile:rounded-xl rounded-3xl z-0 opacity-30"
          src={tabBgVideo}
        >
          Your browser does not support the video tag.
        </video>
        <MdClose
          className="w-6 h-6 absolute top-3 right-2 xs:top-5 xs:right-4 font-bold cursor-pointer"
          onClick={onClose}
        />
        <div
          className={`text-[20px] font-bold leading-[150%] ${
            theme.palette.mode === "light" ? "text-[#181821]" : "text-[#DCDCDE]"
          }`}
        >
          Claim fees
        </div>
        <div
          className={`flex flex-col gap-4 p-6 rounded-[24px] w-full z-10 ${
            theme.palette.mode === "light"
              ? "bg-white"
              : "bg-[#02042C] text-[#DCDCDE]"
          }`}
        >
          <div className="flex justify-between items-center">
            <div className="flex gap-1 items-center">
              <img src={tokenImageMap[token0Name]} alt="USDT" className="w-6 h-6" />
              <span>{token0Name}</span>
            </div>
            <span>{collectfee0}</span>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex gap-1 items-center">
              <img src={tokenImageMap[token1Name]} alt="USDT" className="w-6 h-6" />
              <span>{token1Name}</span>
            </div>
            <span>{collectfee1}</span>
          </div>
        </div>
        <button
          className="w-full text-base leading-6 text-white py-3 px-6 rounded-[12px] bg-[#5663F2] z-10"
          onClick={collectfee}
        >
          Collect
        </button>
      </div>
    </div>
  );
};

export default CollectFees;
