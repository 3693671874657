import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import RewardCard from "./RewardCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ReactComponent as NoLPT } from "../assets/NoLPT.svg";
import { getUserStakedTokenIds, unStake } from "../uniswap/farm";
import { isAddress } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BiLoaderAlt } from "react-icons/bi";
import { farmDetails, farmShare } from "../uniswap/farm";
import { fetchTransactions } from "../uniswap/lpfarm";
import { getEstimatedLPTokens , getFarmRewards } from "../uniswap/farm";
import UnstakeConfirmationModal from "./UnstakeConfirmationModal";
import LPFarmSuccessModal from "./LPFarmSuccessModal";
const ClaimFarmRewards = (props) => {
  const { setActiveSection } = props;
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [isLPTDeposited, setIsLPTDeposited] = useState(true);
  const isLightMode = theme.palette.mode === "light";
  const [connectedAccount, setConnectedAccount] = useState(
    localStorage.getItem("connectedAccount")
  );
  const { walletProvider } = useWeb3ModalProvider();
  const [positionIds, setPositionIds] = useState([]);
  const [lpTokens, setLpTokens] = useState({});
  const [farmpercentage, setfarmpercentage] = useState();
  const [isUnstaketLoading, setIsUnstakeLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isUnstakeConfirmed, setIsUnstakeConfirmed] = useState(false);
  const [isUnstakeFailed, setIsUnstakeFailed] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [farmrewards, setfarmrewards] = useState();
  const shortenAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 4)}...${address.slice(-3)}`;
  };
  const FarmAddress = process.env.REACT_APP_FARMV2_CONTRACT_ADDRESS;
  const shortenedFarmAddress = shortenAddress(FarmAddress);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    draggable: true,
    swipeToSlide: true,
    slidesToShow: 1,
    variableWidth: true,
  };

  useEffect(() => {
    const fetchStakedPositionIds = async () => {
      try {
        setIsLoading(true);
        const account = localStorage.getItem("connectedAccount");
        setConnectedAccount(account); // Update the connected account in state

        if (!isAddress(account)) {
          setIsLPTDeposited(false);
          return;
        }
        const positions = await getUserStakedTokenIds(walletProvider);
        console.log("positions: ", positions);
        setPositionIds(positions);
        setIsLPTDeposited(positions.length > 0);
      } catch (error) {
        console.error("Error fetching positionIds:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStakedPositionIds();
  }, []);

  useEffect(() => {
    const fetchfarmdata = async () => {
      try {
        const connectedAccount = localStorage.getItem("connectedAccount");
        if (!isAddress(connectedAccount)) {
          return;
        }
        const farmdata = await farmShare(walletProvider);
        setfarmpercentage(farmdata);
      } catch (error) {
        console.error("Error fetching positionIds:", error);
      }
    };
    fetchfarmdata();
  }, []);

  const handlePositionSelect = async (position) => {
    try {
      const result = await getEstimatedLPTokens(
        position,
        process.env.REACT_APP_FARMV2_CONTRACT_ADDRESS
      );
      return result;
    } catch (error) {
      console.error("Error getting estimated LP Tokens:", error);
    }
  };

  useEffect(() => {
    const fetchLPTokens = async () => {
      const results = {};
      for (const position of positionIds) {
        const result = await handlePositionSelect(position);
        if (result) {
          results[position] = result;
        }
      }
      setLpTokens(results);
      const FarmRewards = await getFarmRewards();
      setfarmrewards(FarmRewards)
    };
    fetchLPTokens();
  }, [positionIds]);
  const confirmUnstake = async (position) => {
    setIsUnstakeLoading(true);
    try {
      const result = await unStake(walletProvider, position, 0);
      if (result) {
        setIsConfirmModalOpen(false);
        setIsUnstakeConfirmed(true);
        setIsUnstakeLoading(false);
      } else {
        setIsUnstakeLoading(false);
        setIsUnstakeFailed(true);
      }
    } catch (error) {
      console.error("Error in confirmUnstake:", error);
    }
  };
  const openUnstakeModal = (position) => {
    setSelectedPosition(position);
    setIsConfirmModalOpen(true);
  };
  return (
    <div className="z-10 relative">
      <div className={`w-full text-center text-[20px] mb-2`}>
        XBR Farmed: {farmrewards}
      </div>

      {isLoading ? (
        <div className={`flex justify-center items-center h-[210px]`}>
          <BiLoaderAlt className="animate-spin text-gray-50 text-4xl" />
        </div>
      ) : isLPTDeposited ? (
        <Slider {...settings}>
          {positionIds.map((position, index) => (
            <div key={index} className="w-auto mr-2 hover:cursor-grab">
              <RewardCard
                confirmUnstake={() => openUnstakeModal(position)}
                XBRFarmed={position}
                deposited={lpTokens[position]}
                farmShare={farmpercentage}
                accountNo={shortenedFarmAddress}
                token0Name={`USDT`}
                token1Name={`XBR`}
              />
            </div>
          ))}
        </Slider>
      ) : connectedAccount ? (
        <div className="flex flex-col justify-center items-center gap-4">
          <div
            className={`rounded-2.5 mobile:rounded-2.5 items-center py-12 px-4 z-20 w-full h-[210px] flex flex-col justify-center gap-2  ${
              isLightMode
                ? "bg-white text-[#69696F]"
                : "bg-[#02042C] text-[#97979B]"
            }`}
          >
            <NoLPT className="w-10 h-10" />
            <p
              className={`text-[20px] font-semibold leading-[150%] ${
                isLightMode ? "text-[#34343C]" : "text-white"
              }`}
            >
              No LPT Deposited
            </p>
            <p
              className={`text-base leading-[150%] ${
                isLightMode ? "text-[#69696F]" : "text-[#97979B]"
              }`}
            >
              Deposit LPT to get amazing rewards.
            </p>
          </div>
          <button
            className={`w-full py-3 px-6 z-10 rounded-[12px] text-base font-semibold leading-6 text-white bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300`}
            onClick={() => {
              setActiveSection(1);
            }}
          >
            Deposit
          </button>
        </div>
      ) : (
        <NavLink
          to="/connect-wallet"
          className="w-full h-[47px] min-h-[47px] rounded-[12px] flex items-center justify-center bg-[#5763F3] hover:bg-[rgb(116,127,244)] transition-colors duration-300 text-white cursor-pointer z-10"
        >
          Connect Wallet
        </NavLink>
      )}
      {isConfirmModalOpen && (
        <UnstakeConfirmationModal
          confirmUnstake={() => confirmUnstake(selectedPosition)}
          onClose={() => setIsConfirmModalOpen(false)}
          isUnstaketLoading={isUnstaketLoading}
          position = {selectedPosition}
          farmpercentage = {farmpercentage}
          amount={lpTokens}
          rewards={farmrewards}
        />
      )}

      {isUnstakeConfirmed && (
        <LPFarmSuccessModal
          onClose={() => setIsUnstakeConfirmed(false)}
          type={`success`}
          message={`Unstake Successful`}
          amount={lpTokens}
          position = {selectedPosition}
        />
      )}
      {isUnstakeFailed && (
        <LPFarmSuccessModal
          onClose={() => setIsUnstakeFailed(false)}
          type={`failed`}
          message={`Unstake Failed`}
          amount={lpTokens}
          position = {selectedPosition}
        />
      )}
    </div>
  );
};

export default ClaimFarmRewards;
