// import { Token } from "@uniswap/sdk-core";
import { FeeAmount } from "@uniswap/v3-sdk";
import { 
  // DAI_TOKEN, 
  USDT_TOKEN, XBR_TOKEN , TBA_TOKEN } from "../web3Utils";


// Sets if the example should run locally or on chain
export const Environment = {
  LOCAL: 0,
  WALLET_EXTENSION: 1,
  MAINNET: 2,
};

// Inputs that configure this example to run
const ExampleConfig = {
  env: Environment.WALLET_EXTENSION,
  // rpc: {
  //   local: "http://localhost:8545",
  //   mainnet: "",
  // },
  // wallet: {
  //   address: "0xf39fd6e51aad88f6f4ce6ab8827279cfffb92266",
  //   privateKey:
  //     "0xac0974bec39a17e36ba4a6b4d238ff944bacb478cbed5efcae784d7bf4f2ff80",
  // },
  tokens: {
    token0: USDT_TOKEN,
    token0Amount: 1000,
    token1: XBR_TOKEN,
    token1Amount: 1000,
    token2: TBA_TOKEN,
    // token1Amount: 1000,
    poolFee: FeeAmount.MEDIUM, // Replaced FeeAmount.LOW with string as constants are not directly available in React JS
    fractionToAdd: 0.5
  },
};

export const CurrentConfig = ExampleConfig;
