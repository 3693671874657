import { useTheme } from "@mui/material";
import React, { useState } from "react";
import { XBRimg, USDTimg, tba } from "../assets/index.js";
import { unStake } from "../uniswap/farm.js";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import UnstakeConfirmationModal from "./UnstakeConfirmationModal.jsx";
const RewardCard = (props) => {
  const { walletProvider } = useWeb3ModalProvider();
  const {
    confirmUnstake,
    XBRFarmed,
    deposited,
    farmShare,
    accountNo,
    token0Name,
    token1Name,
  } = props;
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";

  const tokenImageMap = {
    XBR: XBRimg,
    USDT: USDTimg,
    TBA: tba,
  };

  const image1 = tokenImageMap[token0Name];
  const image2 = tokenImageMap[token1Name];

  return (
    <>
      <div
        className={`w-[317px] h-[312px] p-6 flex flex-col gap-2 font-inter rounded-[16px] z-10 ${
          isLightMode
            ? "bg-white text-[#69696F]"
            : "bg-[#02042C] text-[#97979B]"
        }`}
      >
        <div className={`py-2`}>
          <h2
            className={`text-[25px] font-bold leading-[150%] ${
              isLightMode ? "text-[#181821]" : "text-white"
            }`}
          >
            {XBRFarmed}
          </h2>
          <span className={`font-base leading-[150%] ${isLightMode ? "" : ""}`}>
            XBR farmed
          </span>
        </div>
        <div
          className={`p-4 w-full flex justify-between items-center text-base leading-[150%] rounded-[16px]  ${
            isLightMode
              ? "bg-[#EEEFFE] text-[#181821]"
              : "bg-[#050840] text-white"
          }`}
        >
          <div className="flex">
            <img
              src={image1}
              alt={token0Name}
              className={`w-6 h-6 border-2 rounded-full ${
                isLightMode ? "border-[]" : "border-[#21246A]"
              }`}
            />
            <img
              src={image2}
              alt={token1Name}
              className={`w-6 h-6 border-2 -ml-2 mr-[2px] rounded-full ${
                isLightMode ? "border-[]" : "border-[#21246A]"
              }`}
            />
            <span>USDT / XBR </span>
          </div>
          <span>{accountNo}</span>
        </div>
        <div
          className={`flex gap-4 p-2 w-full text-base leading-[150%] ${
            isLightMode ? "text-[#97979B]" : "text-[#69696F]"
          }`}
        >
          <div className="w-1/2">
            <span className="font-bold">{deposited}</span>
            <p>Deposited</p>
          </div>
          <div className="w-1/2">
            <span className="font-bold">{farmShare}%</span>
            <p>Farm Share</p>
          </div>
        </div>
        <button
          className={`flex py-3 justify-center items-center text-base leading-[150%] ${
            isLightMode ? "text-[#747FF4]" : "text-[#5663F2]"
          }`}
          onClick={confirmUnstake}
        >
          Tap to unstake
        </button>
      </div>
    </>
  );
};

export default RewardCard;
