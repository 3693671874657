import { createSlice } from "@reduxjs/toolkit";

const refreshNftIdSlice = createSlice({
  name: "refreshNftId",
  initialState: {
    refreshNftId: false,
  },
  reducers: {
    setRefreshNftId: (state, action) => {
      state.refreshNftId = action.payload;
    },
  },
});

export const { setRefreshNftId } = refreshNftIdSlice.actions;
export default refreshNftIdSlice.reducer;
